import { ModalControllerContext } from "App";
import ListAdd from "Components/Icons/ListAdd";
import UI from "Components/UI";
import SelectField from "Components/UI/SelectField";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import { AppDispatch } from "Store";
import TipListActions from "Store/Actions/TipListActions";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import MiscUtils from "Utils/MiscUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Data } from "../Types";
import css from "./TipList.module.css";

async function loadAllTipLists(
  dispatch: AppDispatch,
  args: Parameters<typeof TipListActions.list>[1]
): Promise<TipListModel[]> {
  const [tipLists] = await TipListActions.list(dispatch, {
    ...args,
    limit: 100,
    offset: args?.offset ?? 0,
  });

  if (tipLists.length < 100) {
    return tipLists;
  }

  return [
    ...tipLists,
    ...(await loadAllTipLists(dispatch, {
      ...args,
      offset: (args?.offset ?? 0) + 1,
    })),
  ];
}

type TipListsProps = {
  data: Data;
  userId?: string;
  tipId?: string;
  change: (data: React.SetStateAction<Data>) => void;
};

/* Drop down menu to choose tiplist in tipdialog */
export default function TipLists({
  data,
  userId,
  tipId,
  change,
}: TipListsProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { tipListDialog } = React.useContext(ModalControllerContext);
  const [tipListIds, setTipListIds] = React.useState<string[]>([]);
  const tipLists = useSelector(TipListSelectors.list(...tipListIds));

  // City id changed, fetch available tip lists.
  React.useEffect(() => {
    let mounted = true;

    (async () => {
      if (!userId) {
        setTipListIds([]);
        return;
      }

      const nextTipLists = await loadAllTipLists(dispatch, {
        filters: {
          userIds: [userId],
        },
      });

      if (mounted) {
        setTipListIds(nextTipLists.map((tipList) => tipList.id));
      }
    })();

    return () => {
      mounted = false;
    };
  }, [dispatch, data.venue?.cityId, userId]);

  // Callback when a tip list is created inside this dialog.
  const onTipListCreate = React.useCallback(
    (tipList: TipListModel) => {
      setTipListIds((current) => [tipList.id, ...current]);

      change((current) => ({
        ...current,
        tipLists: [...(current.tipLists ?? []), tipList],
      }));
    },
    [change]
  );

  // Tip id has changed
  React.useEffect(() => {
    change({ tipLists: [] });

    let mounted = true;

    if (!(tipId && userId)) {
      return () => {
        mounted = false;
      };
    }

    // Get tip lists.
    (async () => {
      const nextTipLists = await loadAllTipLists(dispatch, {
        filters: { userIds: [userId], tipId },
      });

      if (mounted) {
        change({ tipLists: nextTipLists });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [tipId, dispatch, userId, change]);

  const sortedTiplists = tipLists
    .map((tipList) => {
      const distanceToVenue = MiscUtils.getDistance(
        { lat: tipList.city.latitude, lng: tipList.city.longitude },
        { lat: data.venue?.latitude, lng: data.venue?.longitude }
      );
      return {
        ...tipList,
        distanceToVenue: distanceToVenue?.numberDistance,
      };
    })
    .sort((a, b) =>
      a.distanceToVenue && b.distanceToVenue
        ? a.distanceToVenue - b.distanceToVenue
        : Number(a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
    );

  return (
    <SelectField
      onChange={(e) =>
        change({
          tipLists: [...tipLists.filter((list) => list.id === e.target.value)],
        })
      }
      className={css.Select}
      label={t("list")}
      icon={<ListAdd />}
      value={data?.tipLists?.[0]?.id ?? 0}
      disableFirst
      borders
    >
      <option>
        <div className={css.CreateNew} style={{ cursor: "default" }}>
          <UI.Button
            variant="outline"
            color="secondary"
            onClick={() => {
              tipListDialog(undefined, data as TipModel, onTipListCreate);
            }}
          >
            {t("create-new-list")}
          </UI.Button>
          <span className={css.HeaderText}>{t("or-add-to-existing")}</span>
        </div>
      </option>
      {sortedTiplists.map((tipList) => (
        <option value={tipList.id} key={tipList.id}>
          <div className={css.TipList}>
            <h4>{tipList.title}</h4>
            <h5>{tipList.city.name}</h5>
          </div>
        </option>
      ))}
    </SelectField>
  );
}
