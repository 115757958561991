import Icons from "Components/Icons";
import { useModal } from "Components/UI/Modal";
import React, { useEffect, useRef, useState } from "react";
import MiscUtils from "Utils/MiscUtils";
import { CityModel } from "Models/CityModel";
import { UserModel } from "Models/UserModel";
import UI from "Components/UI";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UserActions from "Store/Actions/UserActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import Hooks from "Hooks";
import { NotificationContext } from "App";
import css from "./EditProfileDialog.module.css";
import { ModalControllerContext } from "App";
import Avatar from "Components/Icons/Avatar";
import Spinner from "Components/UI/Spinner";
import Tooltip from "Components/Tooltip";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

type Data = {
  firstName: string;
  lastName: string;
  description: string;
  coverImage?: string;
  profileImage?: string;
  city?: CityModel;
  slug: string;
};

type EditProfileDialogProps = {
  onClose: () => void;
};

export default function EditProfileDialog({
  onClose,
}: EditProfileDialogProps): JSX.Element {
  const { t } = useTranslation();
  const { confirmPasswordDialog } = React.useContext(ModalControllerContext);
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get) as UserModel;
  const [changePassword, setChangePassword] = React.useState(false);
  const [toolTip, setToolTip] = useState(false);
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message={t("discard-changes")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        if (changePassword) {
          confirmPasswordDialog();
        }
        onClose();
      }}
    />
  );
  const showNotification = React.useContext(NotificationContext);
  const linkRef = useRef<HTMLInputElement | null>(null);

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      firstName: auth.firstName,
      lastName: auth.lastName,
      description: auth.description ?? "",
      coverImage: undefined,
      profileImage: undefined,
      city: undefined,
      slug: auth.slug ?? "",
    },
    (nextData) => ({
      firstName:
        nextData.firstName.trim().length === 0
          ? (t("enter-first-name") as string)
          : undefined,
      lastName:
        nextData.lastName.trim().length === 0
          ? (t("enter-last-name") as string)
          : undefined,
      slug:
        nextData.slug.length > 0 && !MiscUtils.validSlugStrucutre(nextData.slug)
          ? (t("slug-error") as string)
          : undefined,
      "slug-taken":
        user && user?.id !== auth.id ? (t("slug-taken") as string) : undefined,
    })
  );

  const { user, loading } = Hooks.useFindUser(data.slug);
  const available =
    MiscUtils.validSlugStrucutre(data.slug) && (!user || user.id === auth.id);

  const [pending, setPending] = React.useState(false);

  const profileImageFileRef = React.useRef<HTMLInputElement>(null);

  // Set profile image.
  const setProfileImage = React.useCallback(
    async (file: File) => {
      try {
        if (file.size >= 5242880) {
          throw "FileTooBigException";
        }

        change({ profileImage: await MiscUtils.imageToBase64(file) });
      } catch (err) {
        if (err == "FileTooBigException") {
          showNotification({
            title: t("invalid-image"),
            message: t("file-too-big"),
            type: "error",
          });
        } else {
          showNotification({ title: t("invalid-image"), type: "error" });
        }
      }
    },
    [change, showNotification]
  );

  // Browse profile image.
  const browseProfileImage = React.useCallback(() => {
    if (profileImageFileRef.current) {
      profileImageFileRef.current.click();
    }
  }, []);

  const previousData = Hooks.usePreviousState(data);

  useEffect(() => {
    if (changePassword) {
      return cancel();
    }
  }, [changePassword]);

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (previousData === undefined || shallowEqual(previousData, data)) {
      if (changePassword) {
        confirmPasswordDialog();
      }
      onClose();
      return;
    }
    showConfirmDialog();
  }, [data, onClose, previousData, showConfirmDialog, changePassword]);

  // Save changes.
  const save = React.useCallback(
    async (nextData: Data) => {
      if (pending) {
        return;
      }

      try {
        setPending(true);

        if (!valid()) {
          return;
        }

        UserActions.update(
          dispatch,
          auth.id,
          nextData.firstName,
          nextData.lastName,
          nextData.description,
          nextData.coverImage,
          nextData.profileImage,
          nextData.city?.id,
          nextData.slug.length > 0 ? nextData.slug : null
        )
          .then(() => {
            showNotification({
              title: t("successfully-update-profile"),
              timeoutMs: 4000,
              type: "neutral",
            });
          })
          .catch(() => {
            showNotification({
              title: t("update-profile-error"),
              timeoutMs: 4000,
              type: "error",
            });
          });
      } finally {
        setPending(false);
      }
    },
    [onClose, dispatch, auth.id, pending, valid]
  );

  const imageDropOverlay = (
    <div className={css.ImageDropOverlay}>{t("drop-upload")}</div>
  );

  return (
    <section className={css.Container}>
      {confirmDialog}
      <Tooltip
        selector={"#tooltip"}
        coord={
          linkRef.current
            ? {
                left: linkRef.current.getBoundingClientRect().x - 80,
                top: linkRef.current.getBoundingClientRect().y + 5,
              }
            : undefined
        }
        isOn={toolTip}
      >
        <div style={{ maxWidth: "300px", textAlign: "start" }}>
          {t("slug-error")}
        </div>
      </Tooltip>

      <div className={css.Header}>
        <h5>{t("edit-my-profile")}</h5>

        <UI.Button variant="text" onClick={cancel} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={css.Info}>
        <div className={css.FullSpan}>
          <input
            ref={profileImageFileRef}
            type="file"
            className={css.ImageFile}
            onChange={(e) => {
              if (e.target.files) {
                setProfileImage(e.target.files[0]);
              }

              e.target.value = "";
            }}
          />
          <UI.DropArea
            className={css.ProfileImage}
            onDrop={(e) => setProfileImage(e.dataTransfer.files[0])}
            overlay={imageDropOverlay}
          >
            {data.profileImage && (
              <img src={data.profileImage ?? auth.profileImage.medium ?? ""} />
            )}
            {!data.profileImage && (
              <span>
                <Avatar />
                <div>
                  <UI.Button
                    variant="text"
                    color="purple"
                    onClick={browseProfileImage}
                  >
                    {t("click-here-upload")}
                  </UI.Button>{" "}
                  {t("or-drag-drop")}
                </div>
              </span>
            )}
          </UI.DropArea>
        </div>
        <div className={isPhone ? css.FullSpan : css.Input}>
          <UI.TextField
            className={css.Input}
            label={t("create-firstname")}
            value={data.firstName}
            onChange={(e) => change({ firstName: e.target.value })}
          />
          <UI.ValidationError>{errors.firstName}</UI.ValidationError>
        </div>
        <div className={isPhone ? css.FullSpan : css.Input}>
          <UI.TextField
            className={css.Input}
            label={t("create-lastname")}
            value={data.lastName}
            onChange={(e) => change({ lastName: e.target.value })}
          />
          <UI.ValidationError>{errors.lastName}</UI.ValidationError>
        </div>
        <UI.LocationSearch.City
          value={data.city}
          onChange={(value) => change({ city: value })}
          className={isPhone ? css.FullSpan : css.Input}
        />
        <div className={isPhone ? css.FullSpan : css.Input} ref={linkRef}>
          <UI.TextField
            className={css.Input}
            label={t("personal-link")}
            value={data.slug ?? ""}
            onChange={(e) => change({ slug: e.target.value })}
            icon={
              <span
                className={css.Slug}
                style={{ opacity: data.slug.length === 0 ? "0" : "1" }}
              >
                {loading ? (
                  <Spinner />
                ) : available ? (
                  <Icons.Check className={css.SlugCheck} />
                ) : (
                  <span
                    onMouseEnter={() => setToolTip(true)}
                    onMouseLeave={() => setToolTip(false)}
                  >
                    <Icons.Error />
                  </span>
                )}
              </span>
            }
          />
          <div className={css.Slug}>
            <UI.ValidationError>
              {errors.slug ?? errors["slug-taken"]}
            </UI.ValidationError>
          </div>
        </div>

        <div />
        <div
          className={css.Copy}
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.host}/${data.slug}?signup`
            );
            showNotification({
              title: t("success"),
              message: (
                <>
                  <div>{t("copied-referral")}</div>
                </>
              ),
              type: "neutral",
            });
          }}
        >
          {t("copy-referral")}
          <span className={css.Copy}>
            <Icons.Copy />
          </span>
        </div>
        <div className={css.FullSpan}>
          <UI.MultiLineField
            className={css.Input}
            label={t("biography")}
            value={data.description}
            onChange={(e) => change({ description: e.target.value })}
            maxLength={128}
          />
          <span
            className={css.CharCounter}
          >{`${data.description.length}/128`}</span>
        </div>
      </div>
      <div className={isPhone ? css.Vstack : css.Hstack}>
        <div
          className={css.Change}
          onClick={() => {
            setChangePassword(true);
          }}
        >
          {t("change-password")}
        </div>
        <div className={css.Actions}>
          <UI.Button
            className={css.ActionButton}
            variant="outline"
            onClick={cancel}
          >
            {t("cancel")}
          </UI.Button>

          <UI.Button
            className={css.ActionButton}
            type="submit"
            onClick={() => save(data)}
          >
            {pending ? t("saving") : t("save")}
          </UI.Button>
        </div>
      </div>
    </section>
  );
}
