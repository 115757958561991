import React from "react";

export default function ListCheckFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.52588e-05 24C1.52588e-05 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 1.52588e-05 37.2548 1.52588e-05 24Z"
        fill="#475467"
      />
      <path
        d="M26 22H14V24H26V22ZM26 18H14V20H26V18ZM14 28H22V26H14V28ZM33.5 23.5L35 25L28.01 32L23.5 27.5L25 26L28.01 29L33.5 23.5Z"
        fill="white"
      />
    </svg>
  );
}
