import MainSearch from "Components/MainSearch";
import React, { memo, useEffect } from "react";
import globalCss from "Global.module.css";
import Icons from "Components/Icons";
import { ModalControllerContext } from "App";
import UI from "Components/UI";
import { useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import AuthActions from "Store/Actions/AuthActions";
import TipSelectors from "Store/Selectors/TipSelectors";
import TipActions from "Store/Actions/TipActions";
import { useAppDispatch } from "Store/hooks";
import css from "./Header.module.css";
import Link from "next/link";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "react-i18next";

const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    tipDialog,
    tipListDialog,
    signInDialog,
    signUpDialog,
    editProfileDialog,
    forgotPasswordDialog,
    changeLanguageDialog,
  } = React.useContext(ModalControllerContext);
  const dispatch = useAppDispatch();
  const auth = useSelector(AuthSelectors.get);
  const authId = auth?.id;
  const tips = useSelector(TipSelectors.drafts);
  const tipCount = tips.length;
  const location = useRouter();
  const path = location.pathname.split("/");
  const showSearch = path[1] !== "home" && path[1] !== "" && path[1] !== "l";
  const showMyProfile = path[2] !== auth?.id;
  const { signup, forgotPassword } = location.query;

  if (signup != null) signUpDialog();
  if (forgotPassword != null) forgotPasswordDialog();

  // Sign out.
  const signOut = React.useCallback(() => {
    location.push("/");
    AuthActions.signOut(dispatch);
  }, [dispatch, location]);

  // Load tip drafts.
  React.useEffect(() => {
    if (!authId) {
      return;
    }

    TipActions.list(dispatch, {
      limit: 100,
      filters: { userIds: [authId], draft: true },
    });
  }, [dispatch, authId]);

  return (
    <header
      className={css.Container}
      style={showSearch ? { zIndex: 10 } : { zIndex: 1 }}
    >
      <div className={[globalCss.Content, css.Content].join(" ")}>
        <div
          className={[
            auth?.id ? css.LogoLoggedIn : css.LogoLoggedOut,
            location.query.userId === authId && authId && css.OwnProfile,
          ].join(" ")}
        >
          <Link href={"/home"}>
            <a>
              <Icons.RESIcon />
            </a>
          </Link>
          {auth && (
            <Link href={auth?.slug ? `/${auth.slug}` : "/home"}>
              <a>
                <div>{auth?.id ? t("my-startpage") : "INSIDE"}</div>
              </a>
            </Link>
          )}
        </div>

        {showSearch && (
          <article className={css.Search}>
            <MainSearch
              placeholder={t("header-another")}
              className={css.SearchInput}
              wrapperClassName={css.SearchInputWrapper}
            />
          </article>
        )}

        <nav className={css.Menu}>
          {auth ? (
            <>
              <div
                className={[
                  css.About,
                  location.pathname === "/faq" && css.HelpHighlight,
                ].join(" ")}
              >
                <Link href={"/faq"}>
                  <a>
                    <span>{t("help")}</span>
                  </a>
                </Link>
              </div>
              <div className={css.Globe}>
                <UI.Button
                  variant="text"
                  className={css.CreateButton}
                  onClick={() => changeLanguageDialog()}
                >
                  <Icons.GlobeFilled className={css.CreateButton} />
                </UI.Button>
              </div>
              {tipCount !== 0 && (
                <UI.ContextMenu
                  align="right"
                  button={
                    <UI.Button
                      variant="outline"
                      color="white"
                      className={css.DraftsButton}
                    >
                      <span className={css.DraftIcon}>
                        {tipCount > 99 ? "99+" : tipCount}
                      </span>
                      {t("drafts")}
                    </UI.Button>
                  }
                >
                  <ul className={css.ContextOptions}>
                    <li>
                      <h4 className={css.DraftTitle}>{t("drafts")}</h4>
                    </li>
                    {tips.map((tip) => (
                      <li key={tip.id}>
                        <UI.Button
                          title={t("click-edit")}
                          variant="text"
                          color="third"
                          onClick={() => tipDialog(tip)}
                          className={css.TipDraftButton}
                        >
                          <span>{tip.title ?? t("untitled-tip")}</span>

                          {tip.venue && (
                            <span className={css.VenueName}>
                              {` · ${tip.venue.name}`}
                            </span>
                          )}
                        </UI.Button>
                      </li>
                    ))}
                  </ul>
                </UI.ContextMenu>
              )}
              <UI.ContextMenu
                align="right"
                button={
                  <UI.Button variant="fill" className={css.CreateNewButton}>
                    <Icons.Add />
                    {t("create")}
                  </UI.Button>
                }
              >
                <ul className={css.ContextOptions}>
                  <li>
                    <h4>{t("create-new")}</h4>
                  </li>
                  <li>
                    <UI.Button
                      variant="text"
                      className={css.CreateButton}
                      onClick={() => tipListDialog()}
                    >
                      <Icons.AddToTipList />
                      {t("new-list")}
                    </UI.Button>
                  </li>
                  <li>
                    <UI.Button
                      variant="text"
                      className={css.CreateButton}
                      onClick={() => tipDialog()}
                    >
                      <Icons.Tip />

                      <span>{t("new-tip")}</span>
                    </UI.Button>
                  </li>
                </ul>
              </UI.ContextMenu>

              <UI.ContextMenu
                button={
                  auth.profileImage.thumb ? (
                    <img
                      src={auth.profileImage.thumb}
                      alt=""
                      className={css.Account}
                    />
                  ) : (
                    <Icons.Avatar className={css.Account} />
                  )
                }
              >
                <ul className={css.ContextOptions}>
                  <li>
                    <h4>{t("account")}</h4>
                  </li>

                  {showMyProfile && (
                    <li>
                      <UI.Button
                        variant="text"
                        className={css.CreateButton}
                        onClick={() => location.push(`/${auth.slug}`)}
                      >
                        <Icons.User />
                        {t("my-profile")}
                      </UI.Button>
                    </li>
                  )}

                  <li>
                    <UI.Button
                      variant="text"
                      className={css.CreateButton}
                      onClick={() => editProfileDialog()}
                    >
                      <Icons.EditThin className={css.CreateButton} />
                      {t("edit-profile")}
                    </UI.Button>
                  </li>

                  <li>
                    <UI.Button
                      variant="text"
                      onClick={signOut}
                      className={css.SignOut}
                    >
                      {t("log-out")}
                    </UI.Button>
                  </li>
                </ul>
              </UI.ContextMenu>
            </>
          ) : (
            <>
              <div
                className={[
                  css.About,
                  location.pathname === "/about" && css.AboutHighlight,
                ].join(" ")}
              >
                <Link href={"/about"}>
                  <a>
                    <span>{t("about")}</span>
                  </a>
                </Link>
              </div>

              <div className={css.Globe}>
                <UI.Button
                  variant="text"
                  className={css.CreateButton}
                  onClick={() => changeLanguageDialog()}
                >
                  <Icons.GlobeFilled className={css.CreateButton} />
                </UI.Button>
              </div>

              <UI.Button
                variant="outline"
                color="white"
                onClick={() => signInDialog()}
              >
                {t("sign-in")}
              </UI.Button>

              <UI.Button variant="fill" onClick={() => signUpDialog()}>
                {t("create-account")}
              </UI.Button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default memo(Header);
