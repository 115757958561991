import React from "react";

export default function Share({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 28 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill="#555"
        d="M15.815 0C17.575 0 19 1.45 19 3.235s-1.424 3.234-3.185 3.234a3.155 3.155 0 0 1-2.378-1.084l-6.284 3.44c.14.364.216.76.216 1.175 0 .542-.13 1.052-.363 1.501l6.008 3.725a3.177 3.177 0 0 1 2.801-1.695c1.76 0 3.185 1.45 3.185 3.234C19 18.55 17.576 20 15.815 20c-1.76 0-3.184-1.45-3.184-3.235l.003-.138-6.53-4.046a3.138 3.138 0 0 1-1.92.654C2.425 13.235 1 11.785 1 10s1.424-3.235 3.185-3.235c.852 0 1.626.34 2.197.893l6.382-3.493a3.282 3.282 0 0 1-.133-.93C12.63 1.45 14.055 0 15.815 0Zm0 14.926c-.992 0-1.8.822-1.8 1.84 0 1.017.808 1.839 1.8 1.839.993 0 1.8-.822 1.8-1.84 0-1.017-.807-1.839-1.8-1.839ZM4.185 8.161c-.993 0-1.8.822-1.8 1.839s.807 1.84 1.8 1.84c.992 0 1.8-.823 1.8-1.84 0-1.017-.808-1.84-1.8-1.84Zm11.63-6.766c-.992 0-1.8.822-1.8 1.84 0 1.017.808 1.839 1.8 1.839.993 0 1.8-.822 1.8-1.84 0-1.017-.807-1.839-1.8-1.839Z"
      />
    </svg>
  );
}
