import React from "react";

type LogoColors =
  | "light"
  | "dark-sliced"
  // | "purple-sliced"
  // | "blue-sliced"
  // | "dark"
  // | "purple"
  | "outlined";

export default function Logo({
  className,
  color = "dark-sliced",
}: {
  className?: string;
  color: LogoColors;
}): JSX.Element {
  const fillColor: Partial<{
    [Key in LogoColors]: { left: string; ring: string; right: string };
  }> = {
    "dark-sliced": {
      left: "var(--color-grey600)",
      ring: "var(--color-grey400)",
      right: "var(--color-grey800)",
    },
    light: {
      left: "white",
      ring: "white",
      right: "rgba(29, 41, 57, 0.1)",
    },
  };

  return (
    <svg
      width="30"
      height="45"
      viewBox="0 0 30 45"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0002 40.3333C15.9168 40.3333 29.6668 23.851 29.6668 15.3651C29.6668 6.87918 23.1003 0 15.0002 0C6.89999 0 0.333496 6.87918 0.333496 15.3651C0.333496 23.851 14.0835 40.3333 15.0002 40.3333ZM10.9991 21.7414C11.2135 21.9138 11.493 22 11.8378 22C12.1919 22 12.4714 21.9138 12.6764 21.7414C12.8814 21.5588 13.0491 21.2849 13.1796 20.9198L15.0106 15.4122L16.8136 20.9046C16.9348 21.2799 17.1025 21.5588 17.3168 21.7414C17.5311 21.9138 17.8107 22 18.1555 22C18.5002 22 18.7751 21.9138 18.9801 21.7414C19.1944 21.5588 19.3621 21.2849 19.4833 20.9198L22.2368 12.6432C22.3859 12.1867 22.3626 11.8013 22.1669 11.4869C21.9712 11.1623 21.6591 11 21.2304 11C20.8857 11 20.6154 11.0913 20.4198 11.2739C20.2334 11.4463 20.0843 11.7201 19.9725 12.0954L18.1135 17.9986L16.2965 12.1107C16.1754 11.7252 16.0216 11.4463 15.8352 11.2739C15.6489 11.0913 15.4019 11 15.0944 11C14.7776 11 14.5214 11.0913 14.3257 11.2739C14.1393 11.4463 13.9809 11.7201 13.8505 12.0954L11.9496 17.8769L10.1885 12.0954C10.0766 11.7201 9.92289 11.4463 9.72721 11.2739C9.54085 11.0913 9.27062 11 8.91653 11C8.40404 11 8.04529 11.1623 7.84029 11.4869C7.63529 11.8013 7.612 12.1867 7.7704 12.6432L10.496 20.9046C10.6171 21.2799 10.7848 21.5588 10.9991 21.7414Z"
        fill={fillColor[color]?.left}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41183 36.8828C4.60366 37.5652 2.04541 38.8319 2.04541 40.2818C2.04541 42.4547 7.79109 44.2161 14.8787 44.2161C21.9664 44.2161 27.7121 42.4547 27.7121 40.2818C27.7121 38.8319 25.1538 37.5652 21.3456 36.8828C20.8518 37.4848 20.3616 38.0474 19.8808 38.5627C21.2287 38.7482 22.4273 39.0043 23.4221 39.3093C24.4761 39.6324 25.1993 39.9798 25.621 40.2818C25.1993 40.5839 24.4761 40.9313 23.4221 41.2544C21.3155 41.9002 18.2945 42.3271 14.8787 42.3271C11.463 42.3271 8.44199 41.9002 6.3354 41.2544C5.28134 40.9313 4.55816 40.5839 4.1365 40.2818C4.55816 39.9798 5.28134 39.6324 6.3354 39.3093C7.33015 39.0043 8.5288 38.7482 9.87664 38.5628C9.39581 38.0474 8.90559 37.4848 8.41183 36.8828ZM25.9241 40.0102C25.9241 40.0102 25.922 40.0152 25.9146 40.0251C25.9199 40.0151 25.9241 40.0102 25.9241 40.0102ZM3.83335 40.0102C3.83335 40.0102 3.83759 40.0151 3.84296 40.0251C3.83552 40.0153 3.83335 40.0102 3.83335 40.0102ZM3.83335 40.5535C3.83335 40.5535 3.83552 40.5484 3.84296 40.5385C3.83759 40.5486 3.83335 40.5535 3.83335 40.5535ZM25.9146 40.5386C25.922 40.5484 25.9241 40.5535 25.9241 40.5535C25.9241 40.5535 25.9199 40.5486 25.9146 40.5386Z"
        fill={fillColor[color]?.ring}
      />
      <path
        d="M29.6668 15.2664C29.6668 23.6978 15.8907 40.3333 15.0001 40.3333C15.0001 40.3333 15.07 23.6978 15.07 15.2664C15.07 6.83501 14.8789 8.46014 14.8789 0C22.7482 0 29.6668 6.83501 29.6668 15.2664Z"
        fill={fillColor[color]?.right}
        fillOpacity="0.4"
      />
    </svg>
  );
}
