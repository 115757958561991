import Tip from "./Tip";
import TipList from "./TipList";
import User from "./User";
import TipSmall from "./TipSmall";

export default {
  Tip,
  TipList,
  User,
  TipSmall,
};
