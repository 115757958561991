import Icons from "Components/Icons";
import Warning from "Components/Icons/Warning";
import Success from "Components/Icons/Success";
import Error from "Components/Icons/Error";
import React, { ReactNode } from "react";
import UI from "..";
import Button from "../Button";
import css from "./Notifications.module.css";

export type MessageType = "neutral" | "alert" | "error";

export type NotificationProps = {
  type: MessageType;
  timeoutMs: number;
  transitionTimeMs: number;
  message?: React.ReactNode;
  title?: String | null;
};

function getIcon(type: MessageType): ReactNode {
  switch (type) {
    case "neutral":
      return <Success />;
    case "alert":
      return <Warning />;
    case "error":
      return <Error />;
  }
}

function Notification({
  type,
  timeoutMs,
  transitionTimeMs,
  message = null,
  title = "",
}: NotificationProps): JSX.Element | null {
  const [expanded, setExpanded] = React.useState(false);
  const selfRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    setExpanded(true);

    setTimeout(async () => {
      setExpanded(false);
    }, timeoutMs + transitionTimeMs);
  }, [timeoutMs, transitionTimeMs]);

  return (
    <UI.Collapsible
      expanded={expanded}
      transitionTimeMs={transitionTimeMs}
      className={css.Notification}
    >
      <article
        className={[
          css.Wrapper,
          {
            neutral: css.Neutral,
            alert: css.Alert,
            error: css.Error,
          }[type],
        ].join(" ")}
        style={{
          opacity: expanded ? 1 : 0,
          transition: `opacity ${transitionTimeMs}ms`,
        }}
        ref={selfRef}
      >
        <div className={css.TopBox}>
          <div className={css.Icon}>{getIcon(type)}</div>
          {title}
          <Button
            className={css.Close}
            variant="text"
            onClick={() => setExpanded(false)}
          >
            <Icons.Close />
          </Button>
        </div>
        <p className={css.Message}>{message}</p>
      </article>
    </UI.Collapsible>
  );
}

export default function useNotifications(): [
  JSX.Element,
  (props: Partial<NotificationProps>) => void
] {
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);

  const showNotification = React.useCallback(
    ({
      type = "neutral",
      timeoutMs = 3000,
      transitionTimeMs = 400,
      message = null,
      title = "",
    }: Partial<NotificationProps>) => {
      const notification = (
        <Notification
          key={`.${new Date().getTime()}`}
          type={type}
          timeoutMs={timeoutMs}
          transitionTimeMs={transitionTimeMs}
          message={message}
          title={title}
        />
      );

      setNotifications((current) => [...current, notification]);

      setTimeout(() => {
        setNotifications((current) =>
          current.filter(
            (nextNotification) => nextNotification !== notification
          )
        );
      }, timeoutMs + transitionTimeMs * 2);
    },
    []
  );

  const container = <div className={css.Container}>{notifications}</div>;

  return [container, showNotification];
}
