/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TestimonialModel } from "Models/TestimonialModel";
import { userUpdated } from "./UserReducer";

type TestimonialState = { [id: string]: TestimonialModel };
const initialState: TestimonialState = {};

const testimonialSlice = createSlice({
  name: "testimonial",
  initialState,
  reducers: {
    testimonialUpdated: (state, action: PayloadAction<TestimonialModel[]>) => {
      action.payload.forEach((testimonial) => {
        state[testimonial.id] = testimonial;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userUpdated, (state, action) => {
      const users = action.payload;
      const testimonials = Object.values(state);

      users.forEach((user) => {
        const testimonial = testimonials.find(
          (nextTestimonial) => nextTestimonial.user.id === user.id
        );

        if (testimonial) {
          state[testimonial.id] = { ...testimonial, user };
        }
      });
    });
  },
});

export const { testimonialUpdated } = testimonialSlice.actions;
export default testimonialSlice.reducer;
