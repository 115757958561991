import Icons from "Components/Icons";
import React from "react";
import UI from "Components/UI";
import commonCss from "../SignInSignUpDialog.module.css";
import { NotificationContext } from "App";
import Hooks from "Hooks";
import AuthActions from "Store/Actions/AuthActions";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "react-i18next";

type SignUpProps = {
  onClose: () => void;
  goToSignIn: () => void;
};

type Data = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  emailRegistered: boolean;
  referral: string;
  referralUndefiend: boolean;
};

export default function SignUp({
  onClose,
  goToSignIn,
}: SignUpProps): JSX.Element {
  const { t } = useTranslation();

  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      emailRegistered: false,
      referral: "",
      referralUndefiend: false,
    },
    (nextData) => ({
      firstName:
        nextData.firstName.trim().length === 0
          ? (t("firstname") as string)
          : undefined,
      lastName:
        nextData.lastName.trim().length === 0
          ? (t("lastname") as string)
          : undefined,
      username: !nextData.username.match(/^.+@.+\..+$/)
        ? (t("invalid-email") as string)
        : undefined,
      password:
        !nextData.password.match(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z\w])/) ||
        nextData.password.length < 8 ||
        nextData.password.length > 24
          ? (t("invalid-password") as string)
          : undefined,
      emailRegistered: nextData.emailRegistered
        ? (t("used-email") as string)
        : undefined,
      referralUndefiend: nextData.referralUndefiend
        ? (t("referral-undefiend") as string)
        : undefined,
    })
  );
  const [pending, setPending] = React.useState(false);
  const showNotification = React.useContext(NotificationContext);

  const location = useRouter();
  const { userId, signup } = location.query;

  if (userId && signup != null) {
    data.referral = userId as string;
  }

  const submit = React.useCallback(
    async (nextData: Data) => {
      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        await AuthActions.signUp({
          firstName: nextData.firstName,
          lastName: nextData.lastName,
          username: nextData.username,
          password: nextData.password,
          referral: nextData.referral?.length > 0 ? nextData.referral : null,
        });

        showNotification({
          title: t("account-created"),
          message: <>{t("account-created-message")}</>,
          timeoutMs: 7000,
        });

        onClose();
      } catch (err) {
        const error = err as Error;
        if (error.message === t("used-email")) {
          valid({ emailRegistered: true });
        } else if (error.message === t("referral-undefiend")) {
          valid({ referralUndefiend: true });
        }
      } finally {
        setPending(false);
      }
    },
    [onClose, valid, showNotification]
  );

  return (
    <section className={commonCss.Container}>
      <div className={commonCss.Header}>
        <h4>{t("create-account")}</h4>

        <UI.Button
          variant="text"
          onClick={onClose}
          className={commonCss.CloseButton}
        >
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={commonCss.Content}>
        <div className={commonCss.Options}>
          <form
            className={commonCss.Form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              if (!pending) {
                submit(data);
              }
            }}
          >
            <div
              className={[
                commonCss.AlignVertical,
                commonCss.TwoInlineInputs,
              ].join(" ")}
            >
              <div>
                <div className={commonCss.Input}>
                  <UI.TextField
                    autoFocus
                    label={t("create-firstname")}
                    value={data.firstName}
                    onChange={(e) => change({ firstName: e.target.value })}
                  />
                  <UI.ValidationError>{errors.firstName}</UI.ValidationError>
                </div>
              </div>
              <div>
                <div className={commonCss.Input}>
                  <UI.TextField
                    label={t("create-lastname")}
                    value={data.lastName}
                    onChange={(e) => change({ lastName: e.target.value })}
                  />
                  <UI.ValidationError>{errors.lastName}</UI.ValidationError>
                </div>
              </div>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                type="email"
                label={t("email")}
                value={data.username}
                onChange={(e) =>
                  change({
                    username: e.target.value,
                    emailRegistered: false,
                  })
                }
              />
              <UI.ValidationError>
                {errors.username ?? errors.emailRegistered}
              </UI.ValidationError>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                type="password"
                label={t("password")}
                value={data.password}
                onChange={(e) => change({ password: e.target.value })}
              />
              <UI.ValidationError>{errors.password}</UI.ValidationError>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                disabled={!!userId && signup != null}
                label={t("create-referral")}
                value={data.referral}
                onChange={(e) => change({ referral: e.target.value })}
              />
              <UI.ValidationError>
                {errors.referralUndefiend}
              </UI.ValidationError>
            </div>

            <UI.Button color="primary">
              {pending ? t("creating-account") : t("create-account")}
            </UI.Button>
          </form>
          <h6 className={commonCss.LightText}>{t("or")}</h6>
          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Google sign up
            }}
          >
            <Icons.Google className={commonCss.OptionsIcon} />
            {t("create-google")}
          </UI.Button>

          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Facebook sign up
            }}
          >
            <Icons.Facebook className={commonCss.OptionsIcon} />
            {t("create-facebook")}
          </UI.Button>
        </div>

        <div className={commonCss.Navigation}>
          <span className={commonCss.LightText}>{t("already-member")}</span>
          <UI.Button variant="text" color="link" onClick={goToSignIn}>
            {t("sign-in")}
          </UI.Button>
        </div>
      </div>
    </section>
  );
}
