import Icons from "Components/Icons";
import UI from "Components/UI";
import { useTranslation } from "react-i18next";
import css from "./ChangeLanguageDialog.module.css";

const languages = {
  en: { nativeName: "English" },
  sv: { nativeName: "Svenska" },
};

const ChangeLanguageDialog = ({
  onClose,
}: {
  onClose: () => Promise<void>;
}) => {
  const { t, i18n } = useTranslation();

  return (
    <section className={css.Container}>
      <div className={css.Header}>
        <h5>{t("choose-language")}</h5>

        <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>
      <div className={css.Content}>
        {Object.keys(languages).map((language) => (
          <UI.Button
            variant="outline"
            color="primary"
            onClick={() => {
              i18n.changeLanguage(language);
              localStorage.setItem("lng", language);
              onClose();
            }}
            disabled={language === i18n.resolvedLanguage}
          >
            {languages[language].nativeName}
          </UI.Button>
        ))}
      </div>
    </section>
  );
};

export default ChangeLanguageDialog;
