import React from "react";

export default function Email({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M17.3334 0.666992H2.66671C1.65837 0.666992 0.842541 1.49199 0.842541 2.50033L0.833374 13.5003C0.833374 14.5087 1.65837 15.3337 2.66671 15.3337H17.3334C18.3417 15.3337 19.1667 14.5087 19.1667 13.5003V2.50033C19.1667 1.49199 18.3417 0.666992 17.3334 0.666992ZM17.3334 4.33366L10 8.91699L2.66671 4.33366V2.50033L10 7.08366L17.3334 2.50033V4.33366Z" />
    </svg>
  );
}
