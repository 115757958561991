import React from "react";
import Link from "next/link";
import globalCss from "Global.module.css";
import facebookIcon from "Assets/Icons/facebook.svg";
import instagramIcon from "Assets/Icons/instagram.svg";
import linkedinIcon from "Assets/Icons/linkedin.svg";
import twitterIcon from "Assets/Icons/twitter.svg";
import css from "./Footer.module.css";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

export default function Footer(): JSX.Element {
  const { t } = useTranslation();
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });
  return (
    <footer className={css.Container}>
      <div className={[globalCss.Content, css.Content].join(" ")}>
        {isPhone ? (
          <ul className={css.MobileSiteLinks}>
            <div className={css.MobileGrouped}>
              <li>
                <Link href={"/about"}>{t("about")}</Link>
              </li>
              <Link href={"/faq"}>{t("faq")}</Link>
              <Link href={"/home"}>{t("pricing")}</Link>
            </div>
            <div className={css.MobileGrouped}>
              <Link href={"/home"}>{t("careers")}</Link>
              <Link href={"/home"}>{t("help")}</Link>
              <Link href={"/home"}>{t("privacy")}</Link>
            </div>
          </ul>
        ) : (
          <ul className={css.SiteLinks}>
            <li>
              <Link href={"/faq"}>{t("faq")}</Link>
            </li>
            <li>
              <Link href={"/home"}>{t("who-we-are")}</Link>
            </li>
            <li>
              <Link href={"/home"}>{t("contact-us")}</Link>
            </li>
            <li>
              <Link href={"/home"}>{t("report-abuse")}</Link>
            </li>
            <li>
              <Link href={"/home"}>{t("site-map")}</Link>
            </li>
            <li>
              <Link href={"/home"}>{t("cookie-policy")}</Link>
            </li>
          </ul>
        )}

        <div className={css.divider} />

        <div className={css.Info}>
          <nav className={css.SocialLinks}>
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </nav>
          <span>{t("copyright-res-inside")}</span>
        </div>
      </div>
    </footer>
  );
}
