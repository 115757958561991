import React from "react";

export default function CarouselButton({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="50%" cy="50%" r="50%" fill="white" />
      <circle cx="50%" cy="50%" r="45%" fill="#475467" />

      <path
        d="M14.1666 20H25.8333M25.8333 20L20 14.1666M25.8333 20L20 25.8333"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
