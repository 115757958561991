import React from "react";

export default function Star({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M17.3333 6.19999L11.3417 5.68332L8.99999 0.166656L6.65832 5.69166L0.666656 6.19999L5.21666 10.1417L3.84999 16L8.99999 12.8917L14.15 16L12.7917 10.1417L17.3333 6.19999ZM8.99999 11.3333L5.86666 13.225L6.69999 9.65832L3.93332 7.25832L7.58332 6.94166L8.99999 3.58332L10.425 6.94999L14.075 7.26666L11.3083 9.66666L12.1417 13.2333L8.99999 11.3333Z" />
    </svg>
  );
}
