import AddPhoto from "./AddPhoto";
import BackArrow from "./BackArrow";
import Star from "./Star";
import Chevron from "./Chevron";
import Close from "./Close";
import Remove from "./Remove";
import Edit from "./Edit";
import Email from "./Email";
import Facebook from "./Facebook";
import Web from "./Web";
import Google from "./Google";
import Drafts from "./Drafts";
import Tip from "./Tip";
import Location from "./Location";
import Money from "./Money";
import Phone from "./Phone";
import Search from "./Search";
import Category from "./Category";
import AddToTipList from "./AddToTipList";
import Following from "./Following";
import Followers from "./Followers";
import Menu from "./Menu";
import Plus from "./Plus";
import Check from "./Check";
import Move from "./Move";
import Report from "./Report";
import List from "./List";
import FeaturedGlobe from "./FeaturedGlobe";
import FeaturedShare from "./FeaturedShare";
import FeaturedUniverse from "./FeaturedUniverse";
import TipFilled from "./TipFilled";
import ListFilled from "./ListFilled";
import SearchFilled from "./SearchFilled";
import StarFilled from "./StarFilled";
import ListCheckFilled from "./ListCheckFilled";
import GeometryFilled from "./GeometryFilled";
import Details from "./Details";
import Add from "./Add";
import Avatar from "./Avatar";
import CarouselButton from "./CarouselButton";
import EditThin from "./EditThin";
import Geometry from "./Geometry";
import Upload from "./Upload";
import ListAdd from "./ListAdd";
import User from "./User";
import Success from "./Success";
import Warning from "./Warning";
import Error from "./Error";
import LogoIconFilled from "./LogoIconFilled";
import GlobeFilled from "./GlobeFilled";
import Globe from "./Globe";
import CompassFilled from "./CompassFilled";
import Copy from "./Copy";
import Map from "./Map";
import MapPainted from "./MapPainted";
import HomepageStar from "./HomepageStar";
import HomepageCity from "./HomepageCity";
import HomepageShare from "./HomepageShare";
import SquareBox from "./SquareBox";
import StarPainted from "./StarPainted";
import Logo from "./Logo";
import LogoOutlined from "./LogoOutlined";
import PlusCircle from "./PlusCircle";
import Logout from "./Logout";
import LocationNew from "./LocationNew";
import PhoneNew from "./PhoneNew";
import WebNew from "./WebNew";
import RESIcon from "./RESIcon";
import Share from "./Share";

export default {
  WebNew,
  PhoneNew,
  LocationNew,
  SquareBox,
  Drafts,
  Menu,
  Email,
  Facebook,
  Google,
  AddPhoto,
  BackArrow,
  Close,
  Tip,
  Search,
  Following,
  Followers,
  Category,
  Star,
  Chevron,
  AddToTipList,
  Location,
  Money,
  Phone,
  Web,
  Edit,
  EditThin,
  Remove,
  Plus,
  Check,
  Move,
  Report,
  List,
  FeaturedGlobe,
  FeaturedShare,
  FeaturedUniverse,
  TipFilled,
  ListFilled,
  SearchFilled,
  StarFilled,
  ListCheckFilled,
  GeometryFilled,
  Details,
  Add,
  Avatar,
  CarouselButton,
  Geometry,
  Upload,
  ListAdd,
  User,
  Success,
  Warning,
  Error,
  LogoIconFilled,
  Globe,
  GlobeFilled,
  CompassFilled,
  Copy,
  Map,
  MapPainted,
  HomepageCity,
  HomepageStar,
  HomepageShare,
  StarPainted,
  Logo,
  LogoOutlined,
  PlusCircle,
  Logout,
  RESIcon,
  Share,
};
