import React from "react";

export default function Copy({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10.3333 0.666504H7.66659V1.99984H10.3333C11.4333 1.99984 12.3333 2.89984 12.3333 3.99984C12.3333 5.09984 11.4333 5.99984 10.3333 5.99984H7.66659V7.33317H10.3333C12.1733 7.33317 13.6666 5.83984 13.6666 3.99984C13.6666 2.15984 12.1733 0.666504 10.3333 0.666504ZM6.33325 5.99984H3.66659C2.56659 5.99984 1.66659 5.09984 1.66659 3.99984C1.66659 2.89984 2.56659 1.99984 3.66659 1.99984H6.33325V0.666504H3.66659C1.82659 0.666504 0.333252 2.15984 0.333252 3.99984C0.333252 5.83984 1.82659 7.33317 3.66659 7.33317H6.33325V5.99984ZM4.33325 3.33317H9.66659V4.6665H4.33325V3.33317Z" />
    </svg>
  );
}
