import { useRouter } from "next/dist/client/router";
import React, { useState } from "react";
import ForgotPassword from "./Views/ForgotPassword";

import SignIn from "./Views/SignIn";
import SignUp from "./Views/SignUp";

export type SignInSignUpDialogProps = {
  onClose: () => void;
  initialView?: "signIn" | "signUp" | "forgotPassword";
};

export default function SignInSignUpDialog({
  onClose,
  initialView = "signIn",
}: SignInSignUpDialogProps): JSX.Element {
  const [view, setView] = useState(initialView);
  const location = useRouter();

  switch (view) {
    case "signIn":
      return (
        <SignIn
          onClose={onClose}
          goToSignUp={() => setView("signUp")}
          goToForgotPassword={() => setView("forgotPassword")}
        />
      );
    case "signUp":
      return (
        <SignUp
          onClose={() => {
            location.replace((location.query.userId as string) ?? "");
            location.query["signup"] = undefined;
            onClose();
          }}
          goToSignIn={() => setView("signIn")}
        />
      );
    case "forgotPassword":
      return (
        <ForgotPassword
          onClose={() => {
            location.query["forgotPassword"] = undefined;
            onClose();
          }}
          goToSignIn={() => setView("signIn")}
        />
      );
  }
}
