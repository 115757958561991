import React from "react";

export default function PlusCircle({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M22.5 14.6667V29.3334M15.1667 22H29.8334M40.8334 22C40.8334 32.1252 32.6252 40.3334 22.5 40.3334C12.3748 40.3334 4.16669 32.1252 4.16669 22C4.16669 11.8748 12.3748 3.66669 22.5 3.66669C32.6252 3.66669 40.8334 11.8748 40.8334 22Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
