/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CountryModel } from "Models/CountryModel";
import { cityUpdated } from "./CityReducer";
import { tipListUpdated } from "./commonActions";

type CountryState = { [id: string]: CountryModel };
const initialState: CountryState = {};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    countryUpdated: (state, action: PayloadAction<CountryModel[]>) => {
      action.payload.forEach((country) => {
        state[country.id] = country;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cityUpdated, (state, action) => {
      const cities = action.payload
        .map((city) => city.country)
        .filter((country) => !!country) as CountryModel[];
      cities.forEach((country) => {
        state[country.id] = country;
      });
    });
    builder.addCase(tipListUpdated, (state, action) => {
      const cities = action.payload
        .map((tipList) => tipList.city.country)
        .filter((country) => !!country) as CountryModel[];
      cities.forEach((country) => {
        state[country.id] = country;
      });
    });
  },
});

export const { countryUpdated } = countrySlice.actions;
export default countrySlice.reducer;
