import { createAction } from "@reduxjs/toolkit";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";

const tipListUpdated = createAction<TipListModel[]>("tipListUpdated");
const tipListRemoved = createAction<TipListModel>("tipListRemoved");
const tipListFollowChanged = createAction<TipListModel>("tipListFollowChanged");
const tipUpdated = createAction<TipModel[]>("tipUpdated");
const tipRemoved = createAction<TipModel>("tipRemoved");
const tipBorrowedChanged = createAction<TipModel>("tipBorrowedChanged");

export {
  tipListUpdated,
  tipListRemoved,
  tipListFollowChanged,
  tipUpdated,
  tipRemoved,
  tipBorrowedChanged,
};
