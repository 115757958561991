import React from "react";

export default function SearchFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.333374 24C0.333374 10.7452 11.0785 0 24.3334 0C37.5882 0 48.3334 10.7452 48.3334 24C48.3334 37.2548 37.5882 48 24.3334 48C11.0785 48 0.333374 37.2548 0.333374 24Z"
        fill="#475467"
      />
      <path
        d="M27.8334 26H27.0434L26.7634 25.73C27.7434 24.59 28.3334 23.11 28.3334 21.5C28.3334 17.91 25.4234 15 21.8334 15C18.2434 15 15.3334 17.91 15.3334 21.5C15.3334 25.09 18.2434 28 21.8334 28C23.4434 28 24.9234 27.41 26.0634 26.43L26.3334 26.71V27.5L31.3334 32.49L32.8234 31L27.8334 26ZM21.8334 26C19.3434 26 17.3334 23.99 17.3334 21.5C17.3334 19.01 19.3434 17 21.8334 17C24.3234 17 26.3334 19.01 26.3334 21.5C26.3334 23.99 24.3234 26 21.8334 26Z"
        fill="white"
      />
    </svg>
  );
}
