import UI from "Components/UI";
import React, { useState } from "react";
import css from "./ReportTipDialog.module.css";
import { TipModel } from "Models/TipModel";
import { NotificationContext } from "App";
import TipActions from "Store/Actions/TipActions";
import Icons from "Components/Icons";
import Spinner from "Components/UI/Spinner";
import { useTranslation } from "react-i18next";

type ReportTipProps = {
  value: TipModel;
  onClose: () => void;
};

export default function ReportTipDialog({
  value,
  onClose,
}: ReportTipProps): JSX.Element {
  const { t } = useTranslation();
  const [reportMessage, setReportMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const showNotification = React.useContext(NotificationContext);

  const reportTip = async () => {
    setLoading(true);
    const responseMessage = await TipActions.report(value, reportMessage);
    if (responseMessage === "") {
      showNotification({
        title: value.title + t("has-been-reported"),
        message: <>{t("inspect-report")}</>,
        type: "neutral",
        timeoutMs: 4000,
      });
    } else {
      showNotification({
        title: "Error",
        message: <>{responseMessage}</>,
        type: "error",
        timeoutMs: 4000,
      });
    }
    setLoading(false);
    onClose();
  };

  return (
    <div className={css.ReportReason}>
      <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
        <Icons.Close />
      </UI.Button>
      <div className={css.Body}>
        <h4 className={css.ModalTitle}>{t("reason-for-report")}</h4>
        <UI.MultiLineField
          className={css.InputReport}
          placeholder={t("reason")}
          onChange={(e) => setReportMessage(e.target.value)}
          style={{ minHeight: 100 }}
        />
        {loading ? (
          <Spinner />
        ) : (
          <UI.Button variant="text" onClick={() => reportTip()}>
            <span>{t("confirm")}</span>
          </UI.Button>
        )}
      </div>
    </div>
  );
}
