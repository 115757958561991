import React, { useEffect, useRef } from "react";
import css from "./ConfirmationCode.module.css";
const ConfirmationCode = ({
  codeLength = 6,
  label = "Confirmation Code",
  onChange,
  value,
}: {
  codeLength?: number;
  label?: string;
  onChange: any;
  value: number[];
}) => {
  useEffect(() => {
    onChange(
      Array.from(Array(codeLength).keys()).map(
        (_, index) => value[index] ?? undefined
      )
    );
  }, [codeLength, onChange, value]);

  const ref = useRef<HTMLInputElement>(null);

  const handleChange = (e, x) => {
    onChange(
      value.map((val, index) =>
        x === index &&
        (Number(e.target.value) ||
          e.target.value === "" ||
          e.target.value === "0")
          ? e.target.value
          : val
      )
    );

    if (ref.current == null) {
      return;
    }
    const children = ref.current.parentElement?.parentElement?.children;

    if (children == undefined) {
      return;
    }

    const backspace = Number(e.target.value) == 0 && e.target.value != "0";

    if (x < 5 && !backspace && !isNaN(Number(e.target.value))) {
      (children[x + 1].firstChild as HTMLElement).focus();
    }
  };

  const handleUp = (e, x) => {
    const backspace = "Backspace";
    const key = e.key;

    if (ref.current == null) {
      return;
    }
    const children = ref.current.parentElement?.parentElement?.children;

    if (children == undefined) {
      return;
    }

    if (key == backspace && x > 0) {
      (children[x - 1].firstChild as HTMLElement).focus();
    }
  };

  const handleDown = (e, x) => {
    if (ref.current == null) {
      return;
    }
    const children = ref.current.parentElement?.parentElement?.children;

    if (children == undefined) {
      return;
    }

    if (
      String(value[x]) != "undefined" &&
      String(value[x]) != "" &&
      e.key != "Backspace" &&
      x < 5 &&
      !isNaN(Number(e.key))
    ) {
      (children[x + 1].firstChild as HTMLElement).focus();
    }
  };

  return (
    <div>
      <label className={css.Label}>{label}</label>
      <div className={css.inputContainer}>
        {Array.from(Array(codeLength).keys()).map((x) => (
          <div key={x}>
            <input
              ref={ref}
              value={value[x] ?? ""}
              maxLength={1}
              onKeyDown={(e) => handleDown(e, x)}
              onChange={(e) => handleChange(e, x)}
              onKeyUp={(e) => handleUp(e, x)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(
  ConfirmationCode,
  (prevProps, nextProps) =>
    prevProps.value.toString() === nextProps.value.toString()
);
