import React from "react";

export default function AddPhoto({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4 5.33301V1.33301H6.66667V5.33301H10.6667V7.99967H6.66667V11.9997H4V7.99967H0V5.33301H4ZM8 13.333V9.33301H12V5.33301H21.3333L23.7733 7.99967H28C29.4667 7.99967 30.6667 9.19967 30.6667 10.6663V26.6663C30.6667 28.133 29.4667 29.333 28 29.333H6.66667C5.2 29.333 4 28.133 4 26.6663V13.333H8ZM17.3333 25.333C21.0133 25.333 24 22.3463 24 18.6663C24 14.9863 21.0133 11.9997 17.3333 11.9997C13.6533 11.9997 10.6667 14.9863 10.6667 18.6663C10.6667 22.3463 13.6533 25.333 17.3333 25.333ZM13.0667 18.6663C13.0667 21.0263 14.9733 22.933 17.3333 22.933C19.6933 22.933 21.6 21.0263 21.6 18.6663C21.6 16.3063 19.6933 14.3997 17.3333 14.3997C14.9733 14.3997 13.0667 16.3063 13.0667 18.6663Z" />
    </svg>
  );
}
