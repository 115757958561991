import { PaginationModel } from "Models/PaginationModel";
import { VenueModel } from "Models/VenueModel";
import ServiceUtils from "Services/ServiceUtils";
import MiscUtils from "Utils/MiscUtils";

function parse(venue: VenueModel | null): VenueModel | null {
  if (!venue) {
    return null;
  }

  const nextVenue: VenueModel = {
    ...venue,
    createdAt: new Date(venue.createdAt),
    modifiedAt: new Date(venue.modifiedAt),
    image: MiscUtils.getImageModel(venue.imageId, venue.fullName),
  };

  return nextVenue;
}

async function get(id: string): Promise<VenueModel | null> {
  try {
    const { data: venue } = await ServiceUtils.http.get<VenueModel>(
      `/locations/venues/${id}`
    );

    return parse(venue);
  } catch (err) {
    return null;
  }
}

async function list(
  args: {
    limit?: number;
    offset?: number;
    filters?: {
      ids?: string[];
    };
    sort?: {
      tipCount?: boolean;
    };
    query?: string;
  } = {}
): Promise<[VenueModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    query: args.query,
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
  };

  const {
    data: { total, data: venues },
  } = await ServiceUtils.http.get<PaginationModel<VenueModel>>(
    "/locations/venues",
    { params }
  );

  return [venues.map((venue) => parse(venue)) as VenueModel[], total];
}

export default {
  parse,
  get,
  list,
};
