import { TipCategoryModel } from "Models/TipCategoryModel";
import Services from "Services";
import { AppDispatch } from "Store";
import { categoriesUpdated } from "Store/Reducers/CategoryReducer";

/**
 * Get category.
 * @param dispatch Dispatch function.
 * @param id Category identifier.
 */
async function get(
  dispatch: AppDispatch,
  id: string
): Promise<TipCategoryModel | null> {
  const category = await Services.TipCategory.get(id);
  if (category) {
    dispatch(categoriesUpdated([category]));
  }

  return category;
}

/**
 * List categories.
 * @param dispatch Dispatch function.
 */
async function list(dispatch: AppDispatch): Promise<TipCategoryModel[]> {
  const [categories] = await Services.TipCategory.list();

  dispatch(categoriesUpdated(categories));

  return categories;
}

export default {
  get,
  list,
};
