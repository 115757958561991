import Icons from "Components/Icons";
import ListItem from "Components/ListItem";
import UI from "Components/UI";
import Button from "Components/UI/Button";
import { CityModel } from "Models/CityModel";
import { SearchModel } from "Models/SearchModel";
import { TipListModel } from "Models/TipListModel";
import Services from "Services";
import MiscUtils from "Utils/MiscUtils";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import RadioFilter from "./Components";
import css from "./MainSearch.module.css";
import { UserModel } from "Models/UserModel";

type MainSearchProps = {
  setExpandedCheck?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  placeholder?: string;
  wrapperClassName?: string;
  inputClassName?: string;
};

export default function MainSearch({
  setExpandedCheck,
  className,
  wrapperClassName,
  inputClassName,
  placeholder = "Search",
}: MainSearchProps): JSX.Element {
  const { t } = useTranslation();
  placeholder === "Search" ? t("search") : placeholder;
  const router = useRouter();
  const [result, setResult] = React.useState<SearchModel | null>(null);
  const [visibleResults, setVisibleResults] = useState(5);
  const [backdrop, setBackdrop] = useState(false);

  const pending = React.useRef(false);
  const nextQuery = React.useRef("");
  const [expanded, setExpanded2] = React.useState(false);
  const selfRef = React.useRef<HTMLElement>(null);
  const noResult = React.useMemo(
    () =>
      (result &&
        Object.keys(result).reduce(
          (prev, curr) => prev + result[curr as keyof typeof result].length,
          0
        ) === 0) ??
      true,
    [result]
  );
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });
  const transitionTimeMs = !isPhone ? 300 : 0;
  const [zIndex, setZIndex] = React.useState<number>();
  const nextZIndex = React.useRef<number>();
  const [selectedCategory, setSelectedCategory] = useState<
    "users" | "cities" | "tipLists"
  >("users");

  useEffect(() => {
    if (expanded === false) {
      setResult(null);
      setBackdrop(false);
      setExpandedCheck && setExpandedCheck(false);
      document.body.style.overflow = "unset";
    }
    if (expanded === true) {
      setBackdrop(true);
      setExpandedCheck && setExpandedCheck(true);
    }
  }, [expanded]);

  const setExpanded = React.useCallback(async (nextExpanded) => {
    setExpanded2(nextExpanded);
    nextZIndex.current = nextExpanded ? 1 : undefined;

    if (nextExpanded) {
      setZIndex(nextZIndex.current);
    } else {
      await MiscUtils.sleep(transitionTimeMs);
      setZIndex(nextZIndex.current);
    }
  }, []);

  // Perform search.
  const search = React.useCallback(
    async (query: string) => {
      nextQuery.current = query;
      document.body.style.overflow = "hidden";

      if (pending.current) {
        return;
      }

      pending.current = true;

      const nextResult = await Services.Search.get(query);
      setResult(
        nextResult
          ? {
              cities: nextResult.cities,
              tipLists: nextResult.tipLists,
              // tips: nextResult.tips,  TODO: Uncomment
              tips: [],
              users: nextResult.users,
              // venues: nextResult.venues,  TODO: Uncomment
              venues: [],
            }
          : null
      );
      setExpanded(nextResult !== null);
      // Throttle.
      await MiscUtils.sleep(300);

      pending.current = false;

      if (query !== nextQuery.current) {
        search(nextQuery.current);
      }
    },
    [setExpanded]
  );

  // Blur event.
  React.useEffect(() => {
    const ref = selfRef.current;

    if (!ref) {
      return () => {};
    }

    const clickOutside = (e: MouseEvent) => {
      if (ref.contains(e.target as Node)) {
        return;
      }
      document.body.style.overflow = "unset";
      setExpanded(false);
    };

    document.addEventListener("mousedown", clickOutside);

    return () => document.removeEventListener("mousedown", clickOutside);
  }, [setExpanded]);

  const parseString = React.useCallback(
    (str: string) =>
      str.split(RegExp(`(${nextQuery.current})`, "ig")).map((item, idx) =>
        item.toLowerCase() === nextQuery.current.toLowerCase() ? (
          /* eslint-disable */
          <strong key={idx}>{item}</strong>
        ) : (
          /* eslint-enable */
          item
        )
      ),
    []
  );

  const navigate = React.useCallback(
    async (value: UserModel | CityModel | TipListModel) => {
      if (value.type === "USER") {
        router.push(`/${value.slug}`);
      } else if (value.type === "CITY") {
        router.push(`/cities/${value.slug}`);
      } else if (value.type === "TIP_LIST") {
        router.push(`/${value.user.slug}/list/${value.slug}`);
      }
      setExpanded(false);
    },

    [router, setExpanded]
  );

  const getOpacity = () => {
    if (backdrop && isPhone) {
      return 1;
    }
    if (backdrop) {
      return 0.6;
    }
    return 0;
  };

  const classnamePick = (currentClassName) => {
    if (currentClassName == css.Wrapper) {
      if (expanded && isPhone) {
        return css.WrapperExpandedMobile;
      }
      return css.Wrapper;
    }

    if (currentClassName == css.InputWrapper) {
      if (expanded && isPhone) {
        return css.InputWrapperExpandedMobile;
      }
      return css.InputWrapper;
    }
  };

  return (
    <>
      <div style={{ opacity: getOpacity() }} className={css.Backdrop} />
      <section
        ref={selfRef}
        className={[css.Container, className ?? ""].join(" ")}
        style={{ transition: isPhone ? "border-radius 100ms" : "none", zIndex }}
      >
        <div
          className={[classnamePick(css.Wrapper), wrapperClassName ?? ""].join(
            " "
          )}
        >
          <div className={css.HeaderBar}>
            {expanded && isPhone && (
              <div
                className={css.BackButton}
                onClick={() => setExpanded(false)}
              >
                <Icons.BackArrow />
              </div>
            )}

            <div
              className={[
                classnamePick(css.InputWrapper),
                inputClassName ?? "",
              ].join(" ")}
            >
              <Icons.Search className={css.Icon} />

              <input
                type="text"
                className={css.Input}
                placeholder={placeholder}
                onChange={(e) => search(e.target.value)}
                onFocus={() => {
                  if (!noResult) {
                    setExpanded(true);
                  }
                }}
              />
            </div>
          </div>
          <div className={css.MobileWrapper}>
            <UI.Collapsible
              expanded={expanded}
              className={css.SearchResultWrapper}
              transitionTimeMs={transitionTimeMs}
            >
              <div className={css.SearchResult}>
                <RadioFilter
                  selected={selectedCategory}
                  setSelected={setSelectedCategory}
                  result={result}
                />
                {noResult && (
                  <div className={css.Details}>{t("no-results")}</div>
                )}

                <div className={css.List}>
                  {result?.[selectedCategory]
                    .slice(0, visibleResults)
                    .map((value, index) => {
                      const props = {
                        TIP_LIST: { showAuthor: true, showCity: false },
                        USER: { showSubtitle: true },
                      };
                      return (
                        <span
                          className={css.Item}
                          key={value.id}
                          onClick={() => navigate(value)}
                          onKeyDown={() => {
                            //
                          }}
                          role="button"
                          tabIndex={-1}
                        >
                          <ListItem
                            maxWidth={360}
                            value={value}
                            {...props[value.type]}
                          />
                        </span>
                      );
                    })}
                  {visibleResults <
                    (result?.[selectedCategory].length ?? 0) && (
                    <Button
                      variant="text"
                      color="pink"
                      onClick={() =>
                        setVisibleResults((prev) =>
                          Math.min(
                            prev + 5,
                            result?.[selectedCategory].length ?? 0
                          )
                        )
                      }
                    >
                      {t("show-more")}
                    </Button>
                  )}
                </div>
              </div>
            </UI.Collapsible>
          </div>
        </div>
      </section>
    </>
  );
}
