import React from "react";

export default function Geometry({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1.66669L5.41667 9.16669H14.5833L10 1.66669ZM10 4.86669L11.6083 7.50002H8.38333L10 4.86669ZM14.5833 10.8334C12.5083 10.8334 10.8333 12.5084 10.8333 14.5834C10.8333 16.6584 12.5083 18.3334 14.5833 18.3334C16.6583 18.3334 18.3333 16.6584 18.3333 14.5834C18.3333 12.5084 16.6583 10.8334 14.5833 10.8334ZM14.5833 16.6667C13.4333 16.6667 12.5 15.7334 12.5 14.5834C12.5 13.4334 13.4333 12.5 14.5833 12.5C15.7333 12.5 16.6667 13.4334 16.6667 14.5834C16.6667 15.7334 15.7333 16.6667 14.5833 16.6667ZM2.5 17.9167H9.16667V11.25H2.5V17.9167ZM4.16667 12.9167H7.5V16.25H4.16667V12.9167Z"
        fill="#667085"
      />
    </svg>
  );
}
