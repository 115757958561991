import React from "react";

export default function FeaturedGlobe({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.27808 28C4.27808 14.7452 15.0232 4 28.2781 4C41.5329 4 52.2781 14.7452 52.2781 28C52.2781 41.2548 41.5329 52 28.2781 52C15.0232 52 4.27808 41.2548 4.27808 28Z"
        fill="#FFCA64"
      />
      <path
        d="M38.2781 28C38.2781 33.5228 33.8009 38 28.2781 38M38.2781 28C38.2781 22.4772 33.8009 18 28.2781 18M38.2781 28H18.2781M28.2781 38C22.7552 38 18.2781 33.5228 18.2781 28M28.2781 38C30.7794 35.2616 32.2008 31.708 32.2781 28C32.2008 24.292 30.7794 20.7384 28.2781 18M28.2781 38C25.7768 35.2616 24.3553 31.708 24.2781 28C24.3553 24.292 25.7768 20.7384 28.2781 18M18.2781 28C18.2781 22.4772 22.7552 18 28.2781 18"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.2781 48C17.2324 48 8.27808 39.0457 8.27808 28H0.278076C0.278076 43.464 12.8141 56 28.2781 56V48ZM48.2781 28C48.2781 39.0457 39.3238 48 28.2781 48V56C43.7421 56 56.2781 43.464 56.2781 28H48.2781ZM28.2781 8C39.3238 8 48.2781 16.9543 48.2781 28H56.2781C56.2781 12.536 43.7421 0 28.2781 0V8ZM28.2781 0C12.8141 0 0.278076 12.536 0.278076 28H8.27808C8.27808 16.9543 17.2324 8 28.2781 8V0Z"
        fill="#FFCA64"
      />
    </svg>
  );
}
