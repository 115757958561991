import { CountryModel } from "Models/CountryModel";
import { PaginationModel } from "Models/PaginationModel";
import ServiceUtils from "Services/ServiceUtils";
import MiscUtils from "Utils/MiscUtils";

function parse(country: CountryModel | null): CountryModel | null {
  if (!country) {
    return null;
  }

  const nextCountry: CountryModel = {
    ...country,
    createdAt: new Date(country.createdAt),
    modifiedAt: new Date(country.modifiedAt),
    image: MiscUtils.getImageModel(country.imageId, country.name),
  };

  return nextCountry;
}

async function get(id: string): Promise<CountryModel | null> {
  try {
    const { data: country } = await ServiceUtils.http.get<CountryModel>(
      `/locations/countries/${id}`
    );

    return parse(country);
  } catch (err) {
    return null;
  }
}

async function list(
  args: {
    limit?: number;
    offset?: number;
    filters?: {
      ids?: string[];
    };
    sort?: {
      tipCount?: boolean;
      tipListCount?: boolean;
    };
    query?: string;
  } = {}
): Promise<[CountryModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    query: args.query,
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
  };

  const {
    data: { total, data: countries },
  } = await ServiceUtils.http.get<PaginationModel<CountryModel>>(
    "/locations/countries",
    { params }
  );

  return [countries.map((country) => parse(country)) as CountryModel[], total];
}

export default {
  parse,
  get,
  list,
};
