import Config from "Config";
import { CityModel } from "Models/CityModel";
import { ImageModel } from "Models/ImageModel";
import { UserModel } from "Models/UserModel";

async function sleep(timeMs: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, timeMs));
}

function getImageModel(
  id: string | null,
  altText: string | null = null
): ImageModel {
  const timeStamp = new Date().getTime();

  return {
    thumb: !id ? undefined : `${Config.S3_BASE_URL}/${id}-thumb?${timeStamp}`,
    small: !id ? undefined : `${Config.S3_BASE_URL}/${id}-small?${timeStamp}`,
    medium: !id ? undefined : `${Config.S3_BASE_URL}/${id}-medium?${timeStamp}`,
    large: !id ? undefined : `${Config.S3_BASE_URL}/${id}-large?${timeStamp}`,
    altText: !altText ? "" : altText,
  };
}

function getElementHeight(child: Element): number {
  const { marginTop, marginBottom, height } = window.getComputedStyle(child);

  return (
    Number.parseInt(marginTop, 10) +
    Number.parseInt(marginBottom, 10) +
    Number.parseInt(height, 10)
  );
}

function getChildrenHeight(element: Element): number {
  return Array.from(element.children)
    .map(getElementHeight)
    .reduce((prev, curr) => prev + curr, 0);
}

async function fileToBase64(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() ?? undefined);
    reader.onerror = (error) => reject(error);
  });
}

async function imageToBase64(file: File): Promise<string | undefined> {
  const allowedMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
  ];

  const image = await fileToBase64(file);

  if (!image) {
    return undefined;
  }

  const mimeType = (image.match(/^data:(.+);base64/) ?? [])[1];

  if (allowedMimeTypes.indexOf(mimeType) === -1) {
    throw new Error("Unsupported image type");
  }

  return image;
}

function srcToFile(src: string, fileName: string, mimeType: string) {
  return fetch(src)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], fileName, { type: mimeType });
    });
}

function getCookies(): { [key: string]: string } {
  const result: { [key: string]: string } = {};
  const cookies = document.cookie.matchAll(/([^=]+)=([^;]+);? ?/g);

  Array.from(cookies).forEach((cookie) => {
    const [name, value] = cookie.slice(1);
    result[name] = value;
  });

  return result;
}

function formatNameApos(name: string, language?: string): string {
  if (language !== undefined) {
    if (language === "en") {
      return `${name}'${name.endsWith("s") ? "" : "s"}`;
    } else if (language === "sv") {
      return `${name}${name.endsWith("s") ? "" : "s"}`;
    }
  }

  return `${name}'${name.endsWith("s") ? "" : "s"}`;
}

function capitalize(str: string): string {
  return `${str[0].toUpperCase()}${str.substr(1)}`;
}

function quantify(
  str: string,
  amount: number,
  prependAmount = true,
  language?: string
): string {
  const prefix =
    language !== undefined && language === "en" && amount > 1 ? "s" : "";
  return `${
    prependAmount ? `${amount.toLocaleString("en-US")} ` : ""
  }${str}${prefix}`;
}

function getTipTipListCount(item: {
  tipListCount: number;
  tipCount: number;
}): string {
  return `${quantify("List", item.tipListCount)} · ${quantify(
    "Tip",
    item.tipCount
  )}`;
}

function getFullName(user: UserModel | null): string {
  if (!user) {
    return "";
  }

  return `${user.firstName} ${user.lastName}`;
}

function getCityCountry(item: { city?: CityModel | null } | null): string {
  if (!item) {
    return "";
  }

  return [item.city?.name, item.city?.country?.name]
    .filter((a) => !!a)
    .join(", ");
}

function getDistance(
  { lat, lng }: { lat?: number | null; lng?: number | null },
  { lat: lat2, lng: lng2 }: { lat?: number | null; lng?: number | null }
): { stringDistance: string; numberDistance: number } | null {
  if (
    typeof lat !== "number" ||
    typeof lng !== "number" ||
    typeof lat2 !== "number" ||
    typeof lng2 !== "number"
  ) {
    return null;
  }

  const radlat1 = Math.PI * (lat2 / 180);
  const radlat2 = Math.PI * (lat / 180);
  const theta = lng2 - lng;
  const radtheta = Math.PI * (theta / 180);
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist *= 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  dist *= 1.609344;

  if (dist < 1) {
    return {
      stringDistance: `${(dist * 1000).toFixed(0)} m`,
      numberDistance: dist,
    };
  }

  return { stringDistance: `${dist.toFixed(1)} km`, numberDistance: dist };
}

function removeNullFields<T extends { [key: string]: unknown }>(value: T): T {
  const nextValue: { [key: string]: unknown } = {};

  Object.keys(value).forEach((key) => {
    if (value[key] !== null) {
      nextValue[key] = value[key];
    }
  });

  return nextValue as T;
}

function delimit(delimiter?: string, ...items: (string | undefined)[]): string {
  return items.filter((item) => item !== undefined).join(delimiter ?? ", ");
}

function urlTiplist(): string | null {
  if (typeof window !== "undefined") {
    const location = window.location;
    const parts = location.pathname.split("/");
    if (parts[1] !== "tipLists") return null;
    return parts[2].split("?")[0];
  }
  return null;
}

function validSlugStrucutre(value: string | null): boolean {
  if (!value) return false;

  if (value.length < 6) return false;
  if (value.length > 30) return false;

  return !!value.match(new RegExp("^[a-z0-9-]+$"))?.length;
}

function getSlug(value: string | null): string {
  if (!value) return "";
  const tmp = value
    .toLowerCase()
    .replace(/[0-9]/g, "-")
    .trim()
    .replaceAll(/[åä]/g, "a")
    .replaceAll(/ö/g, "o")
    .replaceAll(", ", "-")
    .replaceAll(" ", "-");
  return tmp;
}

function getMonthString(month: number | undefined): string {
  if (!month) return "";

  const months = {
    0: "January",
    1: "February",
    2: "Mars",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  };
  return months[month];
}

export default {
  getChildrenHeight,
  sleep,
  fileToBase64,
  imageToBase64,
  getCookies,
  formatNameApos,
  capitalize,
  getImageModel,
  quantify,
  getTipTipListCount,
  getFullName,
  getCityCountry,
  getDistance,
  removeNullFields,
  delimit,
  srcToFile,
  urlTiplist,
  validSlugStrucutre,
  getMonthString,
  getSlug,
};
