import Icons from "Components/Icons";
import Button from "Components/UI/Button";
import css from "./BookDialog.module.css";

const BookDialog = ({
  onClose,
  link,
}: {
  onClose: () => Promise<void>;
  link: string;
}) => {
  return (
    <div className={css.Container}>
      <div className={css.ButtonContainer}>
        <Button variant="text" onClick={() => onClose()}>
          <Icons.Close />
        </Button>
      </div>
      <div className={css.IframeContainer}>
        <iframe src={link} height={"100%"} width={"100%"} />
      </div>
    </div>
  );
};

export default BookDialog;
