import React from "react";

export default function Title({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.166656 0.333328V2.83333H4.74999V12.8333H7.24999V2.83333H11.8333V0.333328H0.166656Z"
        fill="#667085"
      />
    </svg>
  );
}
