import React from "react";

export default function Check({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="5 5 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
}
