import React from "react";

export default function TipFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.666687 24C0.666687 10.7452 11.4119 0 24.6667 0V0C37.9215 0 48.6667 10.7452 48.6667 24V24C48.6667 37.2548 37.9215 48 24.6667 48V48C11.4119 48 0.666687 37.2548 0.666687 24V24Z"
        fill="#475467"
      />
      <path
        d="M21.6667 33C21.6667 33.55 22.1167 34 22.6667 34H26.6667C27.2167 34 27.6667 33.55 27.6667 33V32H21.6667V33ZM24.6667 14C20.8067 14 17.6667 17.14 17.6667 21C17.6667 23.38 18.8567 25.47 20.6667 26.74V29C20.6667 29.55 21.1167 30 21.6667 30H27.6667C28.2167 30 28.6667 29.55 28.6667 29V26.74C30.4767 25.47 31.6667 23.38 31.6667 21C31.6667 17.14 28.5267 14 24.6667 14ZM27.5167 25.1L26.6667 25.7V28H22.6667V25.7L21.8167 25.1C20.4667 24.16 19.6667 22.63 19.6667 21C19.6667 18.24 21.9067 16 24.6667 16C27.4267 16 29.6667 18.24 29.6667 21C29.6667 22.63 28.8667 24.16 27.5167 25.1Z"
        fill="white"
      />
    </svg>
  );
}
