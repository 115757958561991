import React from "react";

export default function FeaturedCity({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.203125 24C0.203125 10.7452 10.9483 0 24.2031 0C37.458 0 48.2031 10.7452 48.2031 24C48.2031 37.2548 37.458 48 24.2031 48C10.9483 48 0.203125 37.2548 0.203125 24Z"
        fill="#FDB022"
      />
      <path
        d="M27.2031 23V17L24.2031 14L21.2031 17V19H15.2031V33H33.2031V23H27.2031ZM19.2031 31H17.2031V29H19.2031V31ZM19.2031 27H17.2031V25H19.2031V27ZM19.2031 23H17.2031V21H19.2031V23ZM25.2031 31H23.2031V29H25.2031V31ZM25.2031 27H23.2031V25H25.2031V27ZM25.2031 23H23.2031V21H25.2031V23ZM25.2031 19H23.2031V17H25.2031V19ZM31.2031 31H29.2031V29H31.2031V31ZM31.2031 27H29.2031V25H31.2031V27Z"
        fill="#344054"
      />
    </svg>
  );
}
