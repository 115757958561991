/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CityModel } from "Models/CityModel";

type CityState = { [id: string]: CityModel };
const initialState: CityState = {};

const citySlice = createSlice({
  name: "city",
  initialState,
  reducers: {
    cityUpdated: (state, action: PayloadAction<CityModel[]>) => {
      action.payload.forEach((city) => {
        state[city.id] = city;
      });
    },
  },
});

export const { cityUpdated } = citySlice.actions;
export default citySlice.reducer;
