import React from "react";
import css from "./Button.module.css";

export type ButtonProps = React.PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> & {
  className?: string;
  variant?: "text" | "outline" | "fill";
  color?:
    | "primary"
    | "secondary"
    | "third"
    | "fourth"
    | "fifth"
    | "white"
    | "purple"
    | "link"
    | "pink"
    | "delete";
  size?: "small" | "big";
};

export default function Button({
  children,
  className,
  variant = "fill",
  color = "primary",
  size = "small",
  ...props
}: ButtonProps): JSX.Element {
  return (
    <button
      {...props}
      className={[
        css.Container,
        className ?? "",
        {
          text: css.Text,
          outline: css.Outline,
          fill: css.Fill,
        }[variant],
        {
          primary: css.Primary,
          secondary: css.Secondary,
          link: css.Link,
          third: css.Third,
          fourth: css.Fourth,
          fifth: css.Fifth,
          white: css.White,
          purple: css.Purple,
          pink: css.Pink,
          delete: css.Delete,
        }[color],
        size == "small" ? {} : css.Big,
      ].join("  ")}
    >
      {children}
    </button>
  );
}
