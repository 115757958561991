import { UserModel } from "Models/UserModel";
import { useRouter } from "next/dist/client/router";
import React, { useEffect, useRef, useState } from "react";
import UserService from "Services/UserService";

function useChange<
  T extends Record<string, unknown>,
  U = Partial<{ [key in keyof T]: string }>
>(
  initialState: T,
  validator?: (state: T) => U
): [
  T,
  U,
  (nextData: Partial<T> | React.SetStateAction<T>) => void,
  (current?: Partial<T>, reportErrors?: boolean) => boolean
] {
  const [data, setData] = React.useState<T>(initialState);
  const [errors, setErrors] = React.useState<U>({} as U);

  const change = React.useCallback(
    (nextData: Partial<T> | React.SetStateAction<T>) => {
      if (typeof nextData === "function") {
        setData(nextData);
      }

      setData((current) => ({ ...current, ...nextData }));
      const nextErrors: U = {} as U;

      Object.keys(nextData).forEach((key) => {
        nextErrors[key as keyof U] = undefined as unknown as U[keyof U];
      });

      setErrors((current) => ({ ...current, ...nextErrors }));
    },
    [setData, setErrors]
  );

  const valid = React.useCallback(
    (current?: Partial<T>, reportErrors = true) => {
      if (!validator) {
        return true;
      }

      const nextErrors = validator({ ...data, ...current });

      if (reportErrors) {
        setErrors(nextErrors);
      }

      return (
        Object.values(nextErrors).filter((value) => value !== undefined)
          .length === 0
      );
    },
    [data, validator]
  );

  return [data, errors, change, valid];
}

function useSearch<Params extends { [K in keyof Params]?: string }>(): Params {
  const params = new URLSearchParams("");
  const result: Params = {} as Params;

  params.forEach((val, key) => {
    result[key as keyof Params] = val as Params[keyof Params];
  });

  return result;
}

function usePreviousState<T>(data: T) {
  const previousDataRef = useRef<T>();
  useEffect(() => {
    if (previousDataRef.current === undefined) {
      previousDataRef.current = data;
    }
  }, [data]);
  return previousDataRef.current;
}

function usePreventReloadingAndClosingBrowser() {
  const Router = useRouter();

  React.useEffect(() => {
    const confirmationMessage = "Changes you made may not be saved.";
    const beforeUnloadHandler = (e: BeforeUnloadEvent) => {
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };
    // const beforeRouteHandler = (url: string) => {
    //   console.log(url);
    //   console.log(Router.pathname);
    //   console.log(Router.query);
    //   if (Router.pathname !== url && !confirm(confirmationMessage)) {
    //     // to inform NProgress or something ...
    //     Router.events.emit("routeChangeError");
    //     // tslint:disable-next-line: no-string-throw
    //     throw `Route change to "${url}" was aborted (this error can be safely ignored). See https://github.com/zeit/next.js/issues/2476.`;
    //   }
    // };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    // Router.events.on("routeChangeStart", beforeRouteHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
      // Router.events.off("routeChangeStart", beforeRouteHandler);
    };
  }, [Router.events, Router.pathname]);
}

function useFindUser(id: string | null) {
  const val = useDebounce(id, 1000);

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<UserModel | null | undefined>(undefined);

  useEffect(() => {
    if (val && !loading) {
      setLoading(true);

      UserService.get(val)
        .then((usr) => setUser(usr))
        .catch(() => setUser(null))
        .finally(() => setLoading(false));
    }
  }, [val]);

  return { user, loading };
}

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export default {
  usePreviousState,
  useChange,
  useSearch,
  usePreventReloadingAndClosingBrowser,
  useFindUser,
  useDebounce,
};
