import { PaginationModel } from "Models/PaginationModel";
import { UserModel } from "Models/UserModel";
import { AppDispatch } from "Store";
import { userUpdated } from "Store/Reducers/UserReducer";
import MiscUtils from "Utils/MiscUtils";
import ServiceUtils from "./ServiceUtils";

function parse(user: UserModel | null): UserModel | null {
  if (!user) {
    return null;
  }

  const nextUser: UserModel = {
    ...user,
    createdAt: new Date(user.createdAt),
    modifiedAt: new Date(user.modifiedAt),
    lastLoginAt: user.lastLoginAt ? new Date(user.lastLoginAt) : null,
    profileImage: MiscUtils.getImageModel(
      user.profileImageId,
      `${user.firstName} ${user.lastLoginAt}`
    ),
    coverImage: MiscUtils.getImageModel(user.coverImageId),
    type: "USER",
  };

  return nextUser;
}

async function get(id: string): Promise<UserModel | null> {
  try {
    const { data: user } = await ServiceUtils.http.get<UserModel>(
      `/users/${id}`
    );

    return parse(user);
  } catch (err) {
    return null;
  }
}

async function list(
  args: {
    limit?: number;
    offset?: number;
    filters?: {
      borrowingFromUserId?: string;
      followingUserId?: string;
      cityIds?: string[];
      ids?: string[];
      followingTipListId?: string;
    };
    sort?: {
      tipListCount?: boolean;
      tipCount?: boolean;
      followerCount?: boolean;
      borrowerCount?: boolean;
    };
  } = {}
): Promise<[UserModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
  };

  const {
    data: { total, data: users },
  } = await ServiceUtils.http.get<PaginationModel<UserModel>>("/users", {
    params,
  });

  return [users.map((user) => parse(user)) as UserModel[], total];
}

async function update(
  userId: string,
  firstName: string,
  lastName: string,
  description?: string,
  coverImage?: string | null,
  profileImage?: string | null,
  cityId?: string | null,
  slug?: string | null
): Promise<UserModel | null> {
  try {
    const { data: user } = await ServiceUtils.http.patch<UserModel>(
      `/users/${userId}`,
      {
        firstName,
        lastName,
        description: description ?? null,
        cityId: cityId ?? null,
        profileImageData: profileImage,
        coverImageData: coverImage,
        slug: slug ?? null,
      }
    );

    return parse(user);
  } catch (err) {
    return null;
  }
}

async function listFollowers(args: {
  userId: string;
  limit?: number;
  offset?: number;
}): Promise<[{ user: UserModel; tipListCount: number }[], number]> {
  const params = {
    ...ServiceUtils.getPagination(args.limit, args.offset),
  };

  try {
    const {
      data: { data: users, total },
    } = await ServiceUtils.http.get<
      PaginationModel<{
        user: UserModel;
        tipListCount: number;
      }>
    >(`/users/${args.userId}/followers`, { params });

    const nextUsers = users.map(({ user, tipListCount }) => ({
      user: parse(user) as UserModel,
      tipListCount,
    }));

    return [nextUsers, total];
  } catch (err) {
    return [[], 0];
  }
}

async function listBorrowers(args: {
  userId: string;
  limit?: number;
  offset?: number;
}): Promise<[{ user: UserModel; tipCount?: number }[], number]> {
  const params = {
    ...ServiceUtils.getPagination(args.limit, args.offset),
  };

  try {
    const {
      data: { data: users, total },
    } = await ServiceUtils.http.get<
      PaginationModel<{
        user: UserModel;
        tipCount: number;
      }>
    >(`/users/${args.userId}/borrowers`, { params });

    const nextUsers = users.map(({ user, tipCount }) => ({
      user: parse(user) as UserModel,
      tipCount,
    }));

    return [nextUsers, total];
  } catch (err) {
    return [[], 0];
  }
}

function getLoadMoreFuncFollowersBorrowers<
  T extends typeof listBorrowers | typeof listFollowers
>(
  callback: (
    data: [
      { user: UserModel; tipCount?: number; tipListCount?: number }[],
      number
    ]
  ) => void | Promise<void>,
  dispatch: AppDispatch,
  type: "followers" | "borrowers",
  args: Parameters<T>[0]
): () => Promise<void> {
  const { limit = 1 } = { ...args };
  let offset = 0;

  let pendingReq:
    | Promise<
        [
          { user: UserModel; tipCount?: number; tipListCount?: number }[],
          number
        ]
      >
    | undefined;

  const items: { user: UserModel; tipCount?: number; tipListCount?: number }[] =
    [];
  let total = 0;

  return async (): Promise<void> => {
    if (offset === -1) {
      return;
    }

    if (pendingReq) {
      return;
    }

    pendingReq =
      type === "followers"
        ? listFollowers({ ...args, offset })
        : listBorrowers({ ...args, offset });

    offset += 1;
    const [nextItems, nextTotal] = await pendingReq;
    total = nextTotal;

    dispatch(userUpdated(nextItems.map((item) => item.user)));

    if (nextItems.length < limit) {
      offset = -1;
    }

    items.push(...nextItems);

    await callback([[...items], total]);
    pendingReq = undefined;
  };
}

function getLoadMoreFunc(
  callback: (data: [UserModel[], number]) => void | Promise<void>,
  dispatch: AppDispatch,
  args: Parameters<typeof list>[0]
): () => Promise<void> {
  const { limit = 1 } = { ...args };
  let offset = 0;
  let pendingReq: ReturnType<typeof list> | undefined;
  const items: UserModel[] = [];
  let total = 0;

  return async (): Promise<void> => {
    if (offset === -1) {
      return;
    }

    if (pendingReq) {
      return;
    }

    pendingReq = list({ ...args, offset });
    offset += 1;
    const [nextItems, nextTotal] = await pendingReq;
    total = nextTotal;

    dispatch(userUpdated(nextItems));

    if (nextItems.length < limit) {
      offset = -1;
    }

    items.push(...nextItems);

    await callback([[...items], total]);
    pendingReq = undefined;
  };
}

export default {
  parse,
  get,
  list,
  update,
  listFollowers,
  listBorrowers,
  getLoadMoreFuncFollowersBorrowers,
  getLoadMoreFunc,
};
