import { CityModel } from "Models/CityModel";
import { PaginationModel } from "Models/PaginationModel";
import ServiceUtils from "Services/ServiceUtils";
import MiscUtils from "Utils/MiscUtils";
import CountryService from "./Country";

function parse(city: CityModel | null): CityModel | null {
  if (!city) {
    return null;
  }

  const nextCity: CityModel = {
    ...city,
    createdAt: new Date(city.createdAt),
    modifiedAt: new Date(city.modifiedAt),
    country: CountryService.parse(city.country),
    image: MiscUtils.getImageModel(city.imageId, city.fullName),
    slug: MiscUtils.getSlug(city.fullName),
    type: "CITY",
  };

  return nextCity;
}

async function get(id: string): Promise<CityModel | null> {
  try {
    const { data: city } = await ServiceUtils.http.get<CityModel>(
      `/locations/cities/${id}`
    );

    return parse(city);
  } catch (err) {
    return null;
  }
}

async function list(
  args: {
    limit?: number;
    offset?: number;
    filters?: {
      ids?: string[];
    };
    sort?: {
      tipCount?: boolean;
      tipListCount?: boolean;
    };
    query?: string;
  } = {}
): Promise<[CityModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    query: args.query,
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
  };

  const {
    data: { total, data: cities },
  } = await ServiceUtils.http.get<PaginationModel<CityModel>>(
    "/locations/cities",
    { params }
  );

  return [cities.map((city) => parse(city)) as CityModel[], total];
}

export default {
  parse,
  get,
  list,
};
