import Icons from "Components/Icons";
import React, { useEffect, useState } from "react";
import UI from "Components/UI";
import commonCss from "../SignInSignUpDialog.module.css";
import { NotificationContext } from "App";
import Hooks from "Hooks";
import AuthActions from "Store/Actions/AuthActions";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";

type ForgotPasswordProps = {
  onClose: () => void;
  goToSignIn: () => void;
};

type Data = {
  email: string;
  password: string;
  confirmationCode: number[];
};

export default function SignUp({
  onClose,
  goToSignIn,
}: ForgotPasswordProps): JSX.Element {
  const { t } = useTranslation();
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      email: "",
      password: "",
      confirmationCode: [],
    },
    (nextData) => ({
      email: !nextData.email.match(/^.+@.+\..+$/)
        ? (t("invalid-email") as string)
        : undefined,
      password:
        sentCode &&
        (!nextData.password.match(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z\w])/) ||
          nextData.password.length < 8 ||
          nextData.password.length > 24)
          ? (t("invalid-password") as string)
          : undefined,
    })
  );

  const location = useRouter();

  useEffect(() => {
    const { forgotPassword } = location.query;
    if (forgotPassword) {
      const [email, code] = (forgotPassword as string).split(":");
      change({
        email: email,
        confirmationCode: code.split("").map((s) => parseInt(s, 10)),
      });
      setSentCode(true);
    }
  }, [change, location.query]);

  const [pending, setPending] = React.useState(false);
  const [sentCode, setSentCode] = useState(false);
  const showNotification = React.useContext(NotificationContext);

  const handleResend = async () => {
    change({ confirmationCode: [] });
    try {
      await AuthActions.forgotPassword({
        email: data.email,
      });
      showNotification({
        title: t("password-reset-link"),
        message: <>{t("email-password-reset")}</>,
        timeoutMs: 7000,
      });
    } catch (error) {
      showNotification({
        title: t("error-change-password"),
        message: t("please-try-later"),
        timeoutMs: 7000,
        type: "error",
      });
    }
  };

  const submit = React.useCallback(
    async (nextData: Data) => {
      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        if (!sentCode) {
          await AuthActions.forgotPassword({
            email: nextData.email,
          });
          setSentCode(true);

          showNotification({
            title: t("password-reset-link"),
            message: <>{t("email-password-reset")}</>,
            timeoutMs: 7000,
          });
        } else {
          try {
            await AuthActions.confirmForgotPassword({
              email: nextData.email,
              password: nextData.password,
              confirmationCode: nextData.confirmationCode.join(""),
            });
            showNotification({
              title: t("password-changed"),
              timeoutMs: 7000,
            });
            onClose();
          } catch (error) {
            showNotification({
              title: t("error-change-password"),
              message: t("correct-code"),
              timeoutMs: 7000,
              type: "error",
            });
          }
        }
      } catch (err) {
      } finally {
        setPending(false);
      }
    },
    [onClose, valid, showNotification]
  );

  return (
    <section className={commonCss.Container}>
      <div className={commonCss.Header}>
        <h4>{t("forgot-password")}</h4>

        <UI.Button
          variant="text"
          onClick={onClose}
          className={commonCss.CloseButton}
        >
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={commonCss.Content}>
        <div className={commonCss.Options}>
          <form
            className={commonCss.Form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              if (!pending) {
                submit(data);
              }
            }}
          >
            <div className={commonCss.Input}>
              <UI.TextField
                type="email"
                label={t("email")}
                value={data.email}
                autoFocus
                onChange={(e) =>
                  change({
                    email: e.target.value,
                  })
                }
              />
              <UI.ValidationError>
                {errors.email ?? errors.email}
              </UI.ValidationError>
            </div>

            <div style={{ display: sentCode ? "initial" : "none" }}>
              <div className={commonCss.Input}>
                <UI.TextField
                  type="password"
                  label={t("new-password")}
                  value={data.password}
                  onChange={(e) => change({ password: e.target.value })}
                />
                <UI.ValidationError>{errors.password}</UI.ValidationError>
              </div>
              <div className={commonCss.Input}>
                <UI.ConfirmationCode
                  value={data.confirmationCode}
                  onChange={React.useCallback(
                    (code: number[]) => change({ confirmationCode: code }),
                    [change]
                  )}
                />
              </div>
            </div>

            <UI.Button color="primary">
              {!sentCode && (pending ? t("sending-code") : t("reset-password"))}
              {sentCode &&
                (pending ? t("resetting") : t("confirm-password-reset"))}
            </UI.Button>
          </form>
          {sentCode && (
            <UI.Button variant="text" onClick={handleResend}>
              {t("resend-code")}
            </UI.Button>
          )}
        </div>

        <div className={commonCss.Navigation}>
          <span className={commonCss.LightText}>{t("already-member")} </span>
          <UI.Button variant="text" color="link" onClick={goToSignIn}>
            {t("sign-in")}
          </UI.Button>
        </div>
      </div>
    </section>
  );
}
