import React from "react";
import Icons from "Components/Icons";
import { TipListModel } from "Models/TipListModel";
import Link from "next/link";
import commonCss from "./Common.module.css";
import { useTranslation } from "react-i18next";

type TipListProps = {
  value: TipListModel;
  className?: string;
  onNavigate?: () => void;
};

export default function TipList({
  value,
  className,
  onNavigate,
}: TipListProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={commonCss.ContainerDialog}>
      <Link href={`/${value.user.slug}/list/${value.slug}`}>
        <img
          src={value.image.medium ?? value.city?.image.medium}
          alt=""
          className={commonCss.ImageAvatar}
          onClick={onNavigate}
        />
      </Link>
      <div className={commonCss.InfoBox}>
        <Link href={`/${value.user.slug}/list/${value.slug}`}>
          <a onClick={onNavigate} className={commonCss.TitleTip}>
            {value.title}
          </a>
        </Link>
        <div className={commonCss.NameBox}>
          <Link href={`/${value.user.slug}`}>
            <a className={commonCss.NameText} onClick={onNavigate}>
              {t("by")}
              {[value.user.firstName, value.user.lastName].join(" ")}
            </a>
          </Link>
          <div className={commonCss.FollowerBox}>
            <Icons.Followers className={commonCss.FollowerIcon} />
            {value.followerCount.toLocaleString("en-US")}
            {t("followers")}
          </div>
        </div>
      </div>
    </div>
  );
}
