import React from "react";

export default function ListFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.52588e-05 24C1.52588e-05 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 1.52588e-05 37.2548 1.52588e-05 24Z"
        fill="#475467"
      />
      <path
        d="M16 22.5C15.17 22.5 14.5 23.17 14.5 24C14.5 24.83 15.17 25.5 16 25.5C16.83 25.5 17.5 24.83 17.5 24C17.5 23.17 16.83 22.5 16 22.5ZM16 16.5C15.17 16.5 14.5 17.17 14.5 18C14.5 18.83 15.17 19.5 16 19.5C16.83 19.5 17.5 18.83 17.5 18C17.5 17.17 16.83 16.5 16 16.5ZM16 28.5C15.17 28.5 14.5 29.18 14.5 30C14.5 30.82 15.18 31.5 16 31.5C16.82 31.5 17.5 30.82 17.5 30C17.5 29.18 16.83 28.5 16 28.5ZM19 31H33V29H19V31ZM19 25H33V23H19V25ZM19 17V19H33V17H19Z"
        fill="white"
      />
    </svg>
  );
}
