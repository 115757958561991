import User from "./UserService";
import Tip from "./TipService";
import TipList from "./TipListService";
import TipCategory from "./TipCategoryService";
import Testimonial from "./TestimonialService";
import Search from "./SearchService";
import GoogleMaps from "./GoogleMapsService";
import Auth from "./AuthService";
import ActivityFeed from "./ActivityFeedService";
import Location from "./LocationServices";
import Organization from "./OrganizationService";

export default {
  User,
  Tip,
  TipList,
  TipCategory,
  Testimonial,
  Search,
  GoogleMaps,
  Auth,
  ActivityFeed,
  Location,
  Organization,
};
