import Button from "Components/UI/Button";
import React, { useState } from "react";
import css from "./OnboardingDialog.module.css";
import welcome from "Assets/Images/Onboarding/welcome.png";
import enjoy from "Assets/Images/Onboarding/enjoy.png";
import borrow from "Assets/Images/Onboarding/borrow.png";
import inspire from "Assets/Images/Onboarding/inspire.png";
import { useTranslation } from "react-i18next";

export default function OnboardingDialog({
  onClose,
}: {
  onClose: () => void;
}): JSX.Element {
  const { t } = useTranslation();
  const [index, setIndex] = useState(0);

  const copy = [
    {
      title: t("get-started"),
      text: t("we-are-glad"),
      heroImage: welcome,
    },
    {
      title: t("enjoy"),
      text: t("about-res-inside-text"),
      heroImage: enjoy,
    },
    {
      title: t("borrow"),
      text: t("about-hero-borrow"),
      heroImage: borrow,
    },
    {
      title: t("inspired"),
      text: t("once-started"),
      heroImage: inspire,
    },
  ];

  return (
    <article className={css.Container}>
      <section className={css.Header}>
        <img src={copy[index].heroImage} alt="" />
      </section>
      <section>
        <h4 className={css.Title}>{copy[index].title}</h4>
        <p className={css.Text}>{copy[index].text}</p>
        <div className={css.Meatballs}>
          {copy.map((_, idx) => (
            <span
              style={{
                backgroundColor:
                  index === idx
                    ? "var(--color-primary600)"
                    : "var(--color-primary100)",
              }}
              key={idx}
            />
          ))}
        </div>
      </section>
      <section className={css.Buttons}>
        <Button onClick={onClose} variant="outline" color="secondary">
          {t("skip")}
        </Button>
        <Button
          onClick={() => {
            index === 3 ? onClose() : setIndex((prev) => prev + 1);
          }}
          color="purple"
        >
          {t("next")}
        </Button>
      </section>
    </article>
  );
}
