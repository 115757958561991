import React from "react";

export default function Upload({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20Z"
        fill="#F2F4F7"
      />
      <g clipPath="url(#clip0_1614_93761)">
        <path
          d="M23.3334 23.3332L20 19.9999M20 19.9999L16.6667 23.3332M20 19.9999V27.4999M26.9917 25.3249C27.8045 24.8818 28.4466 24.1806 28.8166 23.3321C29.1866 22.4835 29.2635 21.5359 29.0352 20.6388C28.8069 19.7417 28.2863 18.9462 27.5556 18.3778C26.8249 17.8094 25.9258 17.5005 25 17.4999H23.95C23.6978 16.5243 23.2277 15.6185 22.575 14.8507C21.9223 14.0829 21.1041 13.4731 20.1818 13.0671C19.2595 12.661 18.2572 12.4694 17.2501 12.5065C16.2431 12.5436 15.2576 12.8085 14.3677 13.2813C13.4778 13.7541 12.7066 14.4225 12.1122 15.2362C11.5178 16.05 11.1156 16.9879 10.9358 17.9794C10.7561 18.9709 10.8034 19.9903 11.0744 20.961C11.3453 21.9316 11.8327 22.8281 12.5 23.5832"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1614_93761">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(10 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
