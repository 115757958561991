import React from "react";

export default function Location({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M10.0001 3.33341C11.6084 3.33341 14.1667 4.50008 14.1667 7.62508C14.1667 9.42508 12.7334 11.5167 10.0001 13.7251C7.26675 11.5167 5.83341 9.41675 5.83341 7.62508C5.83341 4.50008 8.39175 3.33341 10.0001 3.33341ZM10.0001 1.66675C7.27508 1.66675 4.16675 3.71675 4.16675 7.62508C4.16675 10.2251 6.10841 12.9667 10.0001 15.8334C13.8917 12.9667 15.8334 10.2251 15.8334 7.62508C15.8334 3.71675 12.7251 1.66675 10.0001 1.66675Z"
        fill="#667085"
      />
      <path
        d="M10.0001 5.83325C9.08342 5.83325 8.33342 6.58325 8.33342 7.49992C8.33342 8.41659 9.08342 9.16659 10.0001 9.16659C10.4421 9.16659 10.866 8.99099 11.1786 8.67843C11.4912 8.36587 11.6667 7.94195 11.6667 7.49992C11.6667 7.05789 11.4912 6.63397 11.1786 6.32141C10.866 6.00885 10.4421 5.83325 10.0001 5.83325V5.83325ZM4.16675 16.6666H15.8334V18.3333H4.16675V16.6666Z"
        fill="#667085"
      />
    </svg>
  );
}
