import React from "react";

export default function LogoIconFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#475467"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 34C24.5 34 32 25.0096 32 20.381C32 15.7523 28.4183 12 24 12C19.5817 12 16 15.7523 16 20.381C16 25.0096 23.5 34 24 34ZM21.8176 23.8589C21.9345 23.953 22.087 24 22.2751 24C22.4682 24 22.6207 23.953 22.7325 23.8589C22.8443 23.7593 22.9358 23.61 23.007 23.4108L24.0057 20.4066L24.9892 23.4025C25.0552 23.6072 25.1467 23.7593 25.2636 23.8589C25.3805 23.953 25.533 24 25.7211 24C25.9091 24 26.0591 23.953 26.1709 23.8589C26.2878 23.7593 26.3793 23.61 26.4453 23.4108L27.9473 18.8963C28.0286 18.6473 28.0159 18.4371 27.9091 18.2656C27.8024 18.0885 27.6321 18 27.3983 18C27.2103 18 27.0629 18.0498 26.9561 18.1494C26.8545 18.2434 26.7732 18.3928 26.7122 18.5975L25.6982 21.8174L24.7071 18.6058C24.641 18.3956 24.5571 18.2434 24.4555 18.1494C24.3538 18.0498 24.2192 18 24.0514 18C23.8786 18 23.7388 18.0498 23.6321 18.1494C23.5305 18.2434 23.4441 18.3928 23.3729 18.5975L22.336 21.751L21.3754 18.5975C21.3144 18.3928 21.2306 18.2434 21.1238 18.1494C21.0222 18.0498 20.8748 18 20.6817 18C20.4021 18 20.2064 18.0885 20.0946 18.2656C19.9828 18.4371 19.9701 18.6473 20.0565 18.8963L21.5432 23.4025C21.6092 23.6072 21.7007 23.7593 21.8176 23.8589Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4065 32.1179C18.3293 32.4902 16.9339 33.1811 16.9339 33.9719C16.9339 35.1571 20.0679 36.1179 23.9339 36.1179C27.7999 36.1179 30.9339 35.1571 30.9339 33.9719C30.9339 33.1811 29.5385 32.4902 27.4613 32.1179C27.192 32.4463 26.9246 32.7532 26.6623 33.0343C27.3975 33.1354 28.0513 33.2751 28.5939 33.4415C29.1688 33.6177 29.5633 33.8072 29.7933 33.9719C29.5633 34.1367 29.1688 34.3262 28.5939 34.5024C27.4449 34.8547 25.797 35.0875 23.9339 35.0875C22.0708 35.0875 20.4229 34.8547 19.2739 34.5024C18.699 34.3262 18.3045 34.1367 18.0745 33.9719C18.3045 33.8072 18.699 33.6177 19.2739 33.4415C19.8165 33.2751 20.4703 33.1354 21.2055 33.0343C20.9432 32.7532 20.6758 32.4463 20.4065 32.1179ZM29.9587 33.8238C29.9587 33.8238 29.9575 33.8265 29.9534 33.8319C29.9564 33.8264 29.9587 33.8238 29.9587 33.8238ZM17.9091 33.8238C17.9091 33.8238 17.9115 33.8265 17.9144 33.8319C17.9103 33.8266 17.9091 33.8238 17.9091 33.8238ZM17.9091 34.1201C17.9091 34.1201 17.9103 34.1173 17.9144 34.112C17.9115 34.1174 17.9091 34.1201 17.9091 34.1201ZM29.9534 34.112C29.9575 34.1174 29.9587 34.1201 29.9587 34.1201C29.9587 34.1201 29.9564 34.1174 29.9534 34.112Z"
        fill="white"
      />
      <path
        d="M32 20.3271C32 24.9261 24.4857 34 24 34C24 34 24.0381 24.9261 24.0381 20.3271C24.0381 15.7282 23.9339 16.6146 23.9339 12C28.2262 12 32 15.7282 32 20.3271Z"
        fill="#1D2939"
        fillOpacity="0.1"
      />
    </svg>
  );
}
