import React from "react";
import css from "./Spinner.module.css";

type SpinnerProps = {
  margin?: string;
  className?: string;
};

const Spinner = ({ margin, className }: SpinnerProps): JSX.Element => (
  <span className={className}>
    <div style={{ margin: `${margin}` }} className={css.spinner} />
  </span>
);

export default Spinner;
