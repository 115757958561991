import React, { useEffect } from "react";
import { useModal } from "Components/UI/Modal";
import Icons from "Components/Icons";
import UI from "Components/UI";
import { TipModel, TipStatusEnum } from "Models/TipModel";
import { useDispatch, useSelector } from "react-redux";
import CategorySelectors from "Store/Selectors/CategorySelectors";
import TipActions from "Store/Actions/TipActions";
import globalCss from "Global.module.css";
import { NotificationContext } from "App";
import TipListActions from "Store/Actions/TipListActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import Hooks from "Hooks";
import { Data } from "./Types";
import TipLists from "./TipLists";
import css from "./TipDialog.module.css";
import { useRouter } from "next/dist/client/router";
import ImageDrop from "../../Components/ImageDrop";
import MiscUtils from "Utils/MiscUtils";
import { TipListModel } from "Models/TipListModel";
import Geometry from "Components/Icons/Geometry";
import Title from "Components/Icons/Title";
import { useTranslation } from "react-i18next";

type TipDialogProps = {
  onClose: () => void;
  tip?: TipModel;
  source?: string;
  list: TipListModel | undefined;
};

export default function TipDialog({
  tip,
  onClose,
  source = "",
  list,
}: TipDialogProps): JSX.Element {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    { ...tip, tipLists: [] },
    (nextData) => ({
      title:
        (nextData.title ?? "").trim().length === 0
          ? (t("enter-title") as string)
          : undefined,
      description:
        (nextData.description ?? "").trim().length === 0
          ? (t("enter-description") as string)
          : undefined,
      venue: !nextData.venue ? (t("select-venue") as string) : undefined,
      imageData: !(nextData.imageData || nextData.imageId)
        ? (t("select-image") as string)
        : undefined,
      categoryId:
        (nextData.categoryId ?? "").trim().length === 0
          ? (t("select-category") as string)
          : undefined,
      tipLists:
        (nextData.tipLists ?? "").length === 0
          ? (t("add-tip") as string)
          : undefined,
    })
  );
  const categories = useSelector(CategorySelectors.all);
  const [pending, setPending] = React.useState<"draft" | "publish">();
  const [ownImage, setOwnImage] = React.useState<boolean>(false);
  const showNotification = React.useContext(NotificationContext);
  const auth = useSelector(AuthSelectors.get);
  const canSave = valid(data, false);
  Hooks.usePreventReloadingAndClosingBrowser();

  useEffect(() => {
    if (source !== "") {
      change({
        imageData: source,
      });
      setOwnImage(true);
    } else {
      setOwnImage(false);
    }
  }, [change, source]);

  // Save
  const submit = React.useCallback(
    async (nextData: Data, status: TipStatusEnum, nextTipId?: string) => {
      setPending(status === TipStatusEnum.DRAFT ? "draft" : "publish");

      try {
        if (status === TipStatusEnum.PUBLISHED && !valid()) {
          return;
        }

        let nextImageData: string | null | undefined = nextData.imageData;

        if (!nextImageData) {
          // Set to null if the image has been deleted.
          nextImageData = nextData.imageId ? undefined : null;
        }

        let nextTip: TipModel | null;

        if (!nextTipId) {
          nextTip = await TipActions.create(
            dispatch,
            nextData.title ?? null,
            nextData.description ?? null,
            nextData.venue?.id ?? null,
            nextData.imageData ?? null,
            nextData.imageAttribution ?? null,
            nextData.categoryId ?? null,
            nextData.tipLists?.map((tipList) => tipList.id) ?? null,
            status
          );
        } else {
          nextTip = await TipActions.update(
            dispatch,
            nextTipId,
            nextData.title ?? null,
            nextData.description ?? null,
            nextData.venue?.id ?? null,
            nextImageData,
            nextData.imageAttribution ?? null,
            nextData.categoryId ?? null,
            nextData.tipLists?.map((tipList) => tipList.id) ?? null,
            status
          );
        }

        let nextTipList: TipListModel | null = null;

        // Redirect if the tip is new.
        if (!nextTipId && nextTip) {
          const [tipList] = await TipListActions.list(dispatch, {
            limit: 1,
            filters: {
              tipId: nextTip?.id,
              userIds: auth?.id ? [auth.id] : undefined,
            },
            sort: { followerCount: true },
          });

          if (tipList[0].imageId == undefined) {
            nextTipList = await TipListActions.update(
              dispatch,
              tipList[0].id,
              tipList[0].title ?? null,
              tipList[0].description ?? "",
              tipList[0].city?.id ?? null,
              nextImageData ?? undefined
            );
          }

          if (tipList.length === 1) {
            router.push({
              pathname: `/${tipList[0].user.slug}/list/${tipList[0].slug}`,
              query: { tipId: nextTip.slug },
            });
          }
        }

        showNotification({
          title: `${t("your-tip-has-been")} ${
            status === TipStatusEnum.PUBLISHED
              ? t("published")
              : t("saved-as-draft")
          }`,
          timeoutMs: 4000,
          type: status === TipStatusEnum.PUBLISHED ? "neutral" : "alert",
        });
        onClose();
      } catch (err) {
        //
      } finally {
        setPending(undefined);
      }
    },
    [dispatch, showNotification, onClose, valid, auth?.id, router]
  );

  // Cancel.
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message={t("discard-changes")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        onClose();
      }}
    />
  );

  const previousData = Hooks.usePreviousState(data);

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (
      previousData === undefined ||
      JSON.stringify(previousData) === JSON.stringify(data)
    ) {
      onClose();
      return;
    }
    showConfirmDialog();
  }, [data, onClose, previousData, showConfirmDialog]);

  // Remove tip
  const [deleteDialog, showDeleteDialog, hideDeleteDialog] = useModal(
    <UI.ConfirmDialog
      message={t("discard-tip")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideDeleteDialog()}
      onConfirm={async () => {
        if (!tip) {
          return;
        }

        TipActions.remove(dispatch, tip);

        showNotification({
          title: t("tip-deleted"),
          type: "error",
        });

        onClose();
        hideDeleteDialog();
      }}
    />
  );

  const title = data.title ?? "";
  const description = data.description ?? "";
  const categoryId = data.categoryId ?? "";

  if (list != undefined && data.tipLists?.length == 0) {
    data.tipLists = [list];
  }

  return (
    <div className={css.Container}>
      {confirmDialog}
      {deleteDialog}

      <div className={[globalCss.Content, css.Content].join(" ")}>
        <h2 className={css.Title}>
          <span>
            {`${!tip ? t("create-new") : t("edit")} ${t("tip")}`}
            {data.status === TipStatusEnum.DRAFT && (
              <span style={{ color: "var(--color-orange0" }}>
                &nbsp;(draft)
              </span>
            )}
          </span>

          <UI.Button
            variant="text"
            onClick={cancel}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        </h2>

        <div className={css.Fields}>
          <div className={css.Col50}>
            <div>
              <UI.LocationSearch.Venue
                value={data.venue ?? undefined}
                onChange={async (nextValue) => {
                  change({
                    venue: nextValue ?? null,
                    imageAttribution:
                      !ownImage && nextValue?.imageAttribution
                        ? nextValue?.imageAttribution
                        : null,
                    imageData:
                      data.imageData != undefined && ownImage
                        ? data.imageData
                        : (nextValue?.imageId &&
                            (await MiscUtils.imageToBase64(
                              await MiscUtils.srcToFile(
                                MiscUtils.getImageModel(
                                  nextValue?.imageId || null
                                ).large || "",
                                "filename",
                                "image/png"
                              )
                            ))) ??
                          undefined,
                  });
                }}
              />
              <UI.ValidationError>{errors.venue}</UI.ValidationError>
            </div>

            <div>
              <UI.SelectField
                value={categoryId}
                onChange={(e) => change({ categoryId: e.target.value })}
                label={t("category")}
                icon={<Geometry />}
              >
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>
                    {category.title}
                  </option>
                ))}
              </UI.SelectField>
              <UI.ValidationError>{errors.categoryId}</UI.ValidationError>
            </div>
          </div>

          <div>
            <UI.TextField
              label={t("title")}
              icon={<Title />}
              className={css.Input}
              value={title}
              onChange={(e) => change({ title: e.target.value })}
              maxLength={60}
            />
            <span className={css.CharCounter}>{`${title.length}/60`}</span>
            <UI.ValidationError>{errors.title}</UI.ValidationError>
          </div>

          <div>
            <TipLists
              change={change}
              data={data}
              userId={auth?.id}
              tipId={tip?.id}
            />
            <UI.ValidationError>{errors.tipLists}</UI.ValidationError>
          </div>

          <div>
            <UI.MultiLineField
              label={t("description")}
              className={css.Input}
              value={description}
              onChange={(e) => change({ description: e.target.value })}
              maxLength={400}
              style={{ minHeight: 150 }}
            />
            <span
              className={css.CharCounter}
            >{`${description.length}/400`}</span>
            <UI.ValidationError>{errors.description}</UI.ValidationError>
          </div>

          <ImageDrop
            label={t("photo")}
            data={data}
            errors={errors}
            onChange={change}
            altText={t("tip-cover")}
            setOwnImage={setOwnImage}
          />

          <div className={css.Actions}>
            <UI.Button onClick={cancel} color="secondary" variant="outline">
              {t("cancel")}
            </UI.Button>

            {tip && (
              <UI.Button color="fourth" onClick={showDeleteDialog}>
                {t("delete")}
              </UI.Button>
            )}

            <UI.Button
              className={css.ButtonRight}
              onClick={() => {
                hideConfirmDialog();
                submit(data, TipStatusEnum.DRAFT, tip?.id);
                onClose();
              }}
              color="fifth"
            >
              {pending === "draft" ? t("saving-draft") : t("save-draft")}
            </UI.Button>

            <UI.Button
              className={!canSave ? css.Disabled : ""}
              onClick={() => {
                if (pending) {
                  return;
                }

                submit(data, TipStatusEnum.PUBLISHED, tip?.id);
              }}
            >
              {!tip &&
                (pending === "publish" ? t("creating") : t("create-tip"))}
              {tip &&
                tip.status === TipStatusEnum.PUBLISHED &&
                (pending === "publish" ? t("saving") : t("save-edit"))}
              {tip &&
                tip.status === TipStatusEnum.DRAFT &&
                (pending === "publish" ? t("publishing") : t("publish"))}
            </UI.Button>
          </div>
        </div>
      </div>
    </div>
  );
}
