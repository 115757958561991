import { NotificationContext } from "App";
import Upload from "Components/Icons/Upload";
import UI from "Components/UI";
import { TipModel } from "Models/TipModel";
import MiscUtils from "Utils/MiscUtils";
import React from "react";
import { useTranslation } from "react-i18next";
import css from "./ImageDrop.module.css";

type Data = Partial<
  Pick<TipModel, "image" | "imageId"> & {
    imageData: string;
    imageAttribution: string | null;
  }
>;

type ImageProps = {
  className?: string;
  data: Data;
  errors: Partial<{ [key in keyof Data]: string }>;
  onChange: (data: Data) => void;
  altText: string;
  setChangeImg?: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  setOwnImage?: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Image({
  className,
  data,
  errors,
  onChange,
  altText,
  setChangeImg,
  label,
  setOwnImage,
}: ImageProps): JSX.Element {
  const { t } = useTranslation();
  const imageFileRef = React.useRef<HTMLInputElement>(null);
  const showNotification = React.useContext(NotificationContext);

  const setImage = React.useCallback(
    async (file: File) => {
      try {
        if (file.size >= 5242880) {
          throw "FileTooBigException";
        }

        if (setOwnImage) {
          setOwnImage(true);
        }
        onChange({
          imageData: await MiscUtils.imageToBase64(file),
          imageAttribution: null,
        });
      } catch (err) {
        if (err == "FileTooBigException") {
          showNotification({
            title: t("invalid-image"),
            message: t("file-too-big"),
            type: "error",
          });
        } else {
          showNotification({ title: t("invalid-image"), type: "error" });
        }
      }
    },
    [onChange, showNotification, setOwnImage]
  );

  const browseImages = () => {
    if (imageFileRef.current) {
      imageFileRef.current.click();
    }
  };

  const imageData = data.imageData ?? data.image?.medium ?? undefined;

  return (
    <div className={className}>
      <div className={css.Image}>
        {label && <span className={css.Label}>{label}</span>}
        {data.imageData || data.imageId ? (
          <div className={css.Preview}>
            <div className={css.PreviewItems}>
              <div className={css.PreviewImage}>
                <img src={imageData} alt={altText} />
              </div>

              <div className={css.PreviewDropArea}>
                <div onClick={browseImages} style={{ cursor: "pointer" }}>
                  <UI.DropArea
                    onDrop={(e) => setImage(e.dataTransfer.files[0])}
                    className={[
                      css.DropArea,
                      data.imageData && css.HasImage,
                    ].join(" ")}
                    overlay={<div className={css.Overlay} />}
                  >
                    <Upload />
                    <div>
                      <UI.Button variant="text" color="purple">
                        {t("or-upload-image")}
                      </UI.Button>
                    </div>
                  </UI.DropArea>
                </div>
              </div>
            </div>

            <UI.Button
              variant="text"
              onClick={() => {
                if (setOwnImage) {
                  setOwnImage(false);
                }
                onChange({
                  imageData: undefined,
                  imageId: null,
                  image: undefined,
                });
                setChangeImg && setChangeImg(true);
              }}
              className={css.RemoveButton}
            >
              {t("remove-image")}
            </UI.Button>
          </div>
        ) : (
          <div onClick={browseImages} style={{ cursor: "pointer" }}>
            <UI.DropArea
              onDrop={(e) => setImage(e.dataTransfer.files[0])}
              className={css.DropArea}
              overlay={<div className={css.Overlay} />}
            >
              <Upload />
              <div>
                <UI.Button variant="text" color="purple">
                  {t("click-here-upload")}
                </UI.Button>
                {t("or-drag-drop")}
              </div>
            </UI.DropArea>
          </div>
        )}
      </div>
      <UI.ValidationError className={css.Error}>
        {errors.imageData}
      </UI.ValidationError>

      <input
        ref={imageFileRef}
        type="file"
        className={css.ImageFile}
        onChange={(e) => {
          if (e.target.files) {
            setImage(e.target.files[0]);
          }

          e.target.value = "";
        }}
      />
    </div>
  );
}
