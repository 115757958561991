import React from "react";

import css from "./DropArea.module.css";

type DropAreaProps = React.PropsWithChildren<{
  className?: string;
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void;
  overlay?: JSX.Element;
}>;

export default function DropArea({
  className,
  children,
  overlay,
  onDrop,
}: DropAreaProps): JSX.Element {
  const [dragging, setDragging] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  React.useEffect(() => {
    let counter = 0;

    const preventDefault = (e: DragEvent) => {
      e.preventDefault();
    };

    const dragStart = () => {
      counter += 1;
      setDragging(true);
    };

    const dragEnd = () => {
      counter -= 1;

      if (counter === 0) {
        setDragging(false);
        setHovered(false);
      }
    };

    window.addEventListener("dragover", preventDefault);
    window.addEventListener("drop", preventDefault);
    window.addEventListener("dragenter", dragStart);
    window.addEventListener("drop", dragEnd);
    window.addEventListener("dragleave", dragEnd);

    return () => {
      window.removeEventListener("dragover", preventDefault);
      window.removeEventListener("drop", preventDefault);
      window.removeEventListener("dragenter", dragStart);
      window.removeEventListener("drop", dragEnd);
      window.removeEventListener("dragleave", dragEnd);
    };
  }, []);

  const dragOver = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    setHovered(true);
  }, []);

  const drop = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();

      if (onDrop) {
        onDrop(e);
      }
    },
    [onDrop]
  );

  return (
    <section
      className={[css.Container, className ?? ""].join(" ")}
      onDragOver={dragOver}
      onDragLeave={() => setHovered(false)}
      onDrop={drop}
    >
      {children}

      {overlay && dragging && (
        <div className={[css.Overlay, hovered ? css.Hovered : ""].join(" ")}>
          {overlay}
        </div>
      )}
    </section>
  );
}
