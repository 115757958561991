import React from "react";

export default function ListAdd({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.6667 8.33333H1.66666V10H11.6667V8.33333ZM11.6667 5H1.66666V6.66667H11.6667V5ZM15 11.6667V8.33333H13.3333V11.6667H9.99999V13.3333H13.3333V16.6667H15V13.3333H18.3333V11.6667H15ZM1.66666 13.3333H8.33332V11.6667H1.66666V13.3333Z"
        fill="#667085"
      />
    </svg>
  );
}
