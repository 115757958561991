import {
  ModalControllerContext,
  NotificationContext,
  UserLocationContext,
} from "App";
import Icons from "Components/Icons";
import Details from "Components/Icons/Details";
import Map from "Components/Icons/Map";
import Star from "Components/Icons/Star";
import UI from "Components/UI";
import DOMPurify from "dompurify";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import Link from "next/link";
import React from "react";
import { useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import MiscUtils from "Utils/MiscUtils";
import commonCss from "./Common.module.css";
import css from "./Tip.module.css";
import { useTranslation } from "react-i18next";

type TipProps = {
  value: TipModel;
  className?: string;
  size?: "small" | "medium";
  onRemoveTipFromTipList?: (tip: TipModel) => void;
  onClickMap?: () => void;
  tipList: TipListModel;
  mapDetails?: boolean;
  bookingLink?: string;
};

export default function Tip({
  tipList,
  value,
  className,
  size = "medium",
  onRemoveTipFromTipList,
  onClickMap,
  mapDetails = false,
  bookingLink,
}: TipProps): JSX.Element {
  const { t } = useTranslation();
  const {
    tipDialog,
    handleTipsDialog,
    signInDialog,
    reportTipDialog,
    bookDialog,
  } = React.useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);
  const userLocation = React.useContext(UserLocationContext);
  const distance = MiscUtils.getDistance(
    { lat: value.venue?.latitude, lng: value.venue?.longitude },
    userLocation ?? { lat: undefined, lng: undefined }
  )?.stringDistance;
  const listOwner = tipList.user;
  const isTipOwner = auth?.id === value.user.id;
  const isListOwner = auth?.id === listOwner.id;
  const isBorrowed = listOwner.id !== value.user.id;
  const anchorRef = React.useRef<HTMLSpanElement>(null);
  const showNotification = React.useContext(NotificationContext);

  return (
    <div
      className={[
        commonCss.Container,
        className ?? "",
        { small: commonCss.Small, medium: commonCss.Medium }[size],
      ].join(" ")}
    >
      <div className={css.Content}>
        <div className={commonCss.Header}>
          {isTipOwner && value.status === 0 && (
            <span className={[css.Status, css.Draft].join(" ")}>
              {t("draft")}
            </span>
          )}

          <span ref={anchorRef}>
            <UI.ContextMenu
              className={commonCss.Options}
              button={
                !mapDetails && (
                  <UI.Button variant="text">
                    <Icons.Menu />
                  </UI.Button>
                )
              }
            >
              <div className={commonCss.Button}>
                <h4 className={commonCss.ModalTitle}>{t("edit-tip")}</h4>
                {isListOwner && onRemoveTipFromTipList && (
                  <UI.Button
                    variant="text"
                    className={commonCss.RemoveButton}
                    onClick={() => {
                      if (onRemoveTipFromTipList) {
                        onRemoveTipFromTipList(value);
                      }
                    }}
                  >
                    {isTipOwner && (
                      <>
                        <Icons.Remove />
                        <span>{t("remove-from-list")}</span>
                      </>
                    )}
                    {isBorrowed && (
                      <>
                        <Icons.Star />
                        <span>{t("unborrow-from-list")}</span>
                      </>
                    )}
                  </UI.Button>
                )}
                {isTipOwner && (
                  <UI.Button variant="text" onClick={() => tipDialog(value)}>
                    <Icons.Edit />
                    <span>{t("edit-tip")}</span>
                  </UI.Button>
                )}
                {isListOwner && (
                  <UI.Button
                    variant="text"
                    onClick={() =>
                      handleTipsDialog(value, false, true, tipList.id)
                    }
                  >
                    <Icons.Move />
                    <span>{t("move-tip-to-another-list")}</span>
                  </UI.Button>
                )}
                {isListOwner && (
                  <UI.Button
                    variant="text"
                    onClick={() => handleTipsDialog(value)}
                  >
                    <Icons.AddToTipList />
                    <span>{t("save-tip-additional-list")}</span>
                  </UI.Button>
                )}
                {!mapDetails && !isTipOwner && (
                  <UI.Button
                    variant="text"
                    onClick={() => reportTipDialog(value)}
                  >
                    <Icons.Report />
                    <span>{t("report-tip")}</span>
                  </UI.Button>
                )}
                <UI.Button
                  variant="text"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.host}/${listOwner.slug}/list/${tipList.slug}?tipId=${value.slug}`
                    );
                    showNotification({
                      title: "Success",
                      message: (
                        <>
                          <div>
                            {t("a-link-to")}
                            <strong
                              className={css.Bold}
                            >{` "${value.title}" `}</strong>
                            {t("has-been-copied-to-your-clipboard")}
                          </div>
                        </>
                      ),
                      type: "neutral",
                    });
                  }}
                >
                  <Icons.Copy />
                  <span>{t("copy-link-tip")}</span>
                </UI.Button>
              </div>
            </UI.ContextMenu>
          </span>
        </div>
        <div className={[css.Rubric, css.Phone].join(" ")}>
          <span>
            {value.category?.icon !== undefined && (
              <Icons.Category
                height={40}
                width={40}
                icon={value.category.icon}
                circle
              />
            )}
          </span>
          <div>
            <h2 className={css.Title}>{value.title}</h2>
            <h3 className={css.Location}>{value.venue?.name}</h3>
          </div>
        </div>
        <div className={css.Photo}>
          {value.imageId != null && (
            <img
              src={value.image.medium ?? value.venue?.image.medium}
              alt=""
              className={commonCss.Image}
            />
          )}
          {value.imageAttribution && (
            <div className={css.Attribution}>
              <p>{t("photo:")}</p>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(value.imageAttribution),
                }}
              />
            </div>
          )}
        </div>

        <div className={css.TipInformation}>
          <div className={[css.Rubric, css.Desktop].join(" ")}>
            {value.category?.icon !== undefined && (
              <Icons.Category
                height={40}
                width={40}
                icon={value.category.icon}
                circle
              />
            )}
            <div>
              <h2 className={css.Title}>{value.title}</h2>
              <h3 className={css.Location}>{value.venue?.name}</h3>
            </div>
          </div>

          {isBorrowed && (
            <Link href={`/${value.user.slug}`}>
              <a className={[css.User, css.Phone].join(" ")}>
                <div>
                  {t("by")}
                  {[value.user.firstName, value.user.lastName].join(" ")}
                </div>
              </a>
            </Link>
          )}
          <p className={css.Description}>{value.description}</p>

          <div className={css.DetailButtons}>
            {isBorrowed && (
              <Link href={`/${value.user.slug}`}>
                <a className={[css.User, css.Desktop].join(" ")}>
                  <div>
                    {t("by")}
                    {[value.user.firstName, value.user.lastName].join(" ")}
                  </div>
                </a>
              </Link>
            )}

            {!mapDetails && (
              <UI.Button
                variant="text"
                onClick={onClickMap}
                className={css.MoreInfo}
              >
                <Map className={css.Icon} />
                <span>{t("map")}</span>
              </UI.Button>
            )}

            {!mapDetails && (
              <UI.ContextMenu
                button={
                  <UI.Button variant="text" className={css.MoreInfo}>
                    <Details
                      className={[css.Icon, css.DetailsIcon].join(" ")}
                    />
                    <span>{t("details")}</span>
                  </UI.Button>
                }
              >
                <ul className={css.VenueDetails}>
                  {value.venue?.address && (
                    <li>
                      <Icons.Location className={css.Icon} />
                      <p>{`${distance ? `${distance} · ` : ""}${
                        value.venue.address
                      }`}</p>
                    </li>
                  )}

                  {typeof value.venue?.priceLevel === "number" && (
                    <li>
                      <Icons.Money className={css.Icon} />
                      <p>
                        {[...Array(value.venue.priceLevel ?? 0)].map(() => "$")}
                      </p>
                    </li>
                  )}

                  {value.venue?.phone && (
                    <li>
                      <Icons.Phone className={css.Icon} />
                      <p>{value.venue.phone}</p>
                    </li>
                  )}

                  {value.venue?.website && (
                    <li>
                      <Icons.Web className={css.Icon} />
                      <p>
                        <a
                          href={value.venue.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {t("website")}
                        </a>
                      </p>
                    </li>
                  )}
                </ul>
              </UI.ContextMenu>
            )}
            <UI.Button variant="text" className={css.MoreInfo}>
              <Star className={css.Icon} />
              <span style={{ display: mapDetails ? "contents" : "initial" }}>
                {value.borrowerCount}
              </span>
            </UI.Button>
          </div>

          <div className={css.Borrow}>
            <UI.Button
              variant="outline"
              color="purple"
              onClick={(e) => {
                if (!auth) {
                  e.stopPropagation();
                  e.preventDefault();

                  signInDialog();
                  return;
                }

                handleTipsDialog(value, true);
              }}
            >
              {t("borrow-tip")}
            </UI.Button>
            {/* 
            Temporary fix to hide the book now button.
            {bookingLink && (
              <UI.Button
                variant="fill"
                color="purple"
                onClick={(e) => bookDialog(bookingLink)}
              >
                {t("book-now")}
              </UI.Button>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
