import React from "react";

export default function GeometryFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.333374 24C0.333374 10.7452 11.0785 0 24.3334 0C37.5882 0 48.3334 10.7452 48.3334 24C48.3334 37.2548 37.5882 48 24.3334 48C11.0785 48 0.333374 37.2548 0.333374 24Z"
        fill="#475467"
      />
      <path
        d="M24.3334 14L18.8334 23H29.8334L24.3334 14ZM24.3334 17.84L26.2634 21H22.3934L24.3334 17.84ZM29.8334 25C27.3434 25 25.3334 27.01 25.3334 29.5C25.3334 31.99 27.3434 34 29.8334 34C32.3234 34 34.3334 31.99 34.3334 29.5C34.3334 27.01 32.3234 25 29.8334 25ZM29.8334 32C28.4534 32 27.3334 30.88 27.3334 29.5C27.3334 28.12 28.4534 27 29.8334 27C31.2134 27 32.3334 28.12 32.3334 29.5C32.3334 30.88 31.2134 32 29.8334 32ZM15.3334 33.5H23.3334V25.5H15.3334V33.5ZM17.3334 27.5H21.3334V31.5H17.3334V27.5Z"
        fill="white"
      />
    </svg>
  );
}
