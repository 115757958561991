import React from "react";

export default function Details({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.7149 15V11M11.7149 7H11.7249M21.7149 11C21.7149 16.5228 17.2378 21 11.7149 21C6.19209 21 1.71494 16.5228 1.71494 11C1.71494 5.47715 6.19209 1 11.7149 1C17.2378 1 21.7149 5.47715 21.7149 11Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
