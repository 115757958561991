import React from "react";

export default function LogoOutlined({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="177"
      height="178"
      viewBox="0 0 177 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.444 90.8939C136.533 79.9603 139.416 70.4131 139.416 63.3571C139.416 32.178 115.942 7.77275 88.0225 7.77275C60.1029 7.77275 36.6286 32.178 36.6286 63.3571C36.6286 70.4131 39.5125 79.9603 44.6006 90.8939C49.6027 101.643 56.3765 112.986 63.3553 123.397C70.323 133.792 77.3964 143.111 82.9153 149.789C84.8651 152.149 86.5895 154.141 88.0225 155.714C89.4555 154.141 91.18 152.149 93.1298 149.789C98.6486 143.111 105.722 133.792 112.69 123.397C119.669 112.986 126.442 101.643 131.444 90.8939ZM88.0225 165.5C91.6892 165.5 146.689 98.0722 146.689 63.3571C146.689 28.6421 120.423 0.5 88.0225 0.5C55.6218 0.5 29.3558 28.6421 29.3558 63.3571C29.3558 98.0722 84.3559 165.5 88.0225 165.5Z"
        fill="#475467"
      />
      <path
        d="M75.373 89.0456C73.9939 89.0456 72.8757 88.6965 72.0184 87.9982C71.1612 87.2588 70.4903 86.1293 70.0057 84.6095L59.1035 51.1541C58.4699 49.3058 58.5631 47.7449 59.3831 46.4716C60.203 45.1572 61.638 44.5 63.688 44.5C65.1044 44.5 66.1853 44.8697 66.9307 45.609C67.7135 46.3073 68.3285 47.4163 68.7757 48.9361L75.8202 72.3487L83.4238 48.9361C83.9456 47.4163 84.5793 46.3073 85.3247 45.609C86.1074 44.8697 87.1324 44.5 88.3997 44.5C89.6297 44.5 90.6174 44.8697 91.3629 45.609C92.1083 46.3073 92.7233 47.4368 93.2078 48.9977L100.476 72.8416L107.912 48.9361C108.359 47.4163 108.955 46.3073 109.701 45.609C110.484 44.8697 111.565 44.5 112.944 44.5C114.658 44.5 115.907 45.1572 116.69 46.4716C117.472 47.7449 117.565 49.3058 116.969 51.1541L105.955 84.6711C105.471 86.1498 104.8 87.2588 103.942 87.9982C103.122 88.6965 102.023 89.0456 100.644 89.0456C99.2646 89.0456 98.1465 88.6965 97.2892 87.9982C96.4319 87.2588 95.761 86.1293 95.2765 84.6095L88.0642 62.3675L80.7402 84.6711C80.2184 86.1498 79.5475 87.2588 78.7275 87.9982C77.9075 88.6965 76.7893 89.0456 75.373 89.0456Z"
        fill="#475467"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.5792 148.032C47.3465 150.761 37.1135 155.828 37.1135 161.628C37.1135 170.319 60.0962 177.365 88.4469 177.365C116.797 177.365 139.78 170.319 139.78 161.628C139.78 155.828 129.547 150.761 114.314 148.031C112.339 150.439 110.378 152.69 108.455 154.751C113.846 155.493 118.641 156.517 122.62 157.737C126.836 159.03 129.729 160.42 131.416 161.628C129.729 162.836 126.836 164.225 122.62 165.518C114.194 168.101 102.11 169.808 88.4469 169.808C74.7839 169.808 62.6998 168.101 54.2735 165.518C50.0572 164.225 47.1645 162.836 45.4779 161.628C47.1645 160.419 50.0572 159.03 54.2735 157.737C58.2525 156.517 63.0471 155.493 68.4384 154.751C66.5151 152.69 64.5542 150.439 62.5792 148.032ZM132.628 160.541C132.628 160.541 132.62 160.561 132.59 160.6C132.612 160.561 132.628 160.541 132.628 160.541ZM44.2653 160.541C44.2653 160.541 44.2823 160.561 44.3037 160.601C44.274 160.561 44.2653 160.541 44.2653 160.541ZM44.2653 162.714C44.2653 162.714 44.274 162.694 44.3037 162.654C44.2823 162.695 44.2653 162.714 44.2653 162.714ZM132.59 162.655C132.62 162.694 132.628 162.714 132.628 162.714C132.628 162.714 132.612 162.695 132.59 162.655Z"
        fill="#475467"
      />
    </svg>
  );
}
