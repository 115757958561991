import Icons from "Components/Icons";
import { TipModel } from "Models/TipModel";
import { useRouter } from "next/dist/client/router";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import css from "./TipSmall.module.css";

const TipSamll = ({
  tip,
  onClose,
  onClick,
  className,
}: {
  tip: TipModel;
  onClose;
  onClick?;
  className?;
}): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div className={[css.Container, className ?? ""].join(" ")}>
      <div style={{ cursor: "pointer" }} onClick={onClick}>
        <img
          style={{
            width: "100px",
            height: "100px",
          }}
          className={css.Image}
          src={tip.image.small}
          alt={""}
        />
      </div>

      <header className={css.Info}>
        <h4 onClick={onClick} className={css.Title}>
          {tip.title}
        </h4>
        <div className={css.Venue}>{tip.venue?.name}</div>
        <div>
          {tip.borrowing && (
            <span
              className={css.TipCreator}
              onClick={() => {
                router.push(`/${tip.user.slug}`);
                onClose();
              }}
            >
              {t("by") + tip.user.firstName + " " + tip.user.lastName}
            </span>
          )}
          <span className={css.BorrowCount}>
            <Icons.Star />
            <span>{tip.borrowerCount}</span>
          </span>
        </div>
      </header>
    </div>
  );
};

export default memo(TipSamll);
