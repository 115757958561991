import React, { useState } from "react";
import Icons from "Components/Icons";
import { TipListModel } from "Models/TipListModel";
import UI from "Components/UI";
import { TipModel } from "Models/TipModel";
import css from "./MapDialog.module.css";
import TipSmall from "Components/Cards/TipSmall";
import Services from "Services";
import getMarkerEncoded from "Components/Icons/Category/MapCategory";
import { useSelector } from "react-redux";
import CategorySelectors from "Store/Selectors/CategorySelectors";
import CategoryFilter from "Components/CategoryFilter";
import { t } from "i18next";

type markerType = {
  marker: google.maps.Marker;
  categoryId: string;
  tipId: string;
};

type MapDialogProps = {
  onClose: () => void;
  tipList: TipListModel;
  tip?: TipModel;
  tips: TipModel[];
  saveCallback?: (tipList: TipListModel) => void;
};

export default function MapDialog({
  tipList,
  tip,
  tips,
  onClose,
}: MapDialogProps): JSX.Element {
  const mapContainerRef = React.useRef<HTMLDivElement>(null);
  const markers = React.useRef<
    { marker: google.maps.Marker; categoryId: string }[]
  >([]);
  const [selectedTip, setSlectedTip] = useState(tip);
  const [map, setMap] = React.useState<google.maps.Map>();
  const allTipCategories = useSelector(CategorySelectors.all);
  const [checkedCategory, setCheckedCategory] = React.useState<string | null>(
    null
  );

  // Get available categories from tips.
  const tipCategories = React.useMemo(
    () =>
      allTipCategories.filter((item) =>
        tips.map((tip) => tip.categoryId).includes(item.id)
      ),
    [tips, allTipCategories]
  );

  const cityLat = tipList?.city.latitude;
  const cityLng = tipList?.city.longitude;

  React.useEffect(() => {
    (async () => {
      if (
        !mapContainerRef.current ||
        typeof cityLat !== "number" ||
        typeof cityLng !== "number"
      ) {
        return;
      }

      setMap(
        await Services.GoogleMaps.getMap(mapContainerRef.current, {
          lat: cityLat,
          lng: cityLng,
        })
      );
    })();
  }, [cityLat, cityLng]);

  React.useEffect(() => {
    if (!map) {
      return;
    }

    const latLngs: { lat: number; lng: number }[] = [];
    const oldMarkers = markers.current as markerType[];
    // Set new markers.
    markers.current = tips
      .map((tip) => {
        const { longitude: lng, latitude: lat } = tip.venue ?? {};

        if (typeof lng !== "number" || typeof lat !== "number") {
          return undefined;
        }

        latLngs.push({ lat, lng });

        const marker = new google.maps.Marker({
          map,
          position: { lat, lng },
          icon: getMarkerEncoded(
            tip.category?.icon ?? 4,
            selectedTip?.id === tip.id
          ),
          title: tip.venue?.name,
        });

        marker.addListener("click", () => {
          oldMarkers.forEach((marker) => {
            if (marker.tipId === tip?.id) {
              marker.marker.setMap(null);
            }
          });
          setSlectedTip(tip);
        });

        return {
          marker,
          categoryId: tip.categoryId,
          tipId: tip.id,
        };
      })
      .filter((marker) => marker !== undefined) as markerType[];

    // Remove old markers:
    setTimeout(() => {
      // Avoid flickering.
      oldMarkers.forEach((marker) => {
        marker.marker.setMap(null);
      });
    }, 50);

    if (latLngs.length === 0) {
      return;
    }

    // Fit map.
    const latLngBounds = new google.maps.LatLngBounds(
      {
        lat: Math.min(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.min(...latLngs.map((latLng) => latLng.lng)),
      },
      {
        lat: Math.max(...latLngs.map((latLng) => latLng.lat)),
        lng: Math.max(...latLngs.map((latLng) => latLng.lng)),
      }
    );

    map.fitBounds(latLngBounds);
  }, [tips, map, tipList.id, selectedTip?.id, selectedTip]);

  const title = tipList.title ?? "";

  return (
    <div className={css.Container}>
      <article className={css.Content}>
        <section className={css.TipsColumn}>
          <header className={css.Header}>
            <div className={css.Title}>
              <UI.Button
                variant="text"
                onClick={onClose}
                className={css.CloseButton}
              >
                <Icons.Close />
              </UI.Button>
              <span>{title}</span>
            </div>
            <div className={css.User}>
              {tipList.user.profileImage.thumb ? (
                <img
                  src={tipList.user.profileImage.thumb}
                  height="40px"
                  width="40px"
                  alt=""
                />
              ) : (
                <Icons.Avatar className={css.Avatar} />
              )}
              <div>
                <div className={css.UserStats}>
                  <Icons.Followers />
                  <span>{tipList.user.followerCount}</span>
                  <Icons.Tip />
                  <span>{tipList.user.borrowerCount}</span>
                </div>
                <div className={css.TipListCreator}>
                  {t("by") +
                    tipList.user.firstName +
                    " " +
                    tipList.user.lastName}
                </div>
              </div>
            </div>
          </header>
          <section className={css.Tips}>
            {tips.map((tip) => (
              <div
                key={tip.id}
                className={
                  selectedTip?.id === tip.id ? css.Selected : undefined
                }
                id={tip.id}
              >
                <TipSmall
                  tip={tip}
                  onClose={onClose}
                  onClick={() => setSlectedTip(tip)}
                />
              </div>
            ))}
          </section>
        </section>

        {/* Category filtering */}
        <div className={css.Filters}>
          <CategoryFilter
            tips={tips}
            callback={(category) => {
              setCheckedCategory(category);
              markers.current.forEach((marker) =>
                marker.marker.setVisible(
                  marker.categoryId === category || category === null
                )
              );
            }}
          />
        </div>

        <aside ref={mapContainerRef} className={css.Map} />
      </article>
    </div>
  );
}
