/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VenueModel } from "Models/VenueModel";
import { tipUpdated } from "./commonActions";

type VenueState = { [id: string]: VenueModel };
const initialState: VenueState = {};

const venueSlice = createSlice({
  name: "venue",
  initialState,
  reducers: {
    venueUpdated: (state, action: PayloadAction<VenueModel[]>) => {
      action.payload.forEach((venue) => {
        state[venue.id] = venue;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tipUpdated, (state, action) => {
      action.payload.forEach((tip) => {
        if (tip.venue) {
          state[tip.venue.id] = tip.venue;
        }
      });
    });
  },
});

export const { venueUpdated } = venueSlice.actions;
export default venueSlice.reducer;
