import App from "App";
import "Common/CSS/common.css";
import "Common/CSS/reset.css";
import { AppProps } from "next/dist/shared/lib/router/router";
import Head from "next/head";
import { Provider } from "react-redux";
import { store } from "../src/Store";
import "../src/i18n";

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>RES Insider</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Provider store={store}>
        <App>
          <Component {...pageProps} />
        </App>
      </Provider>
    </>
  );
}
