/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrganizationModel } from "Models/OrganizationModel";

type OrganizationState = { [id: string]: OrganizationModel };
const initialState: OrganizationState = {};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    organizationUpdated: (
      state,
      action: PayloadAction<OrganizationModel[]>
    ) => {
      action.payload.forEach((organization) => {
        state[organization.id] = organization;
      });
    },
  },
});

export const { organizationUpdated } = organizationSlice.actions;
export default organizationSlice.reducer;
