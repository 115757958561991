import React from "react";

export default function MapPainted({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M18.2149 0L18.0549 0.03L12.7149 2.1L6.71494 0L1.07494 1.9C0.864935 1.97 0.714935 2.15 0.714935 2.38V17.5C0.714935 17.78 0.934935 18 1.21494 18L1.37494 17.97L6.71494 15.9L12.7149 18L18.3549 16.1C18.5649 16.03 18.7149 15.85 18.7149 15.62V0.5C18.7149 0.22 18.4949 0 18.2149 0ZM7.71494 2.47L11.7149 3.87V15.53L7.71494 14.13V2.47ZM2.71494 3.46L5.71494 2.45V14.15L2.71494 15.31V3.46ZM16.7149 14.54L13.7149 15.55V3.86L16.7149 2.7V14.54Z" />
    </svg>
  );
}
