import { PaginationModel } from "Models/PaginationModel";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import ServiceUtils from "./ServiceUtils";
import TipListService from "./TipListService";
import TipService from "./TipService";

async function list(
  userId: string,
  args: {
    limit?: number;
    offset?: number;
  } = {}
): Promise<(TipListModel | TipModel)[]> {
  const params = {
    ...ServiceUtils.getPagination(args.limit, args.offset),
  };

  const {
    data: { data: items },
  } = await ServiceUtils.http.get<PaginationModel<TipListModel | TipModel>>(
    `/users/${userId}/feed`,
    { params }
  );

  return items.map((item) => {
    const tipList = item as TipListModel;
    const tip = item as TipModel;

    if (tipList.cityId) {
      return TipListService.parse(tipList) as TipListModel;
    }

    return TipService.parse(tip) as TipModel;
  });
}

export default {
  list,
};
