import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//import backend from "i18next-http-backend";

/*
 * To use the tranlation in the code:
 * import { useTranslation } from "react-i18next";
 * const { t } = useTranslation();
 * <h1>{t("example-key")}</h1>
 *
 * The "example-key" is the key in the translation file (public/locales/<language>/translation.json)
 * that is used for all languages.
 *
 * There is also a possibliy to use the translations from the locales folder in /public.
 * To do it you un-comment the backend import above and add a .use(backend) to the i18n.use(initReactI18next) chain
 * and also remove the resources in the init. Performance is why this is not used now.
 *
 */

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const resources = {
  sv: {
    translation: {
      enjoy: "Ta del av listor med restips från städer världen över",
      borrow: "Spara tips och följ listor du gillar",
      inspired: "Inspireras av likasinnade resenärer",
      "home-search": "Sök efter stad",
      "home-popular": "Populära resmål",
      "header-another": "Välj en annan destination",
      help: "Hjälp",
      drafts: "Utkast",
      "click-edit": "Klicka för att redigera",
      "untitled-tip": "Namnlöst tips",
      create: "Skapa",
      "create-new": "Skapa ny",
      "new-list": "Ny lista",
      "new-tip": "Nytt tips",
      account: "Konto",
      "my-profile": "Min profil",
      "edit-profile": "Redigera profil",
      "log-out": "Logga ut",
      about: "Om oss",
      "about-res-inside": "Om RES Insider",
      "about-res-inside-text":
        "RES Insider är din goda vän på plats. En interaktiv reseguide med korta, personliga tips – som skapas av resenärer som älskar att dela med sig av personliga upptäckter, och växer med resenärer som letar efter detsamma.",
      "get-started": "Kom igång",
      "get-started-text":
        "Börja med att registrera dig. När det är klart kan du skapa din första lista över en stad eller en plats. Du kan antingen fylla den med dina egna tips som du skapar och lägger till i listan. Eller så kan du använda den unika funktionen på RES Insider som är möjligheten att låna och spara tips från någon annans lista. Du kan lära dig mer om våra funktioner nedan.",
      categories: "Kategorier",
      "categories-short": "Det finns sex olika kategorier av tips. De är:",
      hotels: "Hotell",
      restaurants: "Restauranger",
      "night-life": "Nattliv",
      shopping: "Shopping",
      dos: "Do’s (allmänna tips som inte passar in i någon av kategorierna ovan)",
      donts: "Don’ts (varningar för saker som du tycker bör undvikas)",
      "create-tips": "Skapa tips",
      "tip-short":
        "Ett bra tips är tydligt och konkret. På så vis kan en och samma restaurang ha över tusen tips, men varje tips i sig är relevant och unikt. Saker att tänka på som gör tipsen personliga och inspirerande, är:",
      "specific-dish": "Att berätta om en specifik maträtt du gillade",
      "different-dish":
        "Om någon i personalen var extra trevlig, t.ex. en concierge eller hovmästare",
      "specific-cocktail": "En särskild cocktail du druckit som var supergod",
      "great-view": "En fantastisk utsikt",
      "specific-room": "Ett hotellrum du verkligen gillade",
      "specific-person":
        "En specifik person (bartender eller concierge till exempel)",
      "borrow-tips": "Låna och spara tips",
      "borrow-tips-text":
        "Vi vet att de bästa restipsen alltid kommer från goda vänner. Därför har vi skapat den unika funktionen på RES Insider som innebär att du kan låna och spara andras tips. Det gör du enkelt genom att klicka på ”låna”-knappen i själva tipset och välja den lista du vill lägga till tipset i. Innehållet på RES Insider skapas alltså å ena sidan genom att du skriver dina egna tips. Och å andra sidan, vilket är minst lika viktigt, att du kurerar listor med lånde tips från andra insiders. Ungefär som du skapar spellistor med musik på Spotify som även andra kan spara, i sin helhet eller särskilt utvalda låtar. Smart, va?",
      "follow-lists": "Följ andras listor",
      "follow-lists-text":
        "Om någon har skapat en lista som du gillar i sin helhet kan du också välja att följa hela listan. Det gör du genom att klicka på ”följ”-knappen bredvid listnamnet.",
      "my-universe": "Mitt universum",
      "my-universe-text":
        "RES Insider är användarcentrerad, det handlar helt om dig och alla andra insiders. På så vis har också varje insider ett eget universum. Ditt universum består av alla insiders som du har lånat och sparat tips eller hela listor av – ditt universum är befolkat av just dessa insiders. Hur kommer det sig? Jo, det är förstås för att hjälpa algoritmen att hitta nya, relevanta tips och listor som passar DIN smak. Om du lånat och sparat ett särskilt krogtips i New York, är det troligt att du också gillar den insiderns tips om en restaurang i Lima. På så vis växer ditt universum, och du trillar över nya potentiella upptäckter ute i världen.",
      language: "Språk",
      "choose-language": "Välj språk",
      "sign-in": "Logga in",
      "signing-in": "Loggar in...",
      "signin-google": "Logga in med Google",
      "signin-facebook": "Logga in med Facebook",
      "no-account": "Inget konto?",
      "the-explorer": "Utforskaren",
      "the-explorer-short":
        "Utforskaren är den del av RES Insider som hjälper dig att ”kallt” söka efter tips och listor i din smak. Utforskaren är indelad i två tudelade kategorier:",
      "the-explorer-text-1":
        "Mest populära tips i ditt universum, relaterade till staden och kategorin av tips du tittar på",
      "the-explorer-text-2":
        "Mest populära listor i ditt universum, relaterade till staden du tittar på",
      "the-explorer-text-3":
        "Mest populära tips på RES Insider, relaterade till staden och kategorin av tips du tittar på",
      "the-explorer-text-4":
        "Mest populära listor på RES Insider, relaterade till staden du tittar på",
      faq: "FAQ",
      "faq-meaning": "Vanliga frågor och svar",
      "faq-short":
        "Behöver du hjälp att förstå hur RES Insider fungerar? Prova att bläddra bland våra vanliga frågor nedan.",
      "how-create-profile": "Hur skapar jag en profil?",
      "how-profile-1": "Använd profilknappen i det övre högra hörnet",
      "how-profile-2": "Välj Redigera profil",
      "how-profile-3": "Fyll i ditt namn, hemstad och välj en bild om du vill.",
      "how-profile-4": "Du kan också dela bakgrundsinformation om dig själv.",
      "how-profile-5": "Spara och du är klar.",
      "how-create-list": "Hur skapar jag en Lista?",
      "how-list-1": 'Klicka på "Skapa" knappen',
      "how-list-2": 'Välj "ny Lista"',
      "how-list-3": "Välj stad",
      "how-list-4": "Namnge Listan",
      "how-list-5": "Om du vill kan du lägga till en beskrivning av Listan",
      "how-list-6": 'Klicka på "Skapa Lista knappen"',
      "how-list-photo":
        'Du väljer inte en bild vid det här tillfället. Det första Tipset du skapar eller lånar till listan kommer automatiskt att användas som bilden som representerar listan. Om du vill ändra bilden kan du göra det genom att använda knappen "Redigera lista" när det första Tipset har lagts till i listan.',
      "how-create-tip": "Hur skapar jag ett Tips?",
      "how-tip-1": 'Klicka på "Skapa-knappen".',
      "how-tip-2": 'Välj "nytt tips".',
      "how-tip-3": 'Klicka på "Venue".',
      "how-tip-4": 'Välj "kategori".',
      "how-tip-5":
        " Ge tipset en titel. (Titeln ska inte vara det faktiska namnet på platsen som tipset handlar om. Detta genereras automatiskt och visas i tipset. Ju mer rubriken säger om tipsets kärnvärde desto mer sannolikt är det att andra kommer att se det.)",
      "how-tip-6":
        "Välj vilken lista du vill lägga tipset i, du har också möjlighet att skapa en ny lista för tipset.",
      "how-tip-7": "Skriv själva texten för tipset.",
      "how-tip-8":
        'Ett foto som har en upphovsrättsfri bild genereras automatiskt. Om du vill lägga till ett foto som du själv har tagit klicka på "eller ladda upp foto".',
      "how-tip-9":
        'Klicka på Skapa tips eller spara som ett utkast innan du är säker på att tipset är klart. Om du sparar tipset som ett utkast kommer du att se det i din egen lista med en tydlig notering att det är bara ett utkast och ingen utom du kan se det. Du kan också komma åt utkastet genom att klicka på knappen "Utkast".',
      "how-digest-tip": "Hur tolkar jag ett Tips?",
      "how-digest-1": "Tipsen har följande funktioner:",
      "how-digest-2":
        "Använd den här knappen för att se var platsen eller evenemanget finns på kartan.",
      "how-digest-3": "Adress, telefonnummer etc.",
      "how-digest-4": "Låna enkelt Tipset till en lista du gjort själv.",
      "how-digest-5":
        "De tre punkterna innehåller olika sätt att redigera Tipset.",
      "how-digest-6":
        "Använd för att rapportera Tipset till oss om det är kränkande, om du misstänker upphovsrättsproblem etc.",
      "how-digest-7":
        "Använd för att exportera detta Tip till andra webbplatser eller medier.",
      "how-explorer-1":
        "Mest populära tips i ditt Universum som är relaterade till staden och kategorin av tips du tittar på.",
      "how-explorer-2":
        "Mest populära listor i ditt Universum som är relaterade till staden du tittar på.",
      "how-explorer-3":
        "Mest populära tips på RES Insider som är relaterade till staden och kategorin av tips du tittar på.",
      "how-explorer-4":
        "Mest populära listor på RES Insider som är relaterade till staden du tittar på.",
      "how-save-draft": "Hur sparar jag ett Utkast?",
      "how-save-draft-desc":
        'Om du har Tips som du fortfarande arbetar med kommer de att visas i stadslistan du har valt för Tipset, tydligt markerade som utkast. Du har också en mapp för utkast som visas så snart du har ett eller flera opublicerade utkast. När du är klar med Tipset kan du helt enkelt använda "publicera"-knappen och Tipset kommer att visas för alla och lämna utkastmappen.',
      "how-search": "Hur Söker jag?",
      "how-search-desc":
        "Använd sökfältet för att hitta vad som helst på webbplatsen. Det kan vara en stad, användare, enskilt tips eller annat.",
      "enter-username": "Skriv in ditt användarnamn",
      "enter-password": "Skriv in ditt lösenord",
      "invalid-credentials": "Ogiltiga inloggningsuppgifter",
      email: "E-mail",
      password: "Lösenord",
      forgot: "Glömt ditt lösenord?",
      map: "Karta",
      details: "Detaljer",
      "borrow-digest": "Låna",
      "edit-etc": "Redigera etc",
      report: "Rapportera",
      export: "Exportera",
      "how-use-explorer": "Hur använder jag Utforskaren?",
      "how-explorer-desc":
        "Utforskaren kommer att försöka ge dig förslag på listor och tips som är så relevanta som möjligt för dig, med hänsyn till vilken stad du tittar på och vilken kategori du valt. Utforskaren är uppdelad i fyra grupper:",
      "who-we-are": "Vilka är vi",
      "contact-us": "Kontakta oss",
      "report-abuse": "Rapportera missbruk",
      "site-map": "Platskarta",
      "cookie-policy": "Hantering av Kakor",
      "copyright-res-inside":
        "© 2023 RES Insider. Alla rättigheter förbehållna.",
      "about-hero-enjoy":
        "RES Insider är din goda vän på plats. En interaktiv reseguide med korta, personliga tips – alltid rakt på sak.",
      "about-hero-borrow":
        "Du kan själv bli en RES Insider genom att spara tips från andra insiders.",
      "about-hero-inspired":
        "När du sparar andras tips blir de en del av ditt reseuniversum – och allt blir mer personligt relevant baserat på just din smak.",
      "learn-more": "Läs mer",
      "show-more": "Visa mer",
      "no-results": "Kunde inte hitta några resultat",
      search: "Sök",
      "create-account": "Skapa konto",
      or: "ELLER",
      "my-startpage": "Min sida",
      firstname: "Vänligen ange ditt förnamn",
      lastname: "Vänligen ange ditt efternamn",
      "invalid-email": "Ogiltig e-postadress",
      "invalid-password":
        "Lösenordet måste vara 8-24 tecken långt och innehålla minst en siffra och en stor bokstav.",
      "used-email": "E-postadressen är redan registrerad.",
      "referral-undefiend": "Den hänvisade användarkoden hittades inte.",
      "account-created": "Ditt konto har skapats!",
      "account-created-message":
        "Vänligen kontrollera din e-post för att verifiera.",
      "create-firstname": "Förnamn",
      "create-lastname": "Efternamn",
      "create-referral": "Referenskod",
      "creating-account": "Skapar konto...",
      "create-google": "Skapa konto med Google",
      "create-facebook": "Skapa konto med Facebook",
      "already-member": "Redan medlem? ",
      profiles: "Profiler",
      destinations: "Destinationer",
      lists: "Listor",
      "lists-with-tips": "Listor med tips i",
      that: "Som",
      by: "Av ",
      "error-title": "Inget att se här!",
      "error-text": "Tyvärr, den efterfrågade datan går inte att finna :(",
      "success-remove": "Borttagning lyckades ",
      "error-remove": "Ett fel uppstod vid borttagning av ",
      delete: "Ta bort",
      "move-to-draft": "Flytta till utkast",
      cancel: "Avbryt",
      draft: "Utkast",
      "edit-tip": "Redigera Tips",
      "remove-from-list": "Ta bort från lista",
      "unborrow-from-list": "Sluta låna från lista",
      "move-tip-to-another-list": "Flytta Tips till en annan lista",
      "save-tip-additional-list": "Spara Tips till ytterligare listor",
      "report-tip": "Rapportera Tips",
      "a-link-to": "En länk till",
      "has-been-copied-to-your-clipboard": "har kopierats till urklipp.",
      "copy-link-tip": "Kopiera länk till Tips",
      "photo:": "Foto:",
      website: "Hemsida",
      "borrow-tip": "Låna",
      "book-now": "Boka nu",
      close: "Stäng",
      all: "Alla",
      Restaurants: "Restauranger",
      Hotels: "Hotell",
      Nightlife: "Nattliv",
      Shopping: "Shopping",
      "Do's": "Do's",
      "Don'ts": "Don'ts",
      "confirm-delete-list": "Vill du verkligen ta bort denna Lista?",
      yes: "Fortsätt",
      no: "Avbryt",
      "list-deleted": "Din lista har blivit borttagen",
      "something-went-wrong": "Någonting gick fel!",
      success: "Lyckades",
      following: "Du följer nu ",
      unfollowed: "Du har slutat följa ",
      follow: "Följ",
      unfollow: "Sluta följa",
      tip: "Tips",
      tips: "Tips",
      "tips-in": "Enskilda tips i",
      edit: "Redigera",
      remove: "Ta bort",
      "create-or-borrow":
        "Skapa dina egna Tips eller låna från andra användare",
      "empty-list": "Den här listan ser tom ut",
      "create-tip": "Skapa Tips",
      followers: " Följare",
      "no-lists": "Inga listor att visa",
      "my-lists": "Mina listor",
      "following-left-column": "Följer",
      follows: "följer",
      "explore-my-universe": "Utforska mitt Universum",
      explore: "Utforska",
      universe: "Universum",
      "explore-res-inside": "Utforska RES Insider",
      "no-tips": "Inga Tips att visa",
      borrower: "Lånare",
      borrowers: "Lånare",
      follower: "Följare",
      "discover-new-destinations": "Upptäck nya destinationer",
      "discover-new-destinations-text":
        "Sök efter städer eller välj populära destinationer nedan",
      "about-lists":
        "En lista är en samling av relaterade tips för en specifik plats. Du kan skapa egna tips eller låna tips från andra användare.",
      "create-list": "Skapa lista",
      "about-tips":
        "Ett bra tips bör vara tydlig och rakt på sak. Således kan en restaurang ha +1000 tips där varje tips är relevant i sig själv.",
      "explore-popular-destinations": "Upptäck populära resmål",
      "create-tip-or-list": "Skapa dina egna tips och listor",
      "popular-lists-my-universe": "Populära Listor i mitt universum",
      "popular-tips-my-universe": "Populära Tips i mitt Universum",
      "popular-lists-res-inside": "Populära Listor från RES Insider",
      "popular-tips-res-inside": "Populära Tips från RES Insider",
      "popular-profiles-res-inside": "Populära Profiler från RES Insider",
      start: "Start",
      list: "Lista",
      "photo-draft": "Foto utkast",
      pricing: "Prissättning",
      careers: "Karriär",
      privacy: "Integritet",
      "i-am-following": "Jag följer",
      "is-following": "följer",
      "who-follow-me": "Vilka följer mina listor?",
      "whos-following": "Vilka följer",
      "who-borrow-me": "Vilka lånar mina listor?",
      "whos-borrowing": "Vilka lånar",
      borrowed: "Lånade",
      joined: "Gick med",
      January: "Januari",
      February: "Februari",
      March: "Mars",
      April: "April",
      May: "Maj",
      June: "Juni",
      July: "Juli",
      August: "Augusti",
      September: "September",
      October: "Oktober",
      November: "November",
      December: "December",
      "enter-title": "Vänligen ange en titel",
      "select-city": "Vänligen välj en stad",
      "already-exists-list-title":
        "Du har redan en list med detta namn för vald stad",
      "select-image": "Vänligen ange en bild",
      "tip-added": "Tips tillagd till ",
      your: "Din",
      new: " nya",
      "list-has-been-published": "lista har blivit publicerad",
      "unexpected-error": "Ett oväntat fel uppstod",
      "discard-changes": "Är du säker på att du vill ta bort ändringarna?",
      title: "Titel",
      city: "Stad",
      country: "Land",
      venue: "Plats",
      description: "Beskrivning",
      "tiplist-cover": "Tipslista omslag",
      creating: "Skapar...",
      "save-edit": "Spara ändringar",
      saving: "Sparar...",
      "enter-description": "Vänligen ange en beskrivning",
      "select-venue": "Vänligen välj en plats",
      "select-category": "Vänligen välj en kategori",
      category: "Kategori",
      "add-tip": "Vänligen lägg till Tipset i en lista",
      "your-tip-has-been": "Ditt tips har blivit",
      published: "publicerat",
      "saved-as-draft": "sparat som utkast",
      "discard-tip": "Är du säker på att du vill ta bort detta tips?",
      "tip-deleted": "Ditt tips har blivit borttaget",
      "tip-cover": "Tips omslag",
      "save-draft": "Spara utkast",
      "saving-draft": "Sparar utkast...",
      publish: "Publicera",
      publishing: "Publicerar...",
      photo: "Foto",
      "invalid-image": "Ogiltig bild",
      "file-too-big": "Filen är för stor. Max filstorlek är 5MB",
      "or-upload-image": "Eller ladda upp bild",
      "remove-image": "Ta bort bild",
      "click-here-upload": "Klicka här för att ladda upp",
      "or-drag-drop": " eller dra och släpp",
      "enter-first-name": "Vänligen ange ditt förnamn",
      "enter-last-name": "Vänligen ange ditt efternamn",
      "slug-error":
        "Slug måste vara mellan 6 och 30 bokstäver och får endast innehålla engelska bokstäver och bindestreck",
      "slug-taken": "Slug är upptagen",
      "successfully-update-profile": "Din profil har blivit uppdaterad",
      "update-profile-error":
        "Ett fel uppstod när din profil skulle uppdateras",
      "drop-upload": "Släpp filer här för att ladda upp",
      "edit-my-profile": "Redigera min profil",
      "personal-link": "Personlig länk",
      "copied-referral": "En hänvisningslänk har kopierats till urklipp",
      "copy-referral": "Kopiera hänvisningslänk",
      biography: "Biografi",
      "change-password": "Ändra lösenord",
      save: "Spara",
      "successfully-moved-tip": "Tipset har blivit flyttat till ",
      "create-new-list": "Skapa ny Lista",
      "or-move": "Eller flytta",
      from: "från",
      to: "till",
      "or-lower": "eller",
      "to-existing": "till befintlig Lista",
      add: "lägg till",
      "who-follow": "Vilka följer",
      "lists-followed-by": "listor följda av",
      "i-am": "Jag",
      "no-list-followed": "Inga listor följs",
      "member-since": "Medlem sedan",
      "who-borrow": "Vem lånar",
      "tips-borrowed-by": "Tips lånade av",
      borrowing: "Lånar",
      "passwords-match": "Lösenorden matchar inte",
      "password-changed": "Lösenordet har ändrats",
      "old-password": "Gammalt lösenord",
      "new-password": "Nytt lösenord",
      "confirm-new-password": "Bekräfta nytt lösenord",
      confirm: "Bekräfta",
      "we-are-glad":
        "Vi är glada att ha dig ombord. Här är några snabba tips för att komma igång.",
      "once-started":
        "När du väl börjar använda RES Insider kommer du enkelt att hitta det mest relevanta innehållet för dig.",
      next: "Nästa",
      skip: "Hoppa över",
      "has-been-reported": " har rapporterats",
      "inspect-report":
        "En RES Insider-redaktör kommer att granska det och vidta lämpliga åtgärder.",
      "reason-for-report": "Anledning till rapporten",
      reason: "Anledning",
      "password-reset-link": "Länk för återställning av lösenord skickad!",
      "email-password-reset":
        "Var vänlig kontrollera din e-post för att återställa ditt konto.",
      "error-change-password":
        "Ett fel uppstod vid återställning av ditt lösenord.",
      "please-try-later": "Var vänlig försök igen senare.",
      "correct-code": "Är koden korrekt?",
      "forgot-password": "Glömt lösenord",
      "reset-password": "Återställ lösenord",
      "sending-code": "Skickar kod...",
      "confirm-password-reset": "Bekräfta återställning av lösenord",
      resetting: "Återställer...",
      "resend-code": "Skicka om kod",
      "or-add-to-existing": "Eller lägg till i befintlig lista",
      "search-in": "Sök i",
      there: "Det",
      is: "är",
      are: "är",
      person: "person",
      people: "personer",
      "following-this-list": "som följer denna lista",
      "welcome-to-wl": "Välkommen till RES Insider!",
      verifying: "Verifierar konto",
      "successfully-verified": "Verifiering lyckades",
      "failed-verify": "Verifiering misslyckades",
      "please-wait": "Var god vänta...",
      "tiplist-copied-clipboard":
        "Länken till tipslistan har kopierats till urklipp",
      share: "Dela",
      "res-inside-testimonials":
        "Träffa andra medlemmar av WorldLobby-communityt",
    },
  },
  en: {
    translation: {
      enjoy: "Check out lists of travel tips from cities around the world",
      borrow: "Save tips and follow lists you like",
      inspired: "Get inspired by like-minded travelers",
      "home-search": "Search for a city",
      "home-popular": "Popular Destinations",
      "header-another": "Choose another destination",
      help: "Help",
      drafts: "Drafts",
      "click-edit": "Click to edit",
      "untitled-tip": "Untitled tip",
      create: "Create",
      "create-new": "Create new",
      "new-list": "New list",
      "new-tip": "New tip",
      account: "Account",
      "my-profile": "My profile",
      "edit-profile": "Edit profile",
      "log-out": "Log out",
      about: "About",
      "about-res-inside": "About RES Insider",
      "about-res-inside-text":
        "RES Insider is your good friend on the ground. An interactive travel guide with short, tips - created by travelers who love to share their personal discoveries and personal discoveries, and grows with travelers who are looking for the the same.",
      "get-started": "Get started",
      "get-started-text": `Start by registering. Once that's done, you can create your first list of a city or a place. You can either fill it with your own tips that you create and add to the list. Or you can use the unique feature of RES Insider which is the ability to borrow and save tips from someone else's list. someone else's list. You can learn more about our features below.`,
      categories: "Categories",
      "categories-short":
        "There are six different categories of tips. They are:",
      hotels: "Hotels",
      restaurants: "Restaurants",
      "night-life": "Night life",
      shopping: "Shopping",
      dos: "Do's (general tips that do not fit into any of the above categories)",
      donts: "Don'ts (warnings for things you think should be avoided)",
      "create-tips": "Create tips",
      "tip-short":
        "A good tip is clear and concrete. In this way, one restaurant can have may have over a thousand tips, but each tip is relevant and unique. Things to to consider that make tips personal and inspiring are:",
      "specific-dish": "Telling about a specific dish you liked",
      "different-dish":
        "If someone on the staff was particularly nice, such as a concierge or the head waiter",
      "specific-cocktail": "A particular cocktail you had that was super tasty",
      "great-view": "A great view",
      "specific-room": "A hotel room you really liked",
      "specific-person":
        "A special person (bartender or a concierge for example).",
      "borrow-tips": "Borrow tips",
      "borrow-tips-text": `We know that the best travel tips always come from good friends. That's why we have created a unique feature on RES Insider that allows you to borrow and save other people's and save other people's tips. You can do this simply by clicking on the "borrow" buttonbutton in the tip itself and selecting the list you want to add the tip to. The content of the RES Insider is thus created on the one hand by you writing your own tips. On the other hand, and just as importantly, you will be curating lists of borrowed tips from other insiders. Much like you create playlists of music on Spotify that others can also save, in their entirety. or specifically selected songs. Smart, right?`,
      "follow-lists": "Follow lists",
      "follow-lists-text":
        'If someone has created a list that you like in its entirety, you can also choose to follow the entire list. You can do this by clicking on the "follow" button next to the the list name.',
      "my-universe": "My universe",
      "my-universe-text":
        "RES Insider is user-centered, it's all about you and all the other Insiders. This means that each insider has their own universe. Your universe consists of all the insiders from whom you have borrowed and saved tips or entire lists - your universe is populated by these insiders. Why is that? Well, it's to help the algorithm find new, relevant tips and lists that suit YOUR taste. If you borrowed and saved a particular restaurant tip in New York, it's likely that you also like that insider's tip about a restaurant in Lima. In this way, your universe grows and you stumble upon new potential discoveries around the world.",
      "the-explorer": "The Explorer",
      "the-explorer-short": `The explorer is the part of RES Insider that helps you to "cold" search for tips and lists to your liking. The Explorer is divided into two two-part categories:`,
      "the-explorer-text-1":
        "Most popular tips in your universe, related to the city and category of tips you are looking at",
      "the-explorer-text-2":
        "Most popular lists in your universe, related to the city you are looking at",
      "the-explorer-text-3":
        "Most popular tips on RES Insider, related to the city and category of tips you are looking at",
      "the-explorer-text-4":
        "Most popular lists on RES Insider, related to the city you are looking at",
      faq: "FAQ",
      "faq-meaning": "Frequently asked questions",
      "faq-short":
        "Do you need help understanding how RES Insider works? Try browsing our frequently asked questions below.",
      "how-create-profile": "How do I create a Profile?",
      "how-profile-1": "Use the profile button in the top right corner",
      "how-profile-2": "Chose Edit profile",
      "how-profile-3":
        "Fill in your name, home city and chose a picture if you wish.",
      "how-profile-4":
        "You can also share some background on yourself if you want to.",
      "how-profile-5": "Save and you are done.",
      "how-create-list": "How do I create a List?",
      "how-list-1": 'Click the "Create button"',
      "how-list-2": 'Choose "new List"',
      "how-list-3": "Choose city",
      "how-list-4": "Name List",
      "how-list-5": "If you wish; describe List",
      "how-list-6": 'Click the "create List button"',
      "how-list-photo":
        "You do not choose a photo at this point. The photo of the first Tip you create or borrow to the List will automatically be addressed as the photo representing the List. If you wish to change the photo you can do that by using the Edit List button once the first Tip is put into the List.",
      "how-create-tip": "How do I create a Tip?",
      "how-tip-1": 'Click the "Create button".',
      "how-tip-2": 'Choose "new Tip".',
      "how-tip-3": 'Click "Venue".',
      "how-tip-4": 'Choose "category".',
      "how-tip-5":
        " Give the Tip a title. (The title should not be the actual name of the place the Tip is about. This is automatically generated and is shown in the Tip. The more the Header says about the core value of the Tip the more likely it is others will see it.)",
      "how-tip-6":
        "Choose what List you want to put the Tip in, you also have the option to create a new List for the Tip.",
      "how-tip-7": "Write the actual text for the Tip.",
      "how-tip-8":
        'A photo that has a copyright free picture will be automatically generated. If you wish to add a photo that you have taken yourself click "or upload photo".',
      "how-tip-9":
        'Click Create Tip or save as a draft before you are sure the Tip is ready. If you save the Tip as a draft, you will see it in your own List with a clear note that it is only a draft and nobody but you can see it. You can also access the draft by clicking the button "Drafts".',
      "how-digest-tip": "How do I digest a Tip?",
      "how-digest-1": "The Tip has the following functionalities:",
      "how-digest-2":
        "Use this button to see where the venue or place is on the map.",
      "how-digest-3": "Address, phone number etc.",
      "how-digest-4": "Simply borrow the Tip to a chosen List of your own.",
      "how-digest-5": "The three dots contain different ways to edit the Tip.",
      "how-digest-6":
        "Use to report Tip to us if it is abusive, you suspect copy right issues etc.",
      "how-digest-7": "Use to export this Tip into other sites or media.",
      "how-use-explorer": "How do I use the Explorer?",
      "how-explorer-desc":
        "The Explorer will try to give you suggestions on Lists and Tips that are as relevant as possible to you given what city you are browsing and what category you are looking at. The Explorer is divided into four groups:",
      "how-explorer-1":
        "Most popular Tips in your Universe related to the city and the category of Tips you are looking at.",
      "how-explorer-2":
        "Most popular Lists in your Universe related to the city you are looking at.",
      "how-explorer-3":
        "Most popular Tips on RES Insider related to the city and the category of Tips you are looking at.",
      "how-explorer-4":
        "Most popular Lists on RES Insider related to the city you are looking at.",
      "how-save-draft": "How do I save a Draft?",
      "how-save-draft-desc":
        "If you have Tips that you are still working on, they will show in the city list you have chosen for the Tip clearly marked as Draft. You also have a Drafts folder that appears as soon as you have one or more unpublished drafts. As soon as you are done with the Tip simply use the “publish button” and the Tip will be visual to everyone and leave the Draft folder.",
      "how-search": "How do I Search?",
      "how-search-desc":
        "Use the search field to find anything on the site. It might be a city, user, individual Tip or whatever.",
      "enter-username": "Please enter your username",
      "enter-password": "Please enter your password",
      "invalid-credentials": "Invalid credentials",
      email: "E-mail",
      password: "Password",
      forgot: "Forgot?",
      map: "Map",
      details: "Details",
      "borrow-digest": "Borrow",
      "edit-etc": "Edit etc",
      report: "Report",
      export: "Export",
      "who-we-are": "Who we are",
      "contact-us": "Contact us",
      "report-abuse": "Report abuse",
      "site-map": "Site map",
      "cookie-policy": "Cookie policy",
      "copyright-res-inside": "© 2023 RES Insider. All rights reserved.",
      "about-hero-enjoy":
        "RES Insider is your good friend on the ground. An interactive travel guide with short, personalized tips - always straight to the point.",
      "about-hero-borrow":
        "You can become a RES Insider yourself by saving tips from other insiders.",
      "about-hero-inspired":
        "When you save other people's tips, they become part of your travel universe - and everything is becomes more personally relevant based on your tastes.",
      "learn-more": "Learn more",
      "show-more": "Show more",
      "no-results": "Couldn't find any results",
      search: "Search",
      language: "Language",
      "choose-language": "Choose language",
      "sign-in": "Sign in",
      "signing-in": "Signing in...",
      "signin-google": "Sign in with Google",
      "signin-facebook": "Sign in with Facebook",
      "no-account": "No account?",
      "create-account": "Create account",
      or: "OR",
      "my-startpage": "My page",
      firstname: "Please enter your first name",
      lastname: "Please enter your last name",
      "invalid-email": "Invalid E-mail address",
      "invalid-password":
        "Password must be 8 - 24 characters long and contain at least one number and one upper case letter.",
      "used-email": "E-mail address is already registered.",
      "referral-undefiend": "Referred user code not found.",
      "account-created": "Your account has been created!",
      "account-created-message": "Please check your E-mail to verify.",
      "create-firstname": "First name",
      "create-lastname": "Last name",
      "create-referral": "Referral code",
      "creating-account": "Creating account...",
      "create-google": "Create account with Google",
      "create-facebook": "Create account with Facebook",
      "already-member": "Already a member? ",
      profiles: "Profiles",
      destinations: "Destinations",
      lists: "Lists",
      "lists-with-tips": "Lists with tips in",
      that: "That",
      by: "By ",
      "error-title": "Nothing to see here!",
      "error-text":
        "Sorry, the content you have requested could not be found :(",
      "success-remove": "Successfully removed ",
      "error-remove": "An error occurred when removing ",
      delete: "Delete",
      "move-to-draft": "Move to draft",
      cancel: "Cancel",
      draft: "Draft",
      "edit-tip": "Edit tip",
      "remove-from-list": "Remove from list",
      "unborrow-from-list": "Unborrow from list",
      "move-tip-to-another-list": "Move tip to another list",
      "save-tip-additional-list": "Save tip to additional lists",
      "report-tip": "Report tip",
      "a-link-to": "A link to",
      "has-been-copied-to-your-clipboard": "has been copied to the clipboard",
      "copy-link-tip": "Copy link to tip",
      "photo:": "Photo:",
      website: "Website",
      "borrow-tip": "Borrow",
      "book-now": "Book now",
      close: "Close",
      all: "All",
      Restaurants: "Restaurants",
      Hotels: "Hotels",
      Nightlife: "Nightlife",
      Shopping: "Shopping",
      "Do's": "Do's",
      "Don'ts": "Don'ts",
      "confirm-delete-list": "Are you sure you want to delete this list?",
      yes: "Yes",
      no: "No",
      "list-deleted": "Your list has been deleted",
      "something-went-wrong": "Something went wrong",
      success: "Success",
      following: "You're now following ",
      unfollowed: "You've unfollowed ",
      follow: "Follow",
      unfollow: "Unfollow",
      tip: "Tip",
      tips: "Tips",
      "tips-in": "Individual tips in",
      edit: "Edit",
      remove: "Remove",
      "create-or-borrow": "Create your own tips or borrow from other users",
      "empty-list": "This list looks empty",
      "create-tip": "Create tip",
      followers: " Followers",
      "no-lists": "No lists to show",
      "my-lists": "My lists",
      "following-left-column": "Following",
      follows: "follows",
      "explore-my-universe": "Explore My Universe",
      explore: "Explore",
      universe: "Universe",
      "explore-res-inside": "Explore RES Insider",
      "no-tips": "No tips to show",
      borrower: "Borrower",
      borrowers: "Borrowers",
      follower: "Follower",
      "discover-new-destinations": "Discover new destinations",
      "discover-new-destinations-text":
        "Search city or choose popular destinations below",
      "about-lists":
        "A list is a collection of related Tips for a specific location. You can create your own Tips or borrow Tips from other users.",
      "create-list": "Create list",
      "about-tips":
        "A good Tip should be explicit and to the point. Thus, a restaurant could have +1000 Tips where each Tip is relevant in itself. ",
      "explore-popular-destinations": "Discover popular destinations",
      "create-tip-or-list": "Create your own tip or list",
      "popular-lists-my-universe": "Popular Lists in My universe",
      "popular-tips-my-universe": "Popular Tips in My universe",
      "popular-lists-res-inside": "Popular Lists from RES Insider",
      "popular-tips-res-inside": "Popular Tips in RES Insider",
      "popular-profiles-res-inside": "Popular Profiles from RES Insider",
      start: "Start",
      list: "List",
      "photo-draft": "Photo draft",
      pricing: "Pricing",
      careers: "Careers",
      privacy: "Privacy",
      "i-am-following": "I am following",
      "is-following": "is following",
      "who-follow-me": "Who's following my lists?",
      "whos-following": "Who's following",
      "who-borrow-me": "Who's borrowing my lists?",
      "whos-borrowing": "Who's borrowing",
      borrowed: "Borrowed",
      joined: "Joined",
      January: "January",
      February: "February",
      Mars: "Mars",
      April: "April",
      May: "May",
      June: "June",
      July: "July",
      August: "August",
      September: "September",
      October: "October",
      November: "November",
      December: "December",
      "enter-title": "Please enter a title",
      "select-city": "Please select a city",
      "already-exists-list-title":
        "You already have a list with this title for this city",
      "select-image": "Please select an image",
      "tip-added": "Tip successfully added to ",
      your: "Your",
      new: " new",
      "list-has-been-published": "list has been published",
      "unexpected-error": "An unexpected error occurred",
      "discard-changes": "Are you sure you want to discard your changes?",
      title: "Title",
      city: "City",
      country: "Country",
      venue: "Venue",
      description: "Description",
      "tiplist-cover": "Tiplist cover",
      creating: "Creating...",
      "save-edit": "Save edit",
      saving: "Saving...",
      "enter-description": "Please enter a description",
      "select-venue": "Please select a venue",
      "select-category": "Please select a category",
      category: "Category",
      "add-tip": "Please add your tip to a list",
      "your-tip-has-been": "Your tip has been",
      published: "published",
      "saved-as-draft": "saved as a draft",
      "discard-tip": "Are you sure you want to delete this tip?",
      "tip-deleted": "Your tip has been deleted",
      "tip-cover": "Tip cover",
      "save-draft": "Save draft",
      "saving-draft": "Saving draft...",
      publish: "Publish",
      publishing: "Publishing...",
      photo: "Photo",
      "invalid-image": "Invalid image",
      "file-too-big": "File too large. Maximum size: 5MB",
      "or-upload-image": "Or upload image",
      "remove-image": "Remove image",
      "click-here-upload": "Click here to upload",
      "or-drag-drop": " or drag and drop",
      "enter-first-name": "Please enter your first name",
      "enter-last-name": "Please enter your last name",
      "slug-error":
        "Slug has to be between 6 and 30 chars and only contain lowercase english letters and hyphens.",
      "slug-taken": "Slug is unavailable",
      "successfully-update-profile": "Successfully updated profile",
      "update-profile-error": "An error occured when updaing profile",
      "drop-upload": "Drop to upload",
      "edit-my-profile": "Edit my profile",
      "personal-link": "Personal link",
      "copied-referral":
        "A signup referral link has been copied to the clipboard",
      "copy-referral": "Copy referral link",
      biography: "Biography",
      "change-password": "Change password",
      save: "Save",
      "successfully-moved-tip": "Successfully moved tip to ",
      "create-new-list": "Create new List",
      "or-move": "Or move",
      from: "from",
      to: "to",
      "or-lower": "Or",
      "to-existing": "to existing list",
      add: "add",
      "who-follow": "Who's is following",
      "lists-followed-by": "lists followed by",
      "i-am": "I am",
      "no-list-followed": "No lists followed",
      "member-since": "Member since",
      "who-borrow": "Who's borrowing",
      "tips-borrowed-by": "Tips borrowed by",
      borrowing: "Borrowing",
      "passwords-match": "The passwords must match",
      "password-changed": "Password successfully changed",
      "old-password": "Old password",
      "new-password": "New password",
      "confirm-new-password": "Confirm new password",
      confirm: "Confirm",
      "we-are-glad":
        "We’re glad to have you onboard. Here are some quick tips to get you up and running.",
      "once-started":
        "Once you start using RES Insider you’ll easily find the most relevant content for you.",
      next: "Next",
      skip: "Skip",
      "has-been-reported": " has been reported",
      "inspect-report":
        "A RES Insider editor will inspect it and take the appropriate action.",
      "reason-for-report": "Reason for the report",
      reason: "Reason",
      "password-reset-link": "Password reset link sent!",
      "email-password-reset": "Please check your E-mail to reset your account.",
      "error-change-password": "An error occured when resetting your password",
      "please-try-later": "Please try again later.",
      "correct-code": "Is the code correct?",
      "forgot-password": "Forgot password",
      "reset-password": "Reset password",
      "sending-code": "Sending code...",
      "confirm-password-reset": "Confirm password reset",
      resetting: "Resetting...",
      "resend-code": "Resend code",
      "or-add-to-existing": "Or add to existing list",
      "search-in": "Search in",
      there: "There",
      is: "is",
      are: "are",
      person: "person",
      people: "people",
      "following-this-list": "following this list",
      "welcome-to-wl": "Welcome to RES Insider!",
      verifying: "Verifying account",
      "successfully-verified": "Successfully verified",
      "failed-verify": "Verification failed",
      "please-wait": "Please wait...",
      "tiplist-copied-clipboard":
        "The link to the tiplist has been copied to your clipboard",
      share: "Share",
      "res-inside-testimonials":
        "Meet other members of the WorldLobby community",
    },
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    returnNull: false,
    fallbackLng: "sv",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
