import React from "react";

import css from "./Category.module.css";

const mapping = {
  100: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
    </svg>
  ),
  0: (
    <svg
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0V6C0 8.97 2.16 11.43 5 11.91V16H2V18H10V16H7V11.91C9.84 11.43 12 8.97 12 6V0H0ZM6 10C4.14 10 2.59 8.72 2.14 7H9.86C9.41 8.72 7.86 10 6 10ZM10 5H2V2H10V5Z" />
    </svg>
  ),
  1: (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4.75001 8.61663L7.10834 6.2583L1.25834 0.416631C-0.0416595 1.71663 -0.0416595 3.82496 1.25834 5.1333L4.75001 8.61663ZM10.4 7.1083C11.675 7.69996 13.4667 7.2833 14.7917 5.9583C16.3833 4.36663 16.6917 2.0833 15.4667 0.858298C14.25 -0.358369 11.9667 -0.0583687 10.3667 1.5333C9.04167 2.8583 8.62501 4.64996 9.21667 5.92496L1.08334 14.0583L2.25834 15.2333L8.00001 9.5083L13.7333 15.2416L14.9083 14.0666L9.17501 8.3333L10.4 7.1083Z" />
    </svg>
  ),
  2: (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM14 18H2V6H4V8C4 8.55 4.45 9 5 9C5.55 9 6 8.55 6 8V6H10V8C10 8.55 10.45 9 11 9C11.55 9 12 8.55 12 8V6H14V18Z" />
    </svg>
  ),
  3: (
    <svg
      width="22"
      height="15"
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6 9C7.66 9 9 7.66 9 6C9 4.34 7.66 3 6 3C4.34 3 3 4.34 3 6C3 7.66 4.34 9 6 9ZM6 5C6.55 5 7 5.45 7 6C7 6.55 6.55 7 6 7C5.45 7 5 6.55 5 6C5 5.45 5.45 5 6 5ZM18 2H10V10H2V0H0V15H2V12H20V15H22V6C22 3.79 20.21 2 18 2ZM20 10H12V4H18C19.1 4 20 4.9 20 6V10Z" />
    </svg>
  ),
  4: (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5 6.66671H12.2417L13.0333 2.85837L13.0583 2.59171C13.0583 2.25004 12.9167 1.93337 12.6917 1.70837L11.8083 0.833374L6.32499 6.32504C6.01666 6.62504 5.83333 7.04171 5.83333 7.50004V15.8334C5.83333 16.75 6.58333 17.5 7.49999 17.5H15C15.6917 17.5 16.2833 17.0834 16.5333 16.4834L19.05 10.6084C19.125 10.4167 19.1667 10.2167 19.1667 10V8.33337C19.1667 7.41671 18.4167 6.66671 17.5 6.66671ZM17.5 10L15 15.8334H7.49999V7.50004L11.1167 3.88337L10.1917 8.33337H17.5V10ZM0.833328 7.50004H4.16666V17.5H0.833328V7.50004Z" />
    </svg>
  ),
  5: (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 0H5C4.17 0 3.46 0.5 3.16 1.22L0.14 8.27C0.05 8.5 0 8.74 0 9V11C0 12.1 0.9 13 2 13H8.31L7.36 17.57L7.33 17.89C7.33 18.3 7.5 18.68 7.77 18.95L8.83 20L15.42 13.41C15.78 13.05 16 12.55 16 12V2C16 0.9 15.1 0 14 0ZM14 12L9.66 16.34L11 11H2V9L5 2H14V12ZM18 0H22V12H18V0Z" />
    </svg>
  ),
};

type CategoryProps = {
  icon: keyof typeof mapping;
  className?: string;
  circle?: boolean;
  height?: number;
  width?: number;
};

const colors = {
  100: "var(--color-blue500)",
  0: "#A11043",
  1: "#0086C9",
  2: "#DD2590",
  3: "#7A5AF8",
  4: "#12B76A",
  5: "#D92D20",
};

export default function Category({
  icon,
  className,
  circle = false,
  height = 16,
  width = 16,
}: CategoryProps): JSX.Element {
  return (
    <span
      style={{
        height: height + "px",
        width: width + "px",
        backgroundColor: circle ? colors[icon] : "",
      }}
      className={[
        css.Container,
        className ?? "",
        circle ? css.Circle : "",
        {
          100: "",
          0: css.Nightlife,
          1: css.Restaurant,
          2: css.Shopping,
          3: css.Hotels,
          4: css.Dos,
          5: css.Donts,
        }[icon],
      ].join(" ")}
    >
      {mapping[icon]}
    </span>
  );
}
