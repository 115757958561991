import { SearchModel } from "Models/SearchModel";
import { useEffect, useState } from "react";
import css from "./RadioFilter.module.css";
import { useTranslation } from "react-i18next";

const RadioFilter = ({
  selected,
  setSelected,
  result,
}: {
  selected: "users" | "cities" | "tipLists";
  result: SearchModel | null;
  setSelected: any;
}) => {
  const { t } = useTranslation();
  const alternatives = {
    users: t("profiles"),
    cities: t("destinations"),
    tipLists: t("lists"),
  };
  const [initial, setInitial] = useState(true);

  useEffect(() => {
    setInitial(true);
  }, [result]);

  useEffect(() => {
    //Always select category with maximum results on initial load
    if (initial) {
      const argMax = Object.keys(alternatives).reduce(
        (acc, key) => {
          if (result?.[key]?.length > acc[0]) {
            return [result?.[key]?.length, key];
          }
          return acc;
        },
        [0, selected]
      );
      setSelected(argMax[1]);
      setInitial(false);
    }
  }, [result, selected, setSelected, initial]);

  return (
    <div className={css.Container}>
      {Object.entries(alternatives).map(([key, value]) => (
        <div
          onClick={() => result?.[key]?.length > 0 && setSelected(key)}
          key={key}
          className={[selected === key && css.Selected].join(" ")}
        >
          <span>{result?.[key]?.length}</span>
          {value}
        </div>
      ))}
    </div>
  );
};

export default RadioFilter;
