import React from "react";

export default function Close({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M14 2.11641L12.59 0.755859L7 6.14981L1.41 0.755859L0 2.11641L5.59 7.51035L0 12.9043L1.41 14.2649L7 8.8709L12.59 14.2649L14 12.9043L8.41 7.51035L14 2.11641Z" />
    </svg>
  );
}
