import Icons from "Components/Icons";
import UI from "Components/UI";
import { CityModel } from "Models/CityModel";
import { CountryModel } from "Models/CountryModel";
import { VenueModel } from "Models/VenueModel";
import React from "react";
import Services from "Services";
import MiscUtils from "Utils/MiscUtils";

import css from "./LocationSearch.module.css";
import { useTranslation } from "react-i18next";

type LocationSearchProps<
  T extends { query: string },
  U extends { id: string }
> = {
  list: (args: T) => Promise<[U[], number]>;
  get: (id: string) => Promise<U | null>;
  value?: U;
  onChange?: (value?: U) => void;
  nameRender: (value?: U) => string | undefined;
  className?: string;
  label: string;
};

function LocationSearch<T extends { query: string }, U extends { id: string }>({
  list,
  get,
  value,
  onChange,
  nameRender,
  className,
  label,
}: LocationSearchProps<T, U>): JSX.Element {
  const [query, setQuery] = React.useState(nameRender(value) ?? "");
  const [items, setItems] = React.useState<U[]>([]);
  const currQuery = React.useRef(0);
  const pending = React.useRef<Promise<void>>();
  const [expanded, setExpanded] = React.useState(false);
  const [focused, setFocused] = React.useState(false);

  // Value changed.
  React.useEffect(() => {
    if (!value) {
      return;
    }

    setQuery(nameRender(value) ?? "");
  }, [value, nameRender]);

  // Search for items.
  const search = React.useCallback(
    async (nextQuery: string) => {
      const now = new Date().getTime();
      currQuery.current = now;

      await pending.current;

      if (currQuery.current !== now) {
        // Another search has taken precedence.
        return;
      }

      const [nextItems] = await list({ query: nextQuery } as T);

      setItems(nextItems);
      setExpanded(focused && nextItems.length > 0);

      await MiscUtils.sleep(500);
    },
    [list, focused]
  );

  // Select item.
  const select = React.useCallback(
    async (nextValue: U) => {
      if (onChange) {
        onChange(nextValue);
        setQuery(nameRender(nextValue) ?? "");
        setExpanded(false);

        // Finalize auto complete.
        onChange((await get(nextValue.id)) ?? undefined);
      }
    },
    [get, onChange, nameRender]
  );

  return (
    <div
      className={[
        css.Container,
        className ?? "",
        expanded ? css.Expanded : "",
      ].join(" ")}
    >
      <UI.TextField
        label={label}
        icon={<Icons.Location />}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);

          if (onChange) {
            onChange(undefined);
          }
        }}
        onInput={(e) => {
          pending.current = search((e.target as HTMLInputElement).value);
        }}
        className={css.Query}
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          setExpanded(false);

          if (value === undefined) {
            setQuery("");
          }
        }}
      />

      <div className={css.Items}>
        <div style={{ padding: "30px 16px" }}>
          {items.map((item) => (
            <div
              className={css.Item}
              key={item.id}
              onMouseDown={() => select(item)}
              role="button"
              tabIndex={-1}
            >
              {nameRender(item)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

type SearchProps<T> = {
  value?: T;
  onChange: (value?: T) => void;
  className?: string;
};

function Country({
  value,
  onChange,
  className,
}: SearchProps<CountryModel>): JSX.Element {
  const { t } = useTranslation();
  return (
    <LocationSearch
      list={Services.Location.Country.list}
      get={Services.Location.Country.get}
      value={value}
      onChange={onChange}
      nameRender={(nextValue) => nextValue?.name}
      className={className}
      label={t("country")}
    />
  );
}

function City({
  value,
  onChange,
  className,
}: SearchProps<CityModel>): JSX.Element {
  const { t } = useTranslation();
  return (
    <LocationSearch
      list={Services.Location.City.list}
      get={Services.Location.City.get}
      value={value}
      onChange={onChange}
      nameRender={(nextValue) => nextValue?.fullName ?? nextValue?.name}
      className={className}
      label={t("city")}
    />
  );
}

function Venue({
  value,
  onChange,
  className,
}: SearchProps<VenueModel>): JSX.Element {
  const { t } = useTranslation();
  return (
    <LocationSearch
      list={Services.Location.Venue.list}
      get={Services.Location.Venue.get}
      value={value}
      onChange={onChange}
      nameRender={(nextValue) => nextValue?.fullName ?? nextValue?.name}
      className={className}
      label={t("venue")}
    />
  );
}

export default {
  Country,
  City,
  Venue,
};
