import { CountryModel } from "Models/CountryModel";
import { RootState } from "Store";

function get(id: string): (state: RootState) => CountryModel | null {
  return (state: RootState) => state.countries[id] ?? null;
}

function list(...ids: string[]): (state: RootState) => CountryModel[] {
  return (state: RootState) =>
    ids
      .map((id) => state.countries[id])
      .filter((country) => country !== undefined);
}

export default {
  get,
  list,
};
