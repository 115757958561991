import React from "react";

export default function FeaturedStar({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499939 24C0.499939 10.7452 11.2451 0 24.4999 0C37.7548 0 48.4999 10.7452 48.4999 24C48.4999 37.2548 37.7548 48 24.4999 48C11.2451 48 0.499939 37.2548 0.499939 24Z"
        fill="#FDB022"
      />
      <path
        d="M24.4999 14L27.5899 20.26L34.4999 21.27L29.4999 26.14L30.6799 33.02L24.4999 29.77L18.3199 33.02L19.4999 26.14L14.4999 21.27L21.4099 20.26L24.4999 14Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
