import { UserModel } from "Models/UserModel";
import ServiceUtils from "./ServiceUtils";
import UserService from "./UserService";

async function signIn(
  username: string,
  password: string
): Promise<UserModel | null> {
  try {
    const { data } = await ServiceUtils.http.post<any>("/signin", {
      username,
      password,
    });

    localStorage.setItem("access_token", data.access_token);

    const { data: user } = await ServiceUtils.http.get<UserModel>("/signin");

    return UserService.parse(user);
  } catch (err) {
    return null;
  }
}

async function externalSignUp(code: string): Promise<UserModel | null> {
  try {
    const { data } = await ServiceUtils.http.post<any>("/signup/external", {
      code,
    });

    localStorage.setItem("access_token", data.access_token);

    const { data: user } = await ServiceUtils.http.get<UserModel>("/signin");

    return UserService.parse(user);
  } catch (err) {
    localStorage.removeItem("access_token");
    return null;
  }
}

async function getAuthedUser(): Promise<UserModel | null> {
  try {
    const { data: user } = await ServiceUtils.http.get<UserModel>("/signin");

    return UserService.parse(user);
  } catch (err) {
    localStorage.removeItem("access_token");
    return null;
  }
}

function signOut() {
  localStorage.removeItem("access_token");
}

async function forgotPassword({ email }: { email: string }) {
  try {
    await ServiceUtils.http.post("/signup/forgot-password", {
      email,
    });
  } catch ({ response: { status, data } }) {
    switch (status) {
      case 400:
        throw new Error("Bad request");
      default:
        throw new Error("An unexpected error occured");
    }
  }
}

async function changePassword({
  oldPassword,
  newPassword,
}: {
  oldPassword: string;
  newPassword: string;
}) {
  try {
    var access_token = localStorage.getItem("access_token");
    await ServiceUtils.http.post("/users/change-password", {
      access_token,
      oldPassword,
      newPassword,
    });
  } catch ({ response }) {
    const { status, data } = response as any;
    switch (status) {
      case 400:
        throw new Error(String(data?.detail));
      default:
        throw new Error("An unexpected error occured");
    }
  }
}

async function confirmForgotPassword({
  email,
  password,
  confirmationCode,
}: {
  email: string;
  password: string;
  confirmationCode: string;
}) {
  try {
    await ServiceUtils.http.post("/signup/confirm-forgot-password", {
      email,
      password,
      confirmationCode,
    });
  } catch ({ response: { status, data } }) {
    throw new Error(`Error ${status}, could not reset password`);
  }
}

async function signUp({
  firstName,
  lastName,
  username,
  password,
  referral,
}: {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  referral: string | null;
}): Promise<void> {
  try {
    await ServiceUtils.http.post("/signup", {
      firstName,
      lastName,
      username,
      password,
      referral,
    });
  } catch ({ response: { status, data } }) {
    switch (status) {
      case 400:
        throw new Error("Bad request");
      case 409:
        throw new Error("E-mail address already registered");
      case 422:
        throw new Error("User referral code not found");
      default:
        throw new Error("An unexpected error occured");
    }
  }
}

async function verify(code: string): Promise<void> {
  try {
    await ServiceUtils.http.patch("/signup", { code });
  } catch ({ response: { status, data } }) {
    switch (status) {
      case 400:
        throw Error("Invalid verification code");
      default:
        throw Error("An unexpected error occured");
    }
  }
}

export default {
  signIn,
  getAuthedUser,
  signOut,
  signUp,
  forgotPassword,
  confirmForgotPassword,
  verify,
  changePassword,
  externalSignUp,
};
