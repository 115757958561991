import Config from "Config";
import MiscUtils from "Utils/MiscUtils";

async function waitForInit(): Promise<boolean> {
  try {
    /* eslint-disable */
    const ignore = google.maps;
    /* eslint-enable */

    return true;
  } catch (err) {
    await MiscUtils.sleep(20);
  }

  return waitForInit();
}

async function init(): Promise<boolean> {
  const scriptTag = document.createElement("script");
  scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${Config.GOOGLE_API_KEY}`;
  scriptTag.defer = true;
  document.head.append(scriptTag);

  return waitForInit();
}

let isInstantiated = false;

async function getMap(
  element: HTMLElement,
  center: { lat: number; lng: number }
): Promise<google.maps.Map> {
  if (!isInstantiated) {
    isInstantiated = true;
    await init();
  }

  return new google.maps.Map(element, {
    center,
    zoom: 15,
    maxZoom: 18,
    clickableIcons: false,
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: false,
    scrollwheel: false,
    styles: [{ featureType: "poi", stylers: [{ visibility: "off" }] }],
  });
}

export default {
  getMap,
};
