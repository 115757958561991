import React from "react";

export default function Move({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="12"
      height="6"
      viewBox="0 0 12 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M8.67341 2.3335H0.666748V3.66683H8.67341V5.66683L11.3334 3.00016L8.67341 0.333496V2.3335Z" />
    </svg>
  );
}
