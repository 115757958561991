import React from "react";
import { useMediaQuery } from "react-responsive";
import DesktopContextMenu from "./DesktopContextMenu";
import MobileContextMenu from "./MobileContextMenu";

type ContextMenuProps = React.PropsWithChildren<{
  className?: string;
  button: React.ReactNode;
  align?: "left" | "right";
  vAlign?: "top" | "bottom";
  showClose?: boolean;
}>;

const ContextMenu = ({
  className,
  button,
  align = "right",
  vAlign = "top",
  showClose,
  children,
}: ContextMenuProps) => {
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  if (!isPhone)
    return (
      <DesktopContextMenu
        className={className}
        button={button}
        align={align}
        vAlign={vAlign}
      >
        {children}
      </DesktopContextMenu>
    );

  if (isPhone)
    return (
      <MobileContextMenu button={button} showClose={showClose}>
        {children}
      </MobileContextMenu>
    );

  return <div></div>;
};

export default ContextMenu;
