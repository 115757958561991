import React from "react";

export default function FeaturedUniverse({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.45435 28C4.45435 14.7452 15.1995 4 28.4543 4V4C41.7092 4 52.4543 14.7452 52.4543 28V28C52.4543 41.2548 41.7092 52 28.4543 52V52C15.1995 52 4.45435 41.2548 4.45435 28V28Z"
        fill="#FFCA64"
      />
      <path
        d="M25.0443 29.51L31.8743 33.49M31.8643 22.51L25.0443 26.49M37.4543 21C37.4543 22.6569 36.1112 24 34.4543 24C32.7975 24 31.4543 22.6569 31.4543 21C31.4543 19.3431 32.7975 18 34.4543 18C36.1112 18 37.4543 19.3431 37.4543 21ZM25.4543 28C25.4543 29.6569 24.1112 31 22.4543 31C20.7975 31 19.4543 29.6569 19.4543 28C19.4543 26.3431 20.7975 25 22.4543 25C24.1112 25 25.4543 26.3431 25.4543 28ZM37.4543 35C37.4543 36.6569 36.1112 38 34.4543 38C32.7975 38 31.4543 36.6569 31.4543 35C31.4543 33.3431 32.7975 32 34.4543 32C36.1112 32 37.4543 33.3431 37.4543 35Z"
        stroke="#1D2939"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4543 48C17.4087 48 8.45435 39.0457 8.45435 28H0.454346C0.454346 43.464 12.9904 56 28.4543 56V48ZM48.4543 28C48.4543 39.0457 39.5 48 28.4543 48V56C43.9183 56 56.4543 43.464 56.4543 28H48.4543ZM28.4543 8C39.5 8 48.4543 16.9543 48.4543 28H56.4543C56.4543 12.536 43.9183 0 28.4543 0V8ZM28.4543 0C12.9904 0 0.454346 12.536 0.454346 28H8.45435C8.45435 16.9543 17.4087 8 28.4543 8V0Z"
        fill="#FFCA64"
      />
    </svg>
  );
}
