import { ModalControllerContext, NotificationContext } from "App";
import Icons from "Components/Icons";
import UI from "Components/UI";
import Spinner from "Components/UI/Spinner";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import TipListActions from "Store/Actions/TipListActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import MiscUtils from "Utils/MiscUtils";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./HandleTipsDialog.module.css";
import { useTranslation } from "react-i18next";

type HandleTipsDialogProps = {
  onClose: () => void;
  tip: TipModel;
  borrow?: boolean;
  move?: boolean;
  currentList?: string;
};

export default function HandleTipsDialog({
  tip,
  onClose,
  borrow = false,
  move = false,
  currentList = "",
}: HandleTipsDialogProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const router = useRouter();
  const { userId, listId } = router.query;

  const showNotification = React.useContext(NotificationContext);
  const { tipListDialog } = React.useContext(ModalControllerContext);
  const [tipListIds, setTipListsIds] = useState<string[]>([]);
  const tipLists = useSelector(TipListSelectors.list(...tipListIds));
  const [tipList, setTipList] = useState<TipListModel | null | undefined>(
    undefined
  );

  const [pending, setPending] = useState<string[]>([]);
  const [added, setAdded] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      if (listId) {
        const list = await TipListActions.get(dispatch, listId as string);
        setTipList(list);
      } else if (userId) {
        const list = await TipListActions.getMostPopularList(
          dispatch,
          userId as string
        );
        if (list) {
          setTipList(list);
        }
      }
    })();
  }, [dispatch, listId, router.query, userId]);

  const loadAllTipLists = React.useCallback(
    async (
      args: Parameters<typeof TipListActions.list>[1]
    ): Promise<TipListModel[]> => {
      const [nextTipLists] = await TipListActions.list(dispatch, args);

      if (nextTipLists.length < (args?.limit ?? 20)) {
        return [...nextTipLists];
      }

      return [
        ...nextTipLists,
        ...(await loadAllTipLists({
          ...args,
          offset: (args?.offset ?? 0) + 1,
        })),
      ];
    },
    [dispatch]
  );

  const toggleTip = React.useCallback(
    async (tipList: TipListModel) => {
      if (pending.includes(tipList.id)) return;
      const adding = added.includes(tipList.id) ? false : true;

      setPending((prev) => [...prev, tipList.id]);

      if (adding) {
        await TipListActions.addTips(dispatch, tipList, [tip], auth?.id ?? "");
        setAdded((prev) => [...prev, tipList.id]);
        showNotification({
          title: t("success"),
          message: (
            <>
              {t("tip-added")}
              <Link href={`/${tipList.user.slug}/list/${tipList.slug}`}>
                <a>&quot;{tipList.title}&quot;</a>
              </Link>
            </>
          ),
        });
      } else {
        await TipListActions.removeTips(
          dispatch,
          tipList.id,
          [tip],
          auth?.id ?? ""
        );
        setAdded((prev) => prev.filter((e) => e !== tipList.id));
      }
      setPending((prev) => prev.filter((e) => e !== tipList.id));
    },
    [added, auth?.id, dispatch, pending, showNotification, tip]
  );

  const moveTip = React.useCallback(
    async (tipList: TipListModel) => {
      if (!auth?.id) return;
      if (pending.includes(tipList.id)) return;
      setPending((prev) => [...prev, tipList.id]);
      await TipListActions.addTips(dispatch, tipList, [tip], auth?.id ?? "");
      await TipListActions.removeTips(
        dispatch,
        currentList ?? "",
        [tip],
        auth?.id ?? ""
      );
      setPending((prev) => prev.filter((e) => e !== tipList.id));
      onClose();
      showNotification({
        title: t("success"),
        message: (
          <>
            {t("successfully-moved-tip")}
            <Link href={`/${tipList.user.slug}/list/${tipList.slug}`}>
              <a>&quot;{tipList.title}&quot;</a>
            </Link>
          </>
        ),
      });
    },
    [auth?.id, tipList?.id, dispatch, onClose, pending, showNotification, tip]
  );

  useEffect(() => {
    if (!auth?.id) return;

    loadAllTipLists({
      limit: 100,
      offset: 0,
      filters: {
        userIds: [auth?.id],
        "!tipId": tip.id,
      },
    }).then((nextTipLists) =>
      setTipListsIds(nextTipLists.map((tipList) => tipList.id))
    );
  }, [auth?.id, loadAllTipLists, tip.id]);

  const sortedTiplists = tipLists
    .map((tipList) => {
      const distanceToVenue = MiscUtils.getDistance(
        { lat: tipList.city.latitude, lng: tipList.city.longitude },
        { lat: tip.venue?.latitude, lng: tip.venue?.longitude }
      );
      return {
        ...tipList,
        distanceToVenue: distanceToVenue?.numberDistance,
      };
    })
    .sort((a, b) =>
      a.distanceToVenue && b.distanceToVenue
        ? a.distanceToVenue - b.distanceToVenue
        : Number(a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)
    );

  return (
    <div className={css.Container}>
      <div className={css.Content}>
        <div className={css.Close}>
          <UI.Button
            variant="text"
            onClick={onClose}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        </div>
        <div className={css.Header}>
          {!move && (
            <>
              <UI.Button
                variant="outline"
                color="secondary"
                onClick={() => tipListDialog()}
              >
                {t("create-new-list")}
              </UI.Button>
              <h4>{`${t("or-lower")} ${borrow ? t("borrow-tip") : t("add")} ${t(
                "to-existing"
              )}`}</h4>
            </>
          )}
          {move && (
            <>
              <UI.Button
                variant="outline"
                color="secondary"
                onClick={() => tipListDialog()}
              >
                {t("create-new-list")}
              </UI.Button>
              <h4>
                {`${t("or-move")} "${tip.title}" ${t("from")} "${
                  tipList?.title
                }"
                 ${t("to")}`}
              </h4>
            </>
          )}
        </div>

        <div className={css.TipLists}>
          {sortedTiplists.map((tipList) => {
            const isPending = pending.includes(tipList.id);
            const isAdded = added.includes(tipList.id);

            return (
              <div
                onClick={() => (move ? moveTip(tipList) : toggleTip(tipList))}
                key={tipList.id}
                className={css.TipList}
              >
                {!isPending && (
                  <Icons.Check className={isAdded ? css.Show : css.Hide} />
                )}
                <Spinner className={isPending ? css.Show : css.Hide} />
                <div
                  className={
                    isAdded || isPending ? css.IconAnimation : undefined
                  }
                >
                  <h4>{tipList.title}</h4>
                  <h5>{tipList.city.name}</h5>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
