import React from "react";
import Link from "next/link";
import { UserModel } from "Models/UserModel";
import MiscUtils from "Utils/MiscUtils";
import accountIcon from "Assets/Icons/account.svg";
import css from "./User.module.css";
import { useTranslation } from "react-i18next";

type UserProps = {
  className?: string;
  user?: UserModel | null;
  size?: "small" | "medium" | "large";
  onNavigate?: () => void;
  displayDescription?: boolean;
};

export default function User({
  user = null,
  className,
  onNavigate,
  size = "medium",
  displayDescription: pDisplayDescription,
}: UserProps): JSX.Element | null {
  const { t } = useTranslation();
  // Default values for displaying description.
  const displayDescription =
    pDisplayDescription === undefined
      ? {
          small: false,
          medium: false,
          large: true,
        }[size]
      : pDisplayDescription;

  if (!user) {
    return null;
  }

  const profileLink = `/${user.slug}`;

  return (
    <article
      className={[
        css.Container,
        className ?? "",
        {
          small: css.Small,
          medium: css.Medium,
          large: css.Large,
        }[size],
      ].join(" ")}
    >
      <Link href={profileLink}>
        <a>
          <img
            className={css.Image}
            onClick={onNavigate}
            src={user.profileImage.thumb ?? accountIcon}
            alt={MiscUtils.getFullName(user)}
          />
        </a>
      </Link>

      <p className={css.Info}>
        <Link href={profileLink}>
          <a className={css.Name} onClick={onNavigate}>
            {MiscUtils.getFullName(user)}
          </a>
        </Link>

        <strong className={css.TipsAndLists}>
          {MiscUtils.getTipTipListCount(user)}
        </strong>

        <span className={css.MemberSince}>
          {`${t("member-since")} ${user.createdAt.toLocaleDateString("en-US", {
            month: "long",
            year: "numeric",
          })}`}
        </span>

        {displayDescription && user.description && (
          <span className={css.Description}>{user.description}</span>
        )}
      </p>
    </article>
  );
}
