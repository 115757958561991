import { PaginationModel } from "Models/PaginationModel";
import { TestimonialModel } from "Models/TestimonialModel";
import { UserModel } from "Models/UserModel";
import ServiceUtils from "./ServiceUtils";
import UserService from "./UserService";

function parse(testimonial: TestimonialModel | null): TestimonialModel | null {
  if (!testimonial) {
    return null;
  }

  const nextTestimonial: TestimonialModel = {
    ...testimonial,
    createdAt: new Date(testimonial.createdAt),
    modifiedAt: new Date(testimonial.modifiedAt),
    user: UserService.parse(testimonial.user) as UserModel,
  };

  return nextTestimonial;
}

async function get(id: string): Promise<TestimonialModel | null> {
  try {
    const { data: testimonial } = await ServiceUtils.http.get<TestimonialModel>(
      `/testimonials/${id}`
    );

    return parse(testimonial);
  } catch (err) {
    return null;
  }
}

async function list(
  args: {
    limit?: number;
    offset?: number;
  } = {}
): Promise<[TestimonialModel[], number]> {
  const params = {
    ...ServiceUtils.getPagination(args.limit, args.offset),
  };

  const {
    data: { total, data: testimonials },
  } = await ServiceUtils.http.get<PaginationModel<TestimonialModel>>(
    "/testimonials",
    { params }
  );

  return [
    testimonials.map((testimonial) => parse(testimonial)) as TestimonialModel[],
    total,
  ];
}

export default {
  parse,
  get,
  list,
};
