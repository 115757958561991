import React, { useCallback, useEffect, useState } from "react";
import Button from "../Button";
import css from "./MobileContextMenu.module.css";
import ModalPortal from "./ModalPortal";
import { useTranslation } from "react-i18next";

const MobileContextMenu = ({ children, button, showClose = true }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const [transition, setTransition] = useState(false);

  const moveUp = "translateY(100%)";

  useEffect(() => {
    setTimeout(() => {
      setTransition((prev) => !prev);
    }, 300);
  }, [expanded]);

  const scroll = useCallback((e: Event) => {
    setExpanded(false);
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", scroll, true);

    return () => {
      document.removeEventListener("scroll", scroll, true);
    };
  }, [scroll]);

  return (
    <div>
      {expanded && (
        <ModalPortal selector={"#portal"}>
          <section
            className={css.Content}
            style={{
              transform: transition ? moveUp : "",
            }}
          >
            {children}
            {showClose && (
              <Button
                onClick={() => setExpanded(false)}
                className={css.Close}
                variant="outline"
                color="secondary"
              >
                {t("close")}
              </Button>
            )}
          </section>
        </ModalPortal>
      )}
      {expanded && (
        <span
          className={css.Backdrop}
          style={{ opacity: transition ? "0" : "0.6" }}
          onClick={() => setExpanded(false)}
        />
      )}
      <span
        className={css.Button}
        onClick={() => setExpanded((prev) => !prev)}
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
      >
        {button}
      </span>
    </div>
  );
};

export default MobileContextMenu;
