import Icons from "Components/Icons";
import StarPainted from "Components/Icons/StarPainted";
import UI from "Components/UI";
import Button from "Components/UI/Button";
import { UserModel } from "Models/UserModel";
import Services from "Services";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipSelectors from "Store/Selectors/TipSelectors";
import UserSelectors from "Store/Selectors/UserSelectors";
import MiscUtils from "Utils/MiscUtils";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import css from "./BorrowersDialog.module.css";

type BorrowersDialogProps = {
  onClose: () => void;
  user?: UserModel;
};

export default function BorrowersDialog({
  onClose,
  user,
}: BorrowersDialogProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [view, setView] = React.useState<"users" | "tips">("users");
  const [selectedUserId, setSelectedUserId] = React.useState<string>();
  const selectedUser = useSelector(UserSelectors.get(selectedUserId ?? ""));
  const [userIds, setUserIds] = React.useState<string[]>([]);
  const users = useSelector(UserSelectors.list(...userIds));
  const [userCount, setUserCount] = React.useState(0);
  const [selectedUserTipIds, setSelectedUserTipIds] = React.useState<string[]>(
    []
  );
  const selectedUserTips = useSelector(
    TipSelectors.list(...(selectedUserTipIds ?? []))
  );
  const [borrowedTipCount, setBorrowedTipCount] = React.useState<{
    [key: string]: number;
  }>({});
  const tipsRef = React.useRef<HTMLDivElement>(null);
  const usersRef = React.useRef<HTMLDivElement>(null);
  const userId = user?.id;

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  // Load more tips function.
  const tipFuncInitialized = React.useRef(0);
  const loadMoreTips = React.useMemo(() => {
    const now = new Date().getTime();
    tipFuncInitialized.current = now;

    if (!(userId && selectedUserId)) {
      return () => {};
    }

    return Services.Tip.getLoadMoreFunc(
      ([nextTips, nextTotal]) => {
        if (tipFuncInitialized.current === now) {
          setSelectedUserTipIds(nextTips.map((tip) => tip.id));
          setBorrowedTipCount((current) => ({
            ...current,
            [selectedUserId]: nextTotal,
          }));
        }
      },
      dispatch,
      {
        limit: 8,
        filters: { userIds: [userId], borrowerUserIds: [selectedUserId] },
        withTipListId: true,
      }
    );
  }, [userId, selectedUserId, dispatch]);

  // Load more users function.
  const userFuncInitialized = React.useRef(0);
  const loadMoreUsers = React.useMemo(() => {
    const now = new Date().getTime();
    userFuncInitialized.current = now;

    if (!userId) {
      return () => {};
    }

    return Services.User.getLoadMoreFuncFollowersBorrowers(
      async ([nextUsers, nextTotal]) => {
        setUserIds(Object.values(nextUsers).map((value) => value.user.id));
        setUserCount(nextTotal);

        const nextBorrowedTipCount: { [key: string]: number } = {};
        Object.values(nextUsers).forEach((value) => {
          nextBorrowedTipCount[value.user.id] = value.tipCount ?? 0;
        });

        setBorrowedTipCount((current) => ({
          ...current,
          ...nextBorrowedTipCount,
        }));
      },
      dispatch,
      "borrowers",
      { limit: 8, userId }
    );
  }, [userId, dispatch]);

  // Set view to tips.
  const viewTipLists = React.useCallback((nextUser: UserModel) => {
    setSelectedUserId(nextUser.id);
    setSelectedUserTipIds([]);
    setView("tips");
  }, []);

  // Set view to users.
  const viewUsers = React.useCallback(() => {
    setSelectedUserId(undefined);
    setView("users");
  }, []);

  // Viewing tips a user has borrowed.
  React.useEffect(() => {
    if (view === "tips" && selectedUserId && userId) {
      loadMoreTips();
    }

    const ref = tipsRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreTips();
      }
    };

    ref.addEventListener("scroll", onScroll);

    return () => ref.removeEventListener("scroll", onScroll);
  }, [view, selectedUserId, userId, loadMoreTips]);

  // Viewing borrowing users.
  React.useEffect(() => {
    if (view === "users") {
      loadMoreUsers();
    }

    const ref = usersRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreUsers();
      }
    };

    ref.addEventListener("scroll", onScroll);

    return () => ref.removeEventListener("scroll", onScroll);
  }, [view, loadMoreUsers]);

  let ownerLabel = "my";

  if (user && user.id !== auth?.id) {
    ownerLabel = MiscUtils.formatNameApos(user.firstName);
  }

  const [expanded, setExpanded] = useState(false);
  const [transition, setTransition] = useState(false);
  const moveUp = "translateY(-100%)";

  useEffect(() => {
    setTransition((prev) => !prev);
  }, [expanded]);

  return (
    <section
      className={[css.Container, view === "tips" ? css.TipView : ""].join(" ")}
      style={
        isPhone
          ? {
              transform: transition ? moveUp : "",
            }
          : {}
      }
    >
      <div className={css.Header}>
        {view === "tips" && (
          <UI.Button
            variant="text"
            onClick={viewUsers}
            className={css.BackButton}
          >
            <Icons.BackArrow />
          </UI.Button>
        )}
        <div className={css.DialogTitle}>
          {view === "users" && `${t("who-borrow")} ${ownerLabel} ${t("tips")}`}
          {view === "tips" &&
            `${MiscUtils.capitalize(ownerLabel)} ${t("tips-borrowed-by")} ${
              selectedUser?.firstName
            } ${selectedUser?.lastName}`}
        </div>
        {!isPhone && (
          <UI.Button
            variant="text"
            onClick={onClose}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        )}
      </div>

      {/* Borrowers view. */}
      {view === "users" && (
        <div className={css.Content} ref={usersRef}>
          <div className={css.Items}>
            {users.map((nextUser) => (
              <article className={css.User} key={nextUser.id}>
                <Link href={`/${nextUser.slug}`}>
                  <a onClick={onClose}>
                    {nextUser.profileImage.thumb ? (
                      <img
                        src={nextUser.profileImage.thumb}
                        alt=""
                        className={css.Image}
                      />
                    ) : (
                      <Icons.Avatar className={css.Image} />
                    )}
                  </a>
                </Link>

                <p className={css.Info}>
                  <Link href={`/${nextUser.slug}`}>
                    <a className={css.Name} onClick={onClose}>
                      {`${nextUser.firstName} ${nextUser.lastName}`}
                    </a>
                  </Link>

                  <UI.Button
                    variant="text"
                    color="secondary"
                    className={css.BorrowedCount}
                    onClick={() => viewTipLists(nextUser)}
                  >
                    <span>
                      <Icons.Following />
                      {`${t("borrowing")} ${
                        borrowedTipCount[nextUser.id] ?? 0
                      }`}
                    </span>
                  </UI.Button>
                  <span className={css.Description}>
                    {nextUser.description}
                  </span>
                </p>
              </article>
            ))}
          </div>
        </div>
      )}
      {/* Tip view. */}
      {view === "tips" && (
        <div className={css.Content} ref={tipsRef}>
          <div className={css.Items}>
            {selectedUserTips.map((tip) => (
              <div key={tip.id} className={css.TipContainer}>
                <Link
                  href={`/${tip.user.slug}/list/${tip.tipListSlug}?tipId=${tip.slug}`}
                >
                  <img
                    src={tip.image.medium}
                    alt=""
                    className={css.Image}
                    onClick={onClose}
                  />
                </Link>
                <div className={css.TipName}>
                  <Link
                    href={`/${tip.user.slug}/list/${tip.tipListSlug}?tipId=${tip.slug}`}
                  >
                    <span onClick={onClose} className={css.TipNameTitle}>
                      {tip.title}
                    </span>
                  </Link>
                  <span className={css.UserByText}>
                    {t("by")}
                    {tip.user.firstName}
                    <StarPainted className={css.BorrowerIcon} />
                    {tip.borrowerCount > 1 ? t("borrowers") : t("borrower")}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {isPhone && (
        <div className={css.Close}>
          <Button
            onClick={() => {
              setExpanded((prev) => !prev);
              onClose();
            }}
            variant="outline"
            color="secondary"
            style={{ width: "100%" }}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </section>
  );
}
