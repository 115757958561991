import Icons from "Components/Icons";
import React from "react";
import UI from "Components/UI";
import commonCss from "../SignInSignUpDialog.module.css";
import Hooks from "Hooks";
import { useDispatch } from "react-redux";
import AuthActions from "Store/Actions/AuthActions";
import { signedIn } from "Store/Reducers/AuthReducer";
import { useRouter } from "next/dist/client/router";
import { useTranslation } from "react-i18next";

type Data = {
  username: string;
  password: string;
  invalidCredentials: boolean;
};

type SignInProps = {
  onClose: () => void;
  goToSignUp: () => void;
  goToForgotPassword: () => void;
};

export default function SignIn({
  onClose,
  goToSignUp,
  goToForgotPassword,
}: SignInProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(false);

  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      username: "",
      password: "",
      invalidCredentials: false,
    },
    (nextData) => ({
      username:
        nextData.username.length === 0
          ? (t("enter-username") as string)
          : undefined,
      password:
        nextData.password.length === 0
          ? (t("enter-password") as string)
          : undefined,
      invalidCredentials: nextData.invalidCredentials
        ? (t("invalid-credentials") as string)
        : undefined,
    })
  );

  const router = useRouter();

  const submit = React.useCallback(
    async (nextData: Data) => {
      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        const nextUser = await AuthActions.signIn(
          nextData.username,
          nextData.password
        );
        if (!nextUser) {
          valid({ invalidCredentials: true });
          return;
        }
        dispatch(signedIn(nextUser));

        onClose();
      } catch (err) {
        //
      } finally {
        setPending(false);
      }
    },
    [valid, dispatch, onClose, router]
  );

  return (
    <section className={commonCss.Container}>
      <div className={commonCss.Header}>
        <h4>{t("sign-in")}</h4>

        <UI.Button
          variant="text"
          onClick={onClose}
          className={commonCss.CloseButton}
        >
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={commonCss.Content}>
        <form
          className={commonCss.Form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();

            if (!pending) {
              submit(data);
            }
          }}
        >
          <div className={commonCss.Input}>
            <UI.TextField
              autoFocus
              tabIndex={0}
              type="email"
              label={t("email")}
              value={data.username}
              onChange={(e) =>
                change({
                  username: e.target.value,
                  invalidCredentials: false,
                })
              }
            />
            <UI.ValidationError>{errors.username}</UI.ValidationError>
          </div>

          <div className={commonCss.Input}>
            <UI.TextField
              tabIndex={0}
              type="password"
              label={t("password")}
              value={data.password}
              onChange={(e) =>
                change({
                  password: e.target.value,
                  invalidCredentials: false,
                })
              }
            />
            <UI.ValidationError>
              {errors.password ?? errors.invalidCredentials}
            </UI.ValidationError>
            <div className={commonCss.Forgot}>
              <UI.Button
                variant="text"
                color="link"
                type="button"
                onClick={goToForgotPassword}
              >
                {t("forgot")}
              </UI.Button>
            </div>
          </div>

          <UI.Button color="primary" tabIndex={0}>
            {pending ? t("signing-in") : t("sign-in")}
          </UI.Button>
        </form>

        <h6 className={commonCss.LightText}>{t("or")}</h6>
        <div className={commonCss.Options}>
          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              router.push(
                "https://auth.worldlobby.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://worldlobby.com/external&response_type=CODE&client_id=5rbv9msjc35t765o7slu951r4s&scope=email openid profile aws.cognito.signin.user.admin"
              );
            }}
          >
            <Icons.Google className={commonCss.OptionsIcon} />
            {t("signin-google")}
          </UI.Button>

          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              router.push(
                "https://auth.worldlobby.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://worldlobby.com/external&response_type=CODE&client_id=5rbv9msjc35t765o7slu951r4s&scope=email openid profile aws.cognito.signin.user.admin"
              );
            }}
          >
            <Icons.Facebook className={commonCss.OptionsIcon} />
            {t("signin-facebook")}
          </UI.Button>
        </div>

        <div className={commonCss.Navigation}>
          <span className={commonCss.LightText}>{t("no-account")} </span>
          <UI.Button variant="text" color="link" onClick={goToSignUp}>
            {t("create-account")}
          </UI.Button>
        </div>
      </div>
    </section>
  );
}
