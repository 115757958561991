import React from "react";

export default function PhoneNew({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#F2F4F7"
      />
      <path
        d="M18.54 17C18.6 17.89 18.75 18.76 18.99 19.59L17.79 20.79C17.38 19.59 17.12 18.32 17.03 17H18.54ZM28.4 29.02C29.25 29.26 30.12 29.41 31 29.47V30.96C29.68 30.87 28.41 30.61 27.2 30.21L28.4 29.02ZM19.5 15H16C15.45 15 15 15.45 15 16C15 25.39 22.61 33 32 33C32.55 33 33 32.55 33 32V28.51C33 27.96 32.55 27.51 32 27.51C30.76 27.51 29.55 27.31 28.43 26.94C28.33 26.9 28.22 26.89 28.12 26.89C27.86 26.89 27.61 26.99 27.41 27.18L25.21 29.38C22.38 27.93 20.06 25.62 18.62 22.79L20.82 20.59C21.1 20.31 21.18 19.92 21.07 19.57C20.7 18.45 20.5 17.25 20.5 16C20.5 15.45 20.05 15 19.5 15Z"
        fill="#475467"
      />
    </svg>
  );
}
