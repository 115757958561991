import React from "react";

export default function Followers({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.50001 7.95835C4.55001 7.95835 0.666672 8.93335 0.666672 10.875V12.3334H12.3333V10.875C12.3333 8.93335 8.45001 7.95835 6.50001 7.95835ZM2.61667 10.6667C3.31667 10.1834 5.00834 9.62502 6.50001 9.62502C7.99167 9.62502 9.68334 10.1834 10.3833 10.6667H2.61667ZM6.50001 6.50002C8.10834 6.50002 9.41667 5.19169 9.41667 3.58335C9.41667 1.97502 8.10834 0.666687 6.50001 0.666687C4.89167 0.666687 3.58334 1.97502 3.58334 3.58335C3.58334 5.19169 4.89167 6.50002 6.50001 6.50002ZM6.50001 2.33335C7.19167 2.33335 7.75001 2.89169 7.75001 3.58335C7.75001 4.27502 7.19167 4.83335 6.50001 4.83335C5.80834 4.83335 5.25001 4.27502 5.25001 3.58335C5.25001 2.89169 5.80834 2.33335 6.50001 2.33335ZM12.3667 8.00836C13.3333 8.70835 14 9.64169 14 10.875V12.3334H17.3333V10.875C17.3333 9.19169 14.4167 8.23336 12.3667 8.00836ZM11.5 6.50002C13.1083 6.50002 14.4167 5.19169 14.4167 3.58335C14.4167 1.97502 13.1083 0.666687 11.5 0.666687C11.05 0.666687 10.6333 0.77502 10.25 0.958354C10.775 1.70002 11.0833 2.60835 11.0833 3.58335C11.0833 4.55835 10.775 5.46669 10.25 6.20835C10.6333 6.39169 11.05 6.50002 11.5 6.50002Z" />
    </svg>
  );
}
