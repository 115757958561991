export default function GetMarkerEncoded(categoryId, active) {
  const height = active ? 76 : 44;
  const width = active ? 56 : 32;
  const activated = active ? "active" : "inactive";
  const colors = {
    0: "A11043",
    1: "0086C9",
    2: "DD2590",
    3: "7A5AF8",
    4: "12B76A",
    5: "D92D20",
    100: "12B76A",
  };

  const markerContainer = {
    inactive: `%3E%3Cpath d='M32 16c0 8.837-14.571 27.429-16 27.429-1.429 0-16-18.592-16-27.429S7.163 0 16 0s16 7.163 16 16Z' fill='%23${colors[categoryId]}'`,
    active: `%3E%3Cpath d='M55 28c0 3.64-1.515 8.408-3.93 13.602-2.402 5.165-5.639 10.636-8.965 15.644-3.326 5.005-6.726 9.526-9.444 12.789-1.36 1.633-2.538 2.936-3.44 3.823-.455.446-.82.768-1.093.972-.048.036-.09.066-.128.091a3.631 3.631 0 0 1-.128-.091 11.524 11.524 0 0 1-1.092-.972c-.904-.887-2.08-2.19-3.441-3.823-2.718-3.263-6.118-7.783-9.443-12.79-3.327-5.007-6.564-10.478-8.966-15.643C2.515 36.408 1 31.64 1 28 1 13.088 13.088 1 28 1s27 12.088 27 27ZM27.816 75.03c0-.001.003-.003.01-.005a.056.056 0 0 1-.01.004Zm.358-.005.01.004a.07.07 0 0 1-.01-.004Z' fill='%23FCFCFD' stroke='%23${colors[categoryId]}' strokeWidth='2'`,
  };

  const categoryIdMap = {
    0: "nightlife",
    1: "restaurant",
    2: "shopping",
    3: "hotels",
    4: "dos",
    5: "donts",
    100: "dos",
  };

  const categoryIcons = {
    nightlife: {
      path: `d='M0 0V4C0 5.98 1.44 7.62 3.33333 7.94V10.6667H1.33333V12H6.66667V10.6667H4.66667V7.94C6.56 7.62 8 5.98 8 4V0H0ZM4 6.66667C2.76 6.66667 1.72667 5.81333 1.42667 4.66667H6.57333C6.27333 5.81333 5.24 6.66667 4 6.66667ZM6.66667 3.33333H1.33333V1.33333H6.66667V3.33333Z'`,
      offset: { x: 11.5, y: 10 },
      scale: 1,
    },
    restaurant: {
      path: `d='m13.4 16.893 1.887-1.886-4.68-4.674a2.672 2.672 0 0 0 0 3.774l2.793 2.786Zm4.52-1.206c1.02.473 2.453.14 3.513-.92 1.274-1.274 1.52-3.1.54-4.08-.973-.974-2.8-.734-4.08.54-1.06 1.06-1.393 2.493-.92 3.513l-6.506 6.507.94.94L16 17.607l4.587 4.586.94-.94-4.587-4.586.98-.98Z'`,
      offset: { x: 0, y: 0 },
      scale: 1,
    },
    shopping: {
      path: `d='M14 4h-2c0-2.21-1.79-4-4-4S4 1.79 4 4H2C.9 4 0 4.9 0 6v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2ZM8 2c1.1 0 2 .9 2 2H6c0-1.1.9-2 2-2Zm6 16H2V6h2v2c0 .55.45 1 1 1s1-.45 1-1V6h4v2c0 .55.45 1 1 1s1-.45 1-1V6h2v12Z'`,
      offset: { x: 12, y: 10 },
      scale: 0.8,
    },
    hotels: {
      path: `d='M6 9C7.66 9 9 7.66 9 6C9 4.34 7.66 3 6 3C4.34 3 3 4.34 3 6C3 7.66 4.34 9 6 9ZM6 5C6.55 5 7 5.45 7 6C7 6.55 6.55 7 6 7C5.45 7 5 6.55 5 6C5 5.45 5.45 5 6 5ZM18 2H10V10H2V0H0V15H2V12H20V15H22V6C22 3.79 20.21 2 18 2ZM20 10H12V4H18C19.1 4 20 4.9 20 6V10Z'`,
      offset: { x: 9, y: 15 },
      scale: 0.8,
    },
    dos: {
      path: `d='M14 5.333H9.793l.634-3.046.02-.214c0-.273-.114-.526-.294-.706l-.706-.7L5.06 5.06c-.247.24-.393.573-.393.94v6.667C4.667 13.4 5.267 14 6 14h6c.553 0 1.027-.333 1.227-.813l2.013-4.7c.06-.154.093-.314.093-.487V6.667c0-.734-.6-1.334-1.333-1.334ZM14 8l-2 4.667H6V6l2.893-2.893-.74 3.56H14V8ZM.667 6h2.666v8H.667V6Z'`,
      offset: { x: 8, y: 8 },
      scale: 1,
    },
    donts: {
      path: `d='M14 0H5C4.17 0 3.46 0.5 3.16 1.22L0.14 8.27C0.05 8.5 0 8.74 0 9V11C0 12.1 0.9 13 2 13H8.31L7.36 17.57L7.33 17.89C7.33 18.3 7.5 18.68 7.77 18.95L8.83 20L15.42 13.41C15.78 13.05 16 12.55 16 12V2C16 0.9 15.1 0 14 0ZM14 12L9.66 16.34L11 11H2V9L5 2H14V12ZM18 0H22V12H18V0Z'`,
      offset: { x: 9, y: 15 },
      scale: 0.8,
    },
  };

  const boilerplateStart = `data:image/svg+xml, %3Csvg width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' xmlns='http://www.w3.org/2000/svg'`;

  const boilerplateEnd = "/%3E%3C/svg%3E";

  const { path, offset, scale } = categoryIcons[categoryIdMap[categoryId]];

  const output = `
  ${boilerplateStart} 
  ${markerContainer[activated]} 
  /%3E%3Cpath 
  ${`transform="scale(${active ? scale * 1.75 : scale}) translate(${offset.x},${
    offset.y
  })"`}
  ${path}
  ${active ? `fill='%23${colors[categoryId]}'` : `fill='white'`}
  ${boilerplateEnd}`;

  return output;
}
