import { NotificationContext } from "App";
import Icons from "Components/Icons";
import Title from "Components/Icons/Title";
import ImageDrop from "Components/ImageDrop";
import UI from "Components/UI";
import { useModal } from "Components/UI/Modal";
import globalCss from "Global.module.css";
import Hooks from "Hooks";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import TipListActions from "Store/Actions/TipListActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import css from "./TipListDialog.module.css";

type Data = Partial<
  Pick<TipListModel, "title" | "description" | "city" | "imageId"> & {
    duplicateTitle: boolean;
    imageData: string;
  }
>;

type TipListDialogProps = {
  onClose: () => void;
  tipList?: TipListModel;
  tip?: TipModel;
  saveCallback?: (tipList: TipListModel) => void;
};

export default function TipListDialog({
  tipList,
  tip,
  onClose,
  saveCallback,
}: TipListDialogProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [changeImg, setChangeImg] = React.useState(false);
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    { ...(tipList ?? {}), duplicateTitle: false },
    (nextData) => ({
      title:
        (nextData.title ?? "").trim().length === 0
          ? (t("enter-title") as string)
          : undefined,
      city: !nextData.city ? (t("select-city") as string) : undefined,
      duplicateTitle: nextData.duplicateTitle
        ? (t("already-exists-list-title") as string)
        : undefined,
      imageData:
        !(nextData.imageData || nextData.imageId) && changeImg
          ? (t("select-image") as string)
          : undefined,
    })
  );
  const router = useRouter();

  const tipListId = tipList?.id;
  const showNotification = React.useContext(NotificationContext);
  Hooks.usePreventReloadingAndClosingBrowser();

  // Save
  const [pending, setPending] = React.useState(false);
  const save = React.useCallback(
    async (nextData: Data, nextTipListId: string | undefined) => {
      if (pending) {
        return;
      }

      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        let nextTipList: TipListModel | null = null;

        let nextImageData: string | null | undefined = nextData.imageData;

        if (!nextImageData) {
          // Set to null if the image has been deleted.
          nextImageData = nextData.imageId ? undefined : null;
        }

        if (!nextTipListId) {
          nextTipList = await TipListActions.create(
            dispatch,
            nextData.title ?? null,
            nextData.description ?? "",
            nextData.city?.id ?? null,
            nextData.imageData ?? null
          );

          router.push(`/${nextTipList.user.slug}/list/${nextTipList.slug}`);
        } else {
          nextTipList = await TipListActions.update(
            dispatch,
            nextTipListId,
            nextData.title ?? null,
            nextData.description ?? "",
            nextData.city?.id ?? null,
            nextImageData ?? undefined
          );
        }

        if (tip?.id) {
          await TipListActions.addTips(
            dispatch,
            nextTipList,
            [tip],
            auth?.id ?? ""
          );

          showNotification({
            title: t("success"),
            message: (
              <>
                {t("tip-added")}
                <Link
                  href={`/${nextTipList.user.slug}/list/${nextTipList.slug}`}
                >
                  <a>{nextTipList.title}</a>
                </Link>
              </>
            ),
          });
        }

        showNotification({
          title: t("success"),
          message: `${t("your")}${!nextTipListId ? t("new") : ""} ${t(
            "list-has-been-published"
          )}`,
          timeoutMs: 4000,
        });

        if (saveCallback) {
          saveCallback(nextTipList);
        }

        onClose();
      } catch (err: any) {
        if (err.message === "409") {
          valid({ duplicateTitle: true });
        } else {
          showNotification({
            title: t("unexpected-error"),
            type: "error",
          });
        }
      } finally {
        setPending(false);
      }
    },
    [
      pending,
      dispatch,
      history,
      tip,
      onClose,
      auth,
      valid,
      showNotification,
      saveCallback,
    ]
  );

  // Cancel.
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message={t("discard-changes")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        onClose();
      }}
    />
  );

  // Delete
  const [deleteDialog, showDeleteDialog, hideDeleteDialog] = useModal(
    <UI.ConfirmDialog
      message={t("confirm-delete-list")}
      confirmLabel={t("yes")}
      cancelLabel={t("no")}
      onCancel={() => hideDeleteDialog()}
      onConfirm={async () => {
        if (!tipList) {
          return;
        }

        try {
          TipListActions.remove(dispatch, tipList);

          await hideDeleteDialog();
          showNotification({
            title: t("list-deleted"),
            type: "error",
          });
          router.push(`/${auth?.slug}`);
          onClose();
        } catch (err) {
          showNotification({ title: t("something-went-wrong"), type: "error" });
          hideDeleteDialog();
        }
      }}
    />
  );

  const previousData = Hooks.usePreviousState(data);

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (
      previousData === undefined ||
      JSON.stringify(previousData) === JSON.stringify(data)
    ) {
      onClose();
      return;
    }
    showConfirmDialog();
  }, [data, onClose, previousData, showConfirmDialog]);

  const title = data.title ?? "";
  const description = data.description ?? "";
  return (
    <div className={css.Container}>
      {confirmDialog}
      {deleteDialog}

      <div className={[globalCss.Content, css.Content].join(" ")}>
        <h2 className={css.Title}>
          <span>
            {`${tipListId === undefined ? t("create-new") : t("edit")} ${t(
              "list"
            )}`}
          </span>

          <UI.Button
            variant="text"
            onClick={cancel}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        </h2>

        <div className={css.Fields}>
          <div>
            <UI.LocationSearch.City
              className={css.Input}
              value={data.city ?? undefined}
              onChange={async (nextValue) => {
                change({
                  city: nextValue,
                });
              }}
            />
            <UI.ValidationError>{errors.city}</UI.ValidationError>
          </div>

          <div>
            <UI.TextField
              label={t("title")}
              className={css.Input}
              value={title}
              icon={<Title />}
              onChange={(e) =>
                change({ title: e.target.value, duplicateTitle: false })
              }
              maxLength={60}
            />
            <span className={css.CharCounter}>{`${title.length}/60`}</span>
            <UI.ValidationError>
              {errors.title ?? errors.duplicateTitle}
            </UI.ValidationError>
          </div>

          <div>
            <UI.MultiLineField
              label={t("description")}
              className={css.Input}
              value={description}
              onChange={(e) => change({ description: e.target.value })}
              maxLength={400}
              style={{ minHeight: 130 }}
            />
            <span
              className={css.CharCounter}
            >{`${description.length}/400`}</span>
            <UI.ValidationError>{errors.description}</UI.ValidationError>
          </div>

          {(data.imageId != undefined || changeImg) && (
            <ImageDrop
              data={data}
              setChangeImg={setChangeImg}
              errors={errors}
              onChange={change}
              altText={t("tiplist-cover")}
            />
          )}

          <div
            className={
              tipListId === undefined ? css.ActionsCreate : css.ActionsEdit
            }
          >
            <div>
              <UI.Button
                size="big"
                onClick={cancel}
                color="secondary"
                variant="outline"
              >
                {t("cancel")}
              </UI.Button>
            </div>

            {tipListId != undefined && (
              <div className={css.Delete}>
                <UI.Button
                  size="big"
                  onClick={showDeleteDialog}
                  color="delete"
                  variant="outline"
                >
                  {t("delete")}
                </UI.Button>
              </div>
            )}

            <div>
              <UI.Button size="big" onClick={() => save(data, tipListId)}>
                {tipListId && (pending ? t("saving") : t("save-edit"))}
                {!tipListId && (pending ? t("creating") : t("create-list"))}
              </UI.Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
