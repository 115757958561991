import React, { useState } from "react";
import ReactDOM from "react-dom";
import MiscUtils from "Utils/MiscUtils";

import css from "./Modal.module.css";
import { useMediaQuery } from "react-responsive";

type ModalProps = React.PropsWithChildren<{
  open: boolean;
  transitionTimeMs?: number;
  padded?: boolean;
  showBackdrop?: boolean;
}>;

export default function Modal({
  children,
  open,
  transitionTimeMs = 300,
  padded = true,
  showBackdrop = true,
}: ModalProps): JSX.Element | null {
  const selfRef = React.useRef<HTMLDivElement>(null);
  const currTransition = React.useRef<number>();
  const initialStyle = React.useRef<React.CSSProperties>({
    transition: `opacity ${transitionTimeMs}ms`,
    opacity: open ? "1" : "0",
  });
  const [show, setShow] = React.useState(open);
  const [container, setContainer] = useState<HTMLDivElement>();

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  React.useEffect(() => {
    if (open) {
      let container = document.querySelector<HTMLDivElement>(
        `.${css.Container}`
      );
      if (!container) {
        container = document.createElement("div");
        container.className = css.Container;
        document.body.append(container);
      }
      setContainer(container);
    }
  }, [open]);

  React.useEffect(() => {
    // Prevent scrolling while modals are showing.
    if (container) {
      const visible = container.children.length > 0;
      const { style, clientWidth } = document.body;
      const { innerWidth } = window;

      style.marginRight = visible ? `${innerWidth - clientWidth}px` : "";
      style.overflow = visible ? "hidden" : "";
    }
  }, [show, container]);

  React.useEffect(() => {
    // Update transition time if prop changes.
    if (selfRef.current) {
      selfRef.current.style.transition = `opacity ${transitionTimeMs}ms`;
    }
  }, [transitionTimeMs]);

  React.useEffect(() => {
    // Animate modal.
    (async () => {
      currTransition.current = new Date().getTime();
      const thisTransition = currTransition.current;

      if (open) {
        setShow(true);
        await MiscUtils.sleep(5);
      }

      if (!selfRef.current) {
        return;
      }

      const { style } = selfRef.current;

      if (open) {
        if (thisTransition !== currTransition.current) {
          return;
        }

        style.opacity = "1";
      } else {
        style.opacity = "0";
        await MiscUtils.sleep(transitionTimeMs);
        if (thisTransition !== currTransition.current) {
          return;
        }

        setShow(false);
      }
    })();
  }, [open, transitionTimeMs]);

  const modal = (
    <div
      className={css.BackDrop}
      style={{
        ...initialStyle.current,
        padding: padded && !isPhone ? 10 : 0,
        backgroundColor: showBackdrop && isPhone ? "var(--color-grey100)" : "",
      }}
      ref={selfRef}
    >
      {children}
    </div>
  );

  return show && container != undefined
    ? ReactDOM.createPortal(modal, container)
    : null;
}
