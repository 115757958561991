import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import SquaredBox from "Components/Icons/SquareBox";
import css from "./Tooltip.module.css";

export default function Tooltip({
  children,
  selector,
  coord,
  isOn,
  boxPosition = css.BoxPosition,
}) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, [selector]);

  return mounted
    ? createPortal(
        <div
          className={css.ExtraText}
          style={{ ...coord, display: isOn ? "flex" : "none", zIndex: 99999 }}
        >
          <SquaredBox className={[css.SquaredBox, boxPosition].join(" ")} />
          <p>{children}</p>
        </div>,
        document.querySelector(selector)
      )
    : null;
}
