import Icons from "Components/Icons";
import Category from "Components/Icons/Category";
import Followers from "Components/Icons/Followers";
import List from "Components/Icons/List";
import Star from "Components/Icons/Star";
import Tip from "Components/Icons/Tip";
import Tooltip from "Components/Tooltip";
import { CityModel } from "Models/CityModel";
import { TipListModel } from "Models/TipListModel";
import { TipModel } from "Models/TipModel";
import { UserModel } from "Models/UserModel";
import React, { memo, ReactNode, useRef, useState } from "react";
import MiscUtils from "Utils/MiscUtils";
import css from "./ListItem.module.css";
import { useTranslation } from "react-i18next";

type InfoType = [ReactNode | null, string, string?][];

type ListItemType = {
  title: string;
  image: string;
  Info: InfoType;
  subtitle: string;
};

export const ListItem = ({
  value,
  showImage = true,
  showCity = true,
  showAuthor = false,
  showSubtitle = false,
  maxWidth = 180,
  className,
}: {
  value: any;
  showImage?: boolean;
  showCity?: boolean;
  showAuthor?: boolean;
  showSubtitle?: boolean;
  maxWidth?: number | undefined;
  className?: string;
}) => {
  const ListItem: Partial<ListItemType> = {};
  const { t } = useTranslation();

  if (value.type === "TIP") {
    value = value as TipModel;

    ListItem.title = value.title || "";
    ListItem.image = value.image.thumb;

    let info: InfoType = [];

    info.push([
      <Category key={value.id} icon={value?.category?.icon ?? 100} />,
      value.category?.title || "",
    ]);
    info.push([
      <Star key={value.id} />,
      String(value.borrowerCount) || "",
      "borrower",
    ]);
    ListItem.Info = info;
  } else if (value.type === "TIP_LIST") {
    value = value as TipListModel;

    ListItem.title = value.title || "";
    ListItem.image = value.image.thumb || "noImageTipList";

    let info: InfoType = [];

    showAuthor &&
      info.push([
        null,
        t("by") + value.user.firstName + " " + value.user.lastName || "",
      ]);
    showCity && info.push([null, value.city.name || ""]);
    info.push([
      <Followers key={1} />,
      String(value.followerCount) || "",
      "follower",
    ]);
    info.push([<Tip key={2} />, String(value.tipCount) || "", "tip"]);

    ListItem.Info = info;
  } else if (value.type === "USER") {
    value = value as UserModel;

    ListItem.title = value.firstName + " " + value.lastName;
    ListItem.image = value.profileImage.thumb || "noImageUser";
    ListItem.subtitle = value.description || "";

    let info: InfoType = [];
    info.push([
      <List key={0} />,
      MiscUtils.quantify("list", value.tipListCount),
    ]);
    info.push([<Followers key={0} />, String(value.followerCount)]);
    info.push([<Star key={0} />, String(value.borrowerCount)]);

    ListItem.Info = info;
  } else if (value.type === "CITY") {
    value = value as CityModel;
    ListItem.title = value.name;
    ListItem.image = value.image.thumb;

    let info: InfoType = [];
    info.push([
      <List key={0} />,
      MiscUtils.quantify("list", value.tipListCount),
    ]);
    info.push([<Tip key={0} />, MiscUtils.quantify("tip", value.tipCount)]);
    ListItem.Info = info;
  }

  const ImageType = () => {
    if (ListItem.image === "noImageUser") {
      return <Icons.Avatar className={css.Image} />;
    } else if (ListItem.image === "noImageTipList") {
      return (
        <div className={css.ImageWrapper}>
          <Icons.AddPhoto className={css.ImageTipList} />
        </div>
      );
    } else {
      return <img src={ListItem.image} alt="" className={css.Image} />;
    }
  };

  return (
    <div
      className={[
        css.Item,
        value.type === "USER" && css.ItemUser,
        className ?? "",
      ].join(" ")}
      style={{
        height: showSubtitle ? "84px" : "63px",
      }}
    >
      <div className={css.ItemFlex}>
        {showImage && (
          <div className={css.ImageContainer}>
            {ImageType()}
            <span className={css.Image} />
          </div>
        )}

        <div>
          <div className={css.InfoColumn}>
            <h5 style={{ maxWidth: maxWidth }}>{ListItem.title}</h5>
            <div className={css.InfoRow}>
              {ListItem.Info?.map((infoItem, index) => (
                <ListItemEntry infoItem={infoItem} index={index} key={index} />
              ))}
            </div>
          </div>
          {showSubtitle && (
            <div className={css.Subtitle}>{ListItem.subtitle}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const ListItemEntry = ({ infoItem, index }) => {
  const { t, i18n } = useTranslation();
  const refOne = useRef<HTMLDivElement>(null);
  const [coord, setCoord] = useState({ left: 0, top: 0 });
  const [isOn, setOn] = useState(false);

  const updateTooltipCoords = (refEx) => {
    if (refEx.current !== null) {
      const rect = refEx.current.getBoundingClientRect();
      setCoord({
        left: rect.x - 25,
        top: rect.y - 25,
      });
    }
  };
  return (
    <React.Fragment key={infoItem[1] + index}>
      {infoItem[0] && (
        <span
          ref={refOne}
          onMouseEnter={() => {
            updateTooltipCoords(refOne);
            setOn(true);
          }}
          onMouseLeave={() => setOn(false)}
        >
          {infoItem[0]}
        </span>
      )}
      <div>{t(infoItem[1])}</div>
      {infoItem[2] !== undefined && (
        <Tooltip
          selector={"#tooltip"}
          coord={coord}
          isOn={isOn}
          boxPosition={css.BoxPosition}
        >
          {MiscUtils.quantify(
            t(infoItem[2]),
            Number(infoItem[1]),
            true,
            i18n.language
          )}
        </Tooltip>
      )}
    </React.Fragment>
  );
};

export default memo(ListItem);
