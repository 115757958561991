import UI from "Components/UI";
import React from "react";
import ReactDOM from "react-dom";
import MiscUtils from "Utils/MiscUtils";
import css from "./DesktopContextMenu.module.css";

type ContextMenuProps = React.PropsWithChildren<{
  className?: string;
  button: React.ReactNode;
  align?: "left" | "right";
  vAlign?: "top" | "bottom";
}>;

export default function DesktopContextMenu({
  className,
  button,
  align = "right",
  vAlign = "top",
  children,
}: ContextMenuProps): JSX.Element {
  const [expanded, setExpanded] = React.useState(false);
  const selfRef = React.useRef<HTMLSpanElement>(null);
  const buttonRef = React.useRef<HTMLSpanElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  if (contentRef.current && contentRef.current.style) {
    contentRef.current.style.position = "absolute";
  }

  let wrapper = document.querySelector<HTMLDivElement>(
    `.${css.ContextMenuWrapper}`
  );

  if (!wrapper) {
    wrapper = document.createElement("div");
    wrapper.className = css.ContextMenuWrapper;
    document.body.append(wrapper);
  }

  const toggle = React.useCallback(() => {
    setExpanded((current) => !current);

    if (!(buttonRef.current && contentRef.current)) {
      return;
    }

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const contentRect = contentRef.current.getBoundingClientRect();

    contentRef.current.style.top = `${
      buttonRect.top + buttonRect.height + 8
    }px`;
    contentRef.current.style.left = `${
      buttonRect.left -
      (align === "right" ? contentRect.width - buttonRect.width : 0)
    }px`;
  }, [align]);

  const scroll = React.useCallback((e: Event) => {
    if (!contentRef.current || contentRef.current.contains(e.target as Node)) {
      return;
    }

    setExpanded(false);
  }, []);

  const resize = React.useCallback(() => {
    setExpanded(false);
  }, []);

  // Check that the context menu is not outside the window.
  React.useEffect(() => {
    let ticking = true;

    const tick = async () => {
      if (!(expanded && contentRef.current)) {
        return;
      }

      const { clientHeight, offsetTop } = contentRef.current;

      if (clientHeight + offsetTop > window.innerHeight) {
        // Context menu is outside of the viewport.
        contentRef.current.style.top = `${window.innerHeight - clientHeight}px`;
      }

      if (ticking) {
        await MiscUtils.sleep(100);
        tick();
      }
    };

    tick();

    return () => {
      ticking = false;
    };
  }, [expanded]);

  // Bind resize.
  React.useEffect(() => {
    window.addEventListener("resize", resize, true);

    return () => {
      window.removeEventListener("resize", resize, true);
    };
  }, [resize]);

  // Bind scroll.
  React.useEffect(() => {
    document.addEventListener("scroll", scroll, true);

    return () => {
      document.removeEventListener("scroll", scroll, true);
    };
  }, [scroll]);

  // Bind click outside.
  React.useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (selfRef.current && !selfRef.current.contains(e.target as Node)) {
        setExpanded(false);
      }
    };

    document.addEventListener("click", onClickOutside);

    return () => {
      document.removeEventListener("click", onClickOutside);
    };
  }, []);

  const content = ReactDOM.createPortal(
    <UI.Collapsible
      ref={contentRef}
      expanded={expanded}
      className={css.Menu}
      expandedClassName={css.Expanded}
      transitions={["opacity"]}
      transitionTimeMs={200}
    >
      <div
        className={css.Wrapper}
        onClick={() => setExpanded(false)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
      >
        {children}
      </div>
    </UI.Collapsible>,
    wrapper
  );

  return (
    <span
      className={[
        css.Container,
        className ?? "",
        align === "left" ? css.LeftAlign : css.RightAlign,
        vAlign === "top" ? css.TopAlign : css.BottomAlign,
      ].join(" ")}
      ref={selfRef}
    >
      <span
        ref={buttonRef}
        className={css.Button}
        onClick={toggle}
        role="button"
        tabIndex={-1}
        onKeyDown={() => {}}
      >
        {button}
      </span>

      {content}
    </span>
  );
}
