import React from "react";

export default function WebNew({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24Z"
        fill="#F2F4F7"
      />
      <path
        d="M22 25C22.4294 25.5741 22.9773 26.0491 23.6065 26.3929C24.2357 26.7367 24.9315 26.9411 25.6466 26.9923C26.3617 27.0435 27.0795 26.9403 27.7513 26.6897C28.4231 26.4392 29.0331 26.047 29.54 25.54L32.54 22.54C33.4507 21.597 33.9547 20.3339 33.9433 19.023C33.9319 17.712 33.4061 16.4579 32.479 15.5309C31.552 14.6038 30.2979 14.078 28.987 14.0666C27.676 14.0552 26.413 14.5592 25.47 15.47L23.75 17.18M26 23C25.5705 22.4258 25.0226 21.9508 24.3934 21.607C23.7642 21.2633 23.0684 21.0588 22.3533 21.0076C21.6382 20.9564 20.9204 21.0596 20.2486 21.3102C19.5769 21.5608 18.9668 21.9529 18.46 22.46L15.46 25.46C14.5492 26.403 14.0452 27.666 14.0566 28.977C14.068 30.288 14.5938 31.542 15.5209 32.4691C16.4479 33.3961 17.702 33.9219 19.0129 33.9333C20.3239 33.9447 21.5869 33.4408 22.53 32.53L24.24 30.82"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
