import React from "react";

import css from "./Menu.module.css";

export default function Menu({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <span className={[css.Container, className ?? ""].join(" ")}>
      <span />
      <span />
      <span />
    </span>
  );
}
