import Cards from "Components/Cards";
import Icons from "Components/Icons";
import UI from "Components/UI";
import Button from "Components/UI/Button";
import { UserModel } from "Models/UserModel";
import Services from "Services";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import UserSelectors from "Store/Selectors/UserSelectors";
import MiscUtils from "Utils/MiscUtils";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import css from "./FollowersDialog.module.css";

type FollowersDialogProps = {
  onClose: () => void;
  user?: UserModel;
};

export default function FollowersDialog({
  onClose,
  user,
}: FollowersDialogProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [view, setView] = React.useState<"users" | "tipLists">("users");
  const [userIds, setUserIds] = React.useState<string[]>([]);
  const users = useSelector(UserSelectors.list(...userIds));
  const [userCount, setUserCount] = React.useState(0);
  const [selectedUserId, setSelectedUserId] = React.useState<string>();
  const selectedUser = useSelector(UserSelectors.get(selectedUserId ?? ""));
  const [selectedUserTipListIds, setSelectedUserTipListIds] = React.useState<
    string[]
  >([]);
  const [followedTipListCount, setFollowedTipListCount] = React.useState<{
    [key: string]: number;
  }>({});
  const selectedUserTipLists = useSelector(
    TipListSelectors.list(...selectedUserTipListIds)
  );
  const tipListsRef = React.useRef<HTMLDivElement>(null);
  const usersRef = React.useRef<HTMLDivElement>(null);
  const userId = user?.id;

  // Load more tip lists function.
  const tipListFuncInitialized = React.useRef(0);
  const loadMoreTipLists = React.useMemo(() => {
    const now = new Date().getTime();
    tipListFuncInitialized.current = now;

    if (!(userId && selectedUserId)) {
      return () => {};
    }

    return Services.TipList.getLoadMoreFunc(
      ([nextTiplists, nextTotal]) => {
        if (tipListFuncInitialized.current === now) {
          setSelectedUserTipListIds(nextTiplists.map((tipList) => tipList.id));
          setFollowedTipListCount((current) => ({
            ...current,
            [selectedUserId]: nextTotal,
          }));
        }
      },
      dispatch,
      {
        limit: 8,
        filters: { userIds: [userId], followerUserIds: [selectedUserId] },
      }
    );
  }, [userId, selectedUserId, dispatch]);

  // Load more users function.
  const userFuncInitialized = React.useRef(0);
  const loadMoreUsers = React.useMemo(() => {
    const now = new Date().getTime();
    userFuncInitialized.current = now;

    if (!userId) {
      return () => {};
    }

    return Services.User.getLoadMoreFuncFollowersBorrowers(
      async ([nextUsers, nextTotal]) => {
        setUserIds(Object.values(nextUsers).map((value) => value.user.id));
        setUserCount(nextTotal);

        const nextFollowedTipListcount: { [key: string]: number } = {};
        Object.values(nextUsers).forEach((value) => {
          nextFollowedTipListcount[value.user.id] = value.tipListCount ?? 0;
        });

        setFollowedTipListCount((current) => ({
          ...current,
          ...nextFollowedTipListcount,
        }));
      },
      dispatch,
      "followers",
      { limit: 8, userId }
    );
  }, [userId, dispatch]);

  // Set view to tip lists.
  const viewTipLists = React.useCallback((nextUser: UserModel) => {
    setSelectedUserId(nextUser.id);
    setSelectedUserTipListIds([]);
    setView("tipLists");
  }, []);

  // Set view to users.
  const viewUsers = React.useCallback(() => {
    setSelectedUserId(undefined);
    setView("users");
  }, []);

  // Viewing tip lists a user has followed.
  React.useEffect(() => {
    if (view === "tipLists" && selectedUserId && userId) {
      loadMoreTipLists();
    }

    const ref = tipListsRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreTipLists();
      }
    };

    ref.addEventListener("scroll", onScroll);

    return () => ref.removeEventListener("scroll", onScroll);
  }, [loadMoreTipLists, selectedUserId, userId, view]);

  // Viewing following users.
  React.useEffect(() => {
    if (view === "users") {
      loadMoreUsers();
    }

    const ref = usersRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreUsers();
      }
    };

    ref.addEventListener("scroll", onScroll);

    return () => ref.removeEventListener("scroll", onScroll);
  }, [view, loadMoreUsers]);

  let ownerLabel = "my";

  if (user && user.id !== auth?.id) {
    ownerLabel = MiscUtils.formatNameApos(user.firstName);
  }

  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  const [expanded, setExpanded] = useState(false);
  const [transition, setTransition] = useState(false);
  const moveUp = "translateY(-100%)";

  useEffect(() => {
    setTransition((prev) => !prev);
  }, [expanded]);

  return (
    <section
      className={[
        css.Container,
        view === "tipLists" ? css.TipListView : "",
      ].join(" ")}
      style={
        isPhone && open
          ? {
              transform: transition ? moveUp : "",
            }
          : {}
      }
    >
      <div className={css.Header}>
        {view === "tipLists" && (
          <UI.Button
            variant="text"
            onClick={viewUsers}
            className={css.BackButton}
          >
            <Icons.BackArrow />
          </UI.Button>
        )}

        <div className={css.DialogTitle}>
          {view === "users" && `${t("who-follow")} ${ownerLabel} ${t("lists")}`}
          {view === "tipLists" &&
            `${MiscUtils.capitalize(ownerLabel)} ${t("lists-followed-by")} ${
              selectedUser?.firstName
            } ${selectedUser?.lastName}`}
        </div>

        {!isPhone && (
          <UI.Button
            variant="text"
            onClick={onClose}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        )}
      </div>

      {/* Followers view. */}
      {view === "users" && (
        <div className={css.Content} ref={usersRef}>
          <div className={css.Items}>
            {users.map((nextUser) => (
              <article className={css.User} key={nextUser.id}>
                <Link href={`/${nextUser.slug}`}>
                  <a onClick={onClose}>
                    {nextUser.profileImage.thumb ? (
                      <img
                        src={nextUser.profileImage.thumb}
                        alt=""
                        className={css.Image}
                      />
                    ) : (
                      <Icons.Avatar className={css.Image} />
                    )}
                  </a>
                </Link>

                <p className={css.Info}>
                  <Link href={`/${nextUser.slug}`}>
                    <a className={css.Name} onClick={onClose}>
                      {`${nextUser.firstName} ${nextUser.lastName}`}
                    </a>
                  </Link>

                  <UI.Button
                    variant="text"
                    color="secondary"
                    className={css.FollowedCount}
                    onClick={() => viewTipLists(nextUser)}
                  >
                    <span>
                      <Icons.Following />
                      {`${t("following-left-column")} ${
                        followedTipListCount[nextUser.id] ?? 0
                      }`}
                    </span>
                  </UI.Button>

                  <span className={css.Description}>
                    {nextUser.description}
                  </span>
                </p>
              </article>
            ))}
          </div>
        </div>
      )}

      {/* Tip list view. */}
      {view === "tipLists" && (
        <div className={css.Content} ref={tipListsRef}>
          <div className={css.Items}>
            {selectedUserTipLists.map((tipList) => (
              <Cards.TipList
                key={tipList.id}
                className={css.TipList}
                onNavigate={onClose}
                value={tipList}
              />
            ))}
          </div>
        </div>
      )}

      {isPhone && (
        <div className={css.Close}>
          <Button
            onClick={() => {
              setExpanded((prev) => !prev);
              onClose();
            }}
            variant="outline"
            color="secondary"
            style={{ width: "100%" }}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </section>
  );
}
