import { ModalControllerContext } from "App";
import Icons from "Components/Icons";
import UI from "Components/UI";
import Button from "Components/UI/Button";
import { useRouter } from "next/dist/client/router";
import React, { memo, useEffect, useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import css from "./MobileMenu.module.css";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const { t } = useTranslation();
  const {
    signInDialog,
    signUpDialog,
    tipDialog,
    tipListDialog,
    forgotPasswordDialog,
  } = useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);

  const location = useRouter();
  const [explore, setExplore] = useState(false);

  const { signup, forgotPassword } = location.query;

  if (signup != null) signUpDialog();
  if (forgotPassword != null) forgotPasswordDialog();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/home") {
      setExplore(true);
    } else {
      setExplore(false);
    }
  }, [location]);

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const blob = target.files[0];
        const reader = new window.FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          const newUrl = base64data?.toString();
          tipDialog(undefined, newUrl);
        };
      }
    }
  };

  return (
    <nav className={css.Container}>
      {!auth && (
        <span className={css.Login}>
          <Button color="white" onClick={signInDialog}>
            {t("sign-in")}
          </Button>
          <Button onClick={signUpDialog}>{t("create-account")}</Button>
        </span>
      )}
      {auth && (
        <span className={css.isAuth}>
          <div
            onClick={() => location.replace(`/${auth?.slug}`)}
            className={[css.Logo, explore && css.deSelected].join(" ")}
          >
            {explore ? (
              <Icons.LogoOutlined />
            ) : (
              <Icons.Logo color="dark-sliced" />
            )}
            {t("start")}
          </div>

          <UI.ContextMenu
            button={<Icons.PlusCircle className={css.PlusCirlce} />}
          >
            <section className={css.CreateOptions}>
              <div onClick={() => tipListDialog()}>
                <Icons.List />
                <Button
                  className={css.CreateOptionButton}
                  color="secondary"
                  variant="text"
                >
                  {t("list")}
                </Button>
              </div>
              <div onClick={() => tipDialog()}>
                <Icons.Tip />
                <Button
                  className={css.CreateOptionButton}
                  color="secondary"
                  variant="text"
                >
                  {t("tip")}
                </Button>
              </div>
              <div>
                <input
                  id={css.cameraFileInput}
                  type="file"
                  accept="image/*"
                  capture="environment"
                  onChange={(e) => {
                    handleCapture(e.target);
                  }}
                />
                <label htmlFor={css.cameraFileInput}>
                  <div className={css.CameraInputButton} color="secondary">
                    <Icons.AddPhoto />
                    <p className={css.CameraButtonText}>{t("photo-draft")}</p>
                  </div>
                </label>
              </div>
            </section>
          </UI.ContextMenu>
          <div
            onClick={() => location.push("/home")}
            className={[!explore && css.deSelected].join(" ")}
          >
            <Icons.Search className={css.Search} />
            {t("explore")}
          </div>
        </span>
      )}
    </nav>
  );
};

export default memo(MobileMenu);
