import Category from "Components/Icons/Category";
import { TipModel } from "Models/TipModel";
import CategorySelectors from "Store/Selectors/CategorySelectors";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import css from "./CategoryFilter.module.css";

const CategoryFilter = ({
  tips,
  callback,
}: {
  tips: TipModel[];
  callback: (category: string | null) => any;
}) => {
  const { t } = useTranslation();
  const [checkedCategory, setCheckedCategory] = useState<string | null>(null);
  const allTipCategories = useSelector(CategorySelectors.all);

  const tipCategories = React.useMemo(
    () =>
      allTipCategories.filter((item) =>
        tips.map((tip) => tip.categoryId).includes(item.id)
      ),
    [tips, allTipCategories]
  );

  return (
    <div className={css.Filters}>
      <div
        className={[
          css.FiltersButton,
          checkedCategory === null && css.FilterSelected,
        ].join(" ")}
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCheckedCategory(null);
            callback(null);
          }}
          onKeyDown={() => {}}
          role="button"
          tabIndex={-1}
        >
          <input type="checkbox" checked={checkedCategory === null} readOnly />

          <span>{t("all")}</span>
        </div>
      </div>

      {tipCategories.map((category) => (
        <div
          className={[
            css.FiltersButton,
            checkedCategory == category.id && css.FilterSelected,
          ].join(" ")}
          key={category.id}
        >
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={-1}
            onClick={() => {
              setCheckedCategory(category.id);
              callback(category.id);
            }}
          >
            <input
              type="checkbox"
              checked={checkedCategory === category.id}
              readOnly
            />

            <span>
              <Category icon={category.icon} />
              <span>{t(category.title)}</span>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryFilter;
