import useNotifications from "Components/UI/Notifications";
import { NotificationProps } from "Components/UI/Notifications/Notifications";
import useModalController from "ModalController";
import { Header, MobileMenu } from "Partials";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import AuthActions from "Store/Actions/AuthActions";
import CategoryActions from "Store/Actions/CategoryActions";

export const NotificationContext = React.createContext<
  (props: Partial<NotificationProps>) => void
>(() => {});

export const ModalControllerContext = React.createContext<
  ReturnType<typeof useModalController>[1]
>({} as ReturnType<typeof useModalController>[1]);

export const UserLocationContext = React.createContext<
  | {
      lat: number;
      lng: number;
    }
  | undefined
>(undefined);

function App({ children }: { children: JSX.Element }): JSX.Element {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [userLocation, setUserLocation] = React.useState<{
    lat: number;
    lng: number;
  }>();
  const [modals, modalActions] = useModalController();
  const [notifications, showNotification] = useNotifications();
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  // Fetch logged in user every 15 minutes.
  React.useEffect(() => {
    const repeat = async () => {
      await AuthActions.getAuthedUser(dispatch);

      setTimeout(repeat, 900000);
    };

    repeat();
  }, [dispatch]);

  // Get tip categories.
  React.useEffect(() => {
    CategoryActions.list(dispatch);
  }, [dispatch]);

  // Get language from local storage.
  React.useEffect(() => {
    if (i18n.language !== localStorage.getItem("lng")) {
      i18n.changeLanguage(localStorage.getItem("lng") || "sv");
    }
  }, []);

  // Get location
  React.useEffect(() => {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition((pos) => {
      setUserLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    });
  }, []);

  return (
    <UserLocationContext.Provider value={userLocation}>
      <div id="portal" />
      <ModalControllerContext.Provider value={modalActions}>
        <NotificationContext.Provider value={showNotification}>
          <div id="tooltip" />
          {notifications}
          {modals}
          <div style={{ minHeight: "100vh" }}>
            {isPhone ? <MobileMenu /> : <Header />}
            <div>{children}</div>
          </div>
        </NotificationContext.Provider>
      </ModalControllerContext.Provider>
    </UserLocationContext.Provider>
  );
}

export default App;
