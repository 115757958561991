import React from "react";

export default function StarFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.666687 24C0.666687 10.7452 11.4119 0 24.6667 0C37.9215 0 48.6667 10.7452 48.6667 24C48.6667 37.2548 37.9215 48 24.6667 48C11.4119 48 0.666687 37.2548 0.666687 24Z"
        fill="#475467"
      />
      <path
        d="M24.6667 14L27.7567 20.26L34.6667 21.27L29.6667 26.14L30.8467 33.02L24.6667 29.77L18.4867 33.02L19.6667 26.14L14.6667 21.27L21.5767 20.26L24.6667 14Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
