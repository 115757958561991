import React from "react";

export default function GlobeFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#475467"
      />
      <path
        d="M34 24C34 29.5228 29.5228 34 24 34M34 24C34 18.4772 29.5228 14 24 14M34 24H14M24 34C18.4772 34 14 29.5228 14 24M24 34C26.5013 31.2616 27.9228 27.708 28 24C27.9228 20.292 26.5013 16.7384 24 14M24 34C21.4987 31.2616 20.0772 27.708 20 24C20.0772 20.292 21.4987 16.7384 24 14M14 24C14 18.4772 18.4772 14 24 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
