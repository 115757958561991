import React from "react";

export default function Success({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.3337 9.23342V10.0001C18.3326 11.7971 17.7507 13.5457 16.6748 14.9849C15.5988 16.4242 14.0864 17.4772 12.3631 17.9867C10.6399 18.4962 8.79804 18.435 7.11238 17.8122C5.42673 17.1895 3.98754 16.0385 3.00946 14.531C2.03138 13.0235 1.56682 11.2401 1.68506 9.44702C1.80329 7.65389 2.498 5.94703 3.66556 4.58098C4.83312 3.21494 6.41098 2.26291 8.16382 1.86688C9.91665 1.47085 11.7505 1.65204 13.392 2.38342M18.3337 3.33342L10.0003 11.6751L7.50033 9.17509"
        stroke="#039855"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
