import Icons from "Components/Icons";
import React from "react";
import UI from "Components/UI";
import Hooks from "Hooks";
import { NotificationContext } from "App";
import css from "./ConfirmPasswordDialog.module.css";
import AuthService from "Services/AuthService";
import { useTranslation } from "react-i18next";

type Data = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

type ConfirmPasswordDialogProps = {
  onClose: () => void;
};

export default function ConfirmPasswordDialog({
  onClose,
}: ConfirmPasswordDialogProps): JSX.Element {
  const { t } = useTranslation();
  const showNotification = React.useContext(NotificationContext);
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    (nextData) => ({
      oldPassword:
        nextData.oldPassword.trim().length === 0
          ? (t("enter-password") as string)
          : undefined,
      newPassword:
        !nextData.newPassword.match(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z\w])/) ||
        nextData.newPassword.length < 8 ||
        nextData.newPassword.length > 24
          ? (t("invalid-password") as string)
          : undefined,
      confirmPassword:
        nextData.newPassword !== nextData.confirmPassword
          ? (t("passwords-match") as string)
          : undefined,
    })
  );
  const [pending, setPending] = React.useState(false);

  const handleChangePasword = async () => {
    if (!valid()) {
      return;
    }
    try {
      await AuthService.changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      });
      onClose();
      showNotification({
        title: t("password-changed"),
        timeoutMs: 2000,
      });
    } catch (error: any) {
      showNotification({
        title: "Error",
        message: <>{error.message}</>,
        timeoutMs: 2000,
        type: "error",
      });
    }
  };

  return (
    <section className={css.Container}>
      <div className={css.Header}>
        <h5 className={css.topHeader}>{t("change-password")}</h5>
      </div>

      <div className={css.Input}>
        <UI.TextField
          className={css.Input}
          type="password"
          label={t("old-password")}
          value={data.oldPassword}
          onChange={(e) => change({ oldPassword: e.target.value })}
        />
        <UI.ValidationError>{errors.oldPassword}</UI.ValidationError>
      </div>
      <div className={css.Input}>
        <UI.TextField
          className={css.Input}
          type="password"
          label={t("new-password")}
          value={data.newPassword}
          onChange={(e) => change({ newPassword: e.target.value })}
        />
        <UI.ValidationError>{errors.newPassword}</UI.ValidationError>
      </div>
      <div className={css.Input}>
        <UI.TextField
          className={css.Input}
          type="password"
          label={t("confirm-new-password")}
          value={data.confirmPassword}
          onChange={(e) => change({ confirmPassword: e.target.value })}
        />
        <UI.ValidationError>{errors.confirmPassword}</UI.ValidationError>
      </div>
      <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
        <Icons.Close />
      </UI.Button>
      <div className={css.Actions}>
        <UI.Button variant="outline" onClick={onClose}>
          {t("cancel")}
        </UI.Button>

        <UI.Button type="submit" onClick={() => handleChangePasword()}>
          {pending ? t("saving") : t("confirm")}
        </UI.Button>
      </div>
    </section>
  );
}
