import React from "react";
import { useModal } from "Components/UI/Modal";
import EditProfileDialog from "Dialogs/EditProfileDialog";
import BorrowersDialog from "Dialogs/BorrowersDialog";
import FollowedTipListsDialog from "Dialogs/FollowedTipListsDialog";
import FollowersDialog from "Dialogs/FollowersDialog";
import {
  ForgotPassword,
  SignInDialog,
  SignUpDialog,
} from "Dialogs/SignInSignUpDialog";
import { UserModel } from "Models/UserModel";
import TipDialog from "Dialogs/TipDialog";
import TipListDialog from "Dialogs/TipListDialog";
import { TipModel } from "Models/TipModel";
import { TipListModel } from "Models/TipListModel";
import ConfirmPasswordDialog from "Dialogs/ConfirmPasswordDialog";
import HandleTipsDialog from "Dialogs/HandleTipsDialog";
import MapDialog from "Dialogs/MapDialog";
import OnboardingDialog from "Dialogs/OnboardingDialog";
import { useRouter } from "next/router";
import ReportTipDialog from "Dialogs/ReportTipDialog.tsx";
import BookDialog from "Dialogs/BookDialog";
import ChangeLanguageDialog from "Dialogs/ChangeLanguageDialog";

export default function useModalController(): [
  JSX.Element,
  {
    tipDialog: (
      tip?: TipModel,
      source?: string,
      list?: TipListModel
    ) => Promise<void>;
    signInDialog: () => Promise<void>;
    signUpDialog: () => Promise<void>;
    forgotPasswordDialog: () => Promise<void>;
    tipListDialog: (
      tipList?: TipListModel,
      tip?: TipModel,
      saveCallback?: (tipList: TipListModel) => void
    ) => Promise<void>;
    editProfileDialog: () => Promise<void>;
    confirmPasswordDialog: () => Promise<void>;
    borrowersDialog: (user: UserModel) => Promise<void>;
    followedTipListsDialog: (user: UserModel) => Promise<void>;
    followersDialog: (user: UserModel) => Promise<void>;
    onboardingDialog: () => Promise<void>;
    mapDialog: (
      tipList: TipListModel,
      tips: TipModel[],
      tip?: TipModel
    ) => Promise<void>;
    handleTipsDialog: (
      tip: TipModel,
      borrow?: boolean,
      move?: boolean,
      currentList?: string
    ) => Promise<void>;
    reportTipDialog: (tip: TipModel) => Promise<void>;
    bookDialog: (link: string) => Promise<void>;
    changeLanguageDialog: () => Promise<void>;
  }
] {
  const location = useRouter();
  const [signIn, showSignIn, hideSignIn] = useModal(
    <SignInDialog onClose={() => hideSignIn()} />
  );

  const [signUp, showSignUp, hideSignUp] = useModal(
    <SignUpDialog onClose={() => hideSignUp()} />
  );

  const [forgotPassword, showForgotPassword, hideForgotPassword] = useModal(
    <ForgotPassword
      onClose={() => {
        location.query["forgotPassword"] = undefined;
        hideForgotPassword();
      }}
    />
  );

  const [bookDialog, showBookDialog, hideBookDialog] = useModal(
    (link: string) => (
      <BookDialog onClose={() => hideBookDialog()} link={link} />
    )
  );

  const [changeLanguage, showChangeLanguage, hideChangeLanguage] = useModal(
    <ChangeLanguageDialog onClose={() => hideChangeLanguage()} />
  );

  const [editProfile, showEditProfile, hideEditProfile] = useModal(
    <EditProfileDialog onClose={() => hideEditProfile()} />
  );

  const [confirmPassword, showConfirmPassword, hideConfirmPassword] = useModal(
    <ConfirmPasswordDialog onClose={() => hideConfirmPassword()} />
  );

  const [borrowers, showBorrowers, hideBorrowers] = useModal(
    (user: UserModel) => (
      <BorrowersDialog onClose={() => hideBorrowers()} user={user} />
    ),
    true,
    false
  );

  const [followedTipLists, showFollowedTipLists, hideFollowedTipLists] =
    useModal(
      (user: UserModel) => (
        <FollowedTipListsDialog
          onClose={() => hideFollowedTipLists()}
          user={user}
        />
      ),
      true,
      false
    );

  const [followers, showFollowers, hideFollowers] = useModal(
    (user: UserModel) => (
      <FollowersDialog onClose={() => hideFollowers()} user={user} />
    ),
    true,
    false
  );

  const [onboarding, showOnboarding, hideOnboarding] = useModal(() => (
    <OnboardingDialog onClose={() => hideOnboarding()} />
  ));

  const [tipDialog, showTipDialog, hideTipDialog] = useModal(
    (tip?: TipModel, source?: string, list?: TipListModel) => (
      <TipDialog
        onClose={() => hideTipDialog()}
        tip={tip}
        source={source}
        list={list}
      />
    ),
    false
  );

  const [handleTipsDialog, showHandleTipsDialog, hideHandleTipsDialog] =
    useModal(
      (
        tip: TipModel,
        borrow?: boolean,
        move?: boolean,
        currentList?: string
      ) => (
        <HandleTipsDialog
          onClose={() => hideHandleTipsDialog()}
          tip={tip}
          borrow={borrow}
          move={move}
          currentList={currentList}
        />
      )
    );

  const [mapDialog, showMapDialog, hideMapDialog] = useModal(
    (tipList: TipListModel, tips: TipModel[], tip?: TipModel) => (
      <MapDialog
        onClose={() => hideMapDialog()}
        tip={tip}
        tipList={tipList}
        tips={tips}
      />
    )
  );

  const [tipListDialog, showTipListDialog, hideTipListDialog] = useModal(
    (
      tipList?: TipListModel,
      tip?: TipModel,
      saveCallback?: (tipList: TipListModel) => void
    ) => (
      <TipListDialog
        onClose={() => hideTipListDialog()}
        tipList={tipList}
        tip={tip}
        saveCallback={saveCallback}
      />
    ),
    false
  );

  const [reportTipDialog, showReportTipDialog, hideReportTipDialog] = useModal(
    (tip: TipModel) => (
      <ReportTipDialog onClose={() => hideReportTipDialog()} value={tip} />
    )
  );

  return [
    <>
      {tipDialog}
      {signIn}
      {signUp}
      {forgotPassword}
      {editProfile}
      {confirmPassword}
      {tipListDialog}
      {borrowers}
      {followedTipLists}
      {followers}
      {handleTipsDialog}
      {mapDialog}
      {onboarding}
      {reportTipDialog}
      {bookDialog}
      {changeLanguage}
    </>,
    {
      bookDialog: showBookDialog,
      changeLanguageDialog: showChangeLanguage,
      tipDialog: showTipDialog,
      signInDialog: showSignIn,
      signUpDialog: showSignUp,
      forgotPasswordDialog: showForgotPassword,
      tipListDialog: showTipListDialog,
      editProfileDialog: showEditProfile,
      confirmPasswordDialog: showConfirmPassword,
      borrowersDialog: showBorrowers,
      followedTipListsDialog: showFollowedTipLists,
      followersDialog: showFollowers,
      handleTipsDialog: showHandleTipsDialog,
      mapDialog: showMapDialog,
      onboardingDialog: showOnboarding,
      reportTipDialog: showReportTipDialog,
    },
  ];
}
