import React from "react";

export default function LocationNew({
  className,
}: {
  className?: string;
  stroke?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#F2F4F7"
      />
      <path
        d="M24 16C25.93 16 29 17.4 29 21.15C29 23.31 27.28 25.82 24 28.47C20.72 25.82 19 23.3 19 21.15C19 17.4 22.07 16 24 16ZM24 14C20.73 14 17 16.46 17 21.15C17 24.27 19.33 27.56 24 31C28.67 27.56 31 24.27 31 21.15C31 16.46 27.27 14 24 14Z"
        fill="#475467"
      />
      <path
        d="M24 19C22.9 19 22 19.9 22 21C22 22.1 22.9 23 24 23C24.5304 23 25.0391 22.7893 25.4142 22.4142C25.7893 22.0391 26 21.5304 26 21C26 20.4696 25.7893 19.9609 25.4142 19.5858C25.0391 19.2107 24.5304 19 24 19V19ZM17 32H31V34H17V32Z"
        fill="#475467"
      />
    </svg>
  );
}
