import UI from "Components/UI";
import React from "react";

import css from "./ConfirmDialog.module.css";

type ConfirmDialogProps = {
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  options?: React.ReactNode;
};

export default function ConfirmDialog({
  message,
  confirmLabel = "OK",
  cancelLabel = "Cancel",
  onConfirm,
  onCancel,
  options,
}: ConfirmDialogProps): JSX.Element {
  return (
    <div className={css.Container}>
      <p>{message}</p>

      <div className={css.Actions}>
        {options !== undefined ? (
          options
        ) : (
          <>
            <UI.Button variant="fill" color="fourth" onClick={onCancel}>
              {cancelLabel}
            </UI.Button>

            <UI.Button variant="fill" onClick={onConfirm}>
              {confirmLabel}
            </UI.Button>
          </>
        )}
      </div>
    </div>
  );
}
