import React from "react";

export default function FeaturedShare({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.63069 28C4.63069 14.7452 15.3759 4 28.6307 4C41.8855 4 52.6307 14.7452 52.6307 28C52.6307 41.2548 41.8855 52 28.6307 52C15.3759 52 4.63069 41.2548 4.63069 28Z"
        fill="#FFCA64"
      />
      <path
        d="M32.1307 27C32.9591 27 33.6307 26.3284 33.6307 25.5C33.6307 24.6716 32.9591 24 32.1307 24C31.3023 24 30.6307 24.6716 30.6307 25.5C30.6307 26.3284 31.3023 27 32.1307 27Z"
        fill="#1D2939"
      />
      <path
        d="M25.1307 27C25.9591 27 26.6307 26.3284 26.6307 25.5C26.6307 24.6716 25.9591 24 25.1307 24C24.3023 24 23.6307 24.6716 23.6307 25.5C23.6307 26.3284 24.3023 27 25.1307 27Z"
        fill="#1D2939"
      />
      <path
        d="M28.6307 34C30.9107 34 32.8507 32.34 33.6307 30H23.6307C24.4107 32.34 26.3507 34 28.6307 34Z"
        fill="#1D2939"
      />
      <path
        d="M28.6207 18C23.1007 18 18.6307 22.48 18.6307 28C18.6307 33.52 23.1007 38 28.6207 38C34.1507 38 38.6307 33.52 38.6307 28C38.6307 22.48 34.1507 18 28.6207 18ZM28.6307 36C24.2107 36 20.6307 32.42 20.6307 28C20.6307 23.58 24.2107 20 28.6307 20C33.0507 20 36.6307 23.58 36.6307 28C36.6307 32.42 33.0507 36 28.6307 36Z"
        fill="#1D2939"
      />
      <path
        d="M28.6307 48C17.585 48 8.63069 39.0457 8.63069 28H0.630692C0.630692 43.464 13.1667 56 28.6307 56V48ZM48.6307 28C48.6307 39.0457 39.6764 48 28.6307 48V56C44.0947 56 56.6307 43.464 56.6307 28H48.6307ZM28.6307 8C39.6764 8 48.6307 16.9543 48.6307 28H56.6307C56.6307 12.536 44.0947 0 28.6307 0V8ZM28.6307 0C13.1667 0 0.630692 12.536 0.630692 28H8.63069C8.63069 16.9543 17.585 8 28.6307 8V0Z"
        fill="#FFCA64"
      />
    </svg>
  );
}
