import React from "react";
import MiscUtils from "Utils/MiscUtils";
import Modal from "./Modal";

function useModal<T>(
  content: React.ReactNode | ((...args: T[]) => React.ReactNode),
  padded = true,
  showBackdrop = true
): [JSX.Element, (...args: T[]) => Promise<void>, () => Promise<void>] {
  const [open, setOpen] = React.useState(false);
  const [args, setArgs] = React.useState<T[]>([]);

  const hide = React.useCallback(async () => {
    setOpen(false);
    await MiscUtils.sleep(400);
  }, []);

  const show = React.useCallback(
    async (...nextArgs: T[]) => {
      if (typeof content === "function") {
        setArgs(nextArgs);
      }

      setOpen(true);
      await MiscUtils.sleep(400);
    },
    [content]
  );

  const modal = (
    <Modal open={open} padded={padded} showBackdrop={showBackdrop}>
      {typeof content === "function" ? content(...args) : content}
    </Modal>
  );

  return [modal, show, hide];
}

export default Modal;

export { useModal };
