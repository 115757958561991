import axios from "axios";
import Config from "Config";

const http = axios.create({
  baseURL: Config.API_BASE_URL,
});
http.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
function getSort(sort?: {
  [key: string]: boolean | undefined;
}): string | undefined {
  if (!sort) {
    return undefined;
  }

  return Object.keys(sort)
    .map((key) => (sort[key] ? `-${key}` : key))
    .join(",");
}

function getFilters(filters?: { [key: string]: unknown }): {
  [key: string]: unknown;
} {
  if (!filters) {
    return {};
  }

  const nextFilters: { [key: string]: unknown } = {};

  Object.keys(filters).forEach((key) => {
    let val = filters[key];

    if (val instanceof Array) {
      val = val.join(",");

      if (val === "") {
        return;
      }
    }

    nextFilters[`filter[${key}]`] = val;
  });

  return nextFilters;
}

function getPagination(
  limit?: number,
  offset?: number
): {
  "page[limit]": number;
  "page[offset]": number;
} {
  return {
    "page[limit]": limit !== undefined ? limit : 20,
    "page[offset]": offset !== undefined ? offset : 0,
  };
}

export default {
  http,
  getSort,
  getFilters,
  getPagination,
};
