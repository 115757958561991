import React from "react";

export default function HomepageShare({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.796692 24C0.796692 10.7452 11.5419 0 24.7967 0C38.0515 0 48.7967 10.7452 48.7967 24C48.7967 37.2548 38.0515 48 24.7967 48C11.5419 48 0.796692 37.2548 0.796692 24Z"
        fill="#FDB022"
      />
      <path
        d="M21.3867 25.51L28.2167 29.49M28.2067 18.51L21.3867 22.49M33.7967 17C33.7967 18.6569 32.4535 20 30.7967 20C29.1398 20 27.7967 18.6569 27.7967 17C27.7967 15.3431 29.1398 14 30.7967 14C32.4535 14 33.7967 15.3431 33.7967 17ZM21.7967 24C21.7967 25.6569 20.4535 27 18.7967 27C17.1398 27 15.7967 25.6569 15.7967 24C15.7967 22.3431 17.1398 21 18.7967 21C20.4535 21 21.7967 22.3431 21.7967 24ZM33.7967 31C33.7967 32.6569 32.4535 34 30.7967 34C29.1398 34 27.7967 32.6569 27.7967 31C27.7967 29.3431 29.1398 28 30.7967 28C32.4535 28 33.7967 29.3431 33.7967 31Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
