import { OrganizationModel } from "Models/OrganizationModel";
import ServiceUtils from "./ServiceUtils";

async function get(id: string): Promise<OrganizationModel | null> {
  try {
    const { data: organzation } =
      await ServiceUtils.http.get<OrganizationModel>(`/l/${id}`);

    return organzation as OrganizationModel;
  } catch (err) {
    return null;
  }
}

export default {
  get,
};
