import { TipModel, TipStatusEnum } from "Models/TipModel";
import { RootState } from "Store";
import CategorySelectors from "./CategorySelectors";
import UserSelectors from "./UserSelectors";
import VenueSelectors from "./VenueSelectors";

function get(id: string): (state: RootState) => TipModel | null {
  return (state: RootState) => {
    const tip = state.tips[id] ?? null;
    //kolla efter om tip är null -> annars iterera igenom alla tips efter slug
    if (!tip) {
      return tip;
    }

    return {
      ...tip,
      venue: VenueSelectors.get(tip.venue?.id ?? "")(state) ?? tip.venue,
      category:
        CategorySelectors.get(tip.category?.id ?? "")(state) ?? tip.category,
      user: UserSelectors.get(tip.user.id ?? "")(state) ?? tip.user,
    };
  };
}

function list(...ids: string[]): (state: RootState) => TipModel[] {
  return (state: RootState) => {
    const tips = ids
      .map((id) => state.tips[id])
      .filter((tip) => tip !== undefined);

    const venueIds: string[] = [];
    const categoryIds: string[] = [];
    const userIds: string[] = [];

    tips.forEach((tip) => {
      if (tip.venue?.id) {
        venueIds.push(tip.venue.id);
      }

      if (tip.category?.id) {
        categoryIds.push(tip.category.id);
      }

      userIds.push(tip.user.id);
    });

    const venues = VenueSelectors.list(...venueIds)(state);
    const categories = CategorySelectors.list(...categoryIds)(state);
    const users = UserSelectors.list(...userIds)(state);

    return tips.map((tip) => ({
      ...tip,
      venue: venues.find((venue) => venue.id === tip.venue?.id) ?? tip.venue,
      category:
        categories.find((category) => category.id === tip.category?.id) ??
        tip.category,
      user: users.find((user) => user.id === tip.user.id) ?? tip.user,
    }));
  };
}

function drafts(state: RootState): TipModel[] {
  return list(
    ...Object.values(state.tips)
      .filter((tip) => tip.status === TipStatusEnum.DRAFT)
      .map((tip) => tip.id)
  )(state);
}

export default {
  get,
  list,
  drafts,
};
