import React from "react";

export default function CompassFilled({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0V0C37.2548 0 48 10.7452 48 24V24C48 37.2548 37.2548 48 24 48V48C10.7452 48 0 37.2548 0 24V24Z"
        fill="#475467"
      />
      <path
        d="M24 14C18.48 14 14 18.48 14 24C14 29.52 18.48 34 24 34C29.52 34 34 29.52 34 24C34 18.48 29.52 14 24 14ZM24 32C19.59 32 16 28.41 16 24C16 19.59 19.59 16 24 16C28.41 16 32 19.59 32 24C32 28.41 28.41 32 24 32ZM18.5 29.5L26.01 26.01L29.5 18.5L21.99 21.99L18.5 29.5ZM24 22.9C24.61 22.9 25.1 23.39 25.1 24C25.1 24.61 24.61 25.1 24 25.1C23.39 25.1 22.9 24.61 22.9 24C22.9 23.39 23.39 22.9 24 22.9Z"
        fill="white"
      />
    </svg>
  );
}
