import { CityModel } from "Models/CityModel";
import { RootState } from "Store";
import CountrySelectors from "./CountrySelectors";

function get(slug: string): (state: RootState) => CityModel | null {
  return (state: RootState) => {
    const res =
      Object.values(state.cities).find((city) => city.slug === slug) ?? null;
    if (!res) {
      return null;
    }

    return {
      ...res,
      country:
        CountrySelectors.get(res.country?.id ?? "")(state) ?? res.country,
    };
  };
}

function list(...ids: string[]): (state: RootState) => CityModel[] {
  return (state: RootState) => {
    const cities = ids
      .map((id) => state.cities[id])
      .filter((city) => city !== undefined);
    const countryIds: string[] = [];

    cities.forEach((city) => {
      if (city.country?.id) {
        countryIds.push(city.country.id);
      }
    });

    const countries = CountrySelectors.list(...countryIds)(state);

    return cities.map((city) => ({
      ...city,
      country:
        countries.find((country) => country.id === city.country?.id) ??
        city.country,
    }));
  };
}

export default {
  get,
  list,
};
