import { UserModel } from "Models/UserModel";
import { RootState } from "Store";

function get(id: string): (state: RootState) => UserModel | null {
  return (state: RootState) => {
    const user = state.users[id] ?? null;
    return user;
  };
}

function list(...ids: string[]): (state: RootState) => UserModel[] {
  return (state: RootState) => {
    const users = ids
      .map((id) => state.users[id])
      .filter((user) => user !== undefined);

    return users;
  };
}

export default {
  get,
  list,
};
