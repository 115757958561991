import Cards from "Components/Cards";
import Icons from "Components/Icons";
import UI from "Components/UI";
import Button from "Components/UI/Button";
import { UserModel } from "Models/UserModel";
import Services from "Services";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import TipListSelectors from "Store/Selectors/TipListSelectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import css from "./FollowedTipListsDialog.module.css";
import { useTranslation } from "react-i18next";

type FollowedTipListsDialogProps = {
  onClose: () => void;
  user: UserModel;
};

export default function FollowedTipListsDialog({
  onClose,
  user,
}: FollowedTipListsDialogProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [tipListIds, setTipListIds] = React.useState<string[]>([]);
  const tipLists = useSelector(TipListSelectors.list(...tipListIds));
  const [total, setTotal] = React.useState(0);
  const myTipLists = auth && auth.id === user.id;
  const tipListsRef = React.useRef<HTMLDivElement>(null);
  const userId = user.id;

  const [expanded, setExpanded] = useState(false);

  const [transition, setTransition] = useState(false);

  const moveUp = "translateY(-100%)";

  useEffect(() => {
    setTransition((prev) => !prev);
  }, [expanded]);

  // Load more function.
  const funcInitialized = React.useRef<number>(0);
  const loadMore = React.useMemo(() => {
    const now = new Date().getTime();
    funcInitialized.current = now;

    return Services.TipList.getLoadMoreFunc(
      ([nextTipLists, nextTotal]) => {
        if (funcInitialized.current === now) {
          setTipListIds(nextTipLists.map((tipList) => tipList.id));
          setTotal(nextTotal);
        }
      },
      dispatch,
      { filters: { followerUserIds: [userId] }, limit: 8 }
    );
  }, [userId, dispatch]);

  // Initial load
  React.useEffect(() => {
    loadMore();

    const ref = tipListsRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMore();
      }
    };

    ref.addEventListener("scroll", onScroll);

    return () => ref.removeEventListener("scroll", onScroll);
  }, [loadMore]);
  const isPhone = useMediaQuery({
    query: "(max-width: 900px)",
  });

  return (
    <section
      className={css.Container}
      style={
        isPhone
          ? {
              transform: transition ? moveUp : "",
            }
          : {}
      }
    >
      <div className={css.Header}>
        <div className={css.DialogTitle}>
          {`${myTipLists ? t("i-am") : user.firstName} ${t(
            "following-left-column"
          )} ${total} ${total > 1 ? t("lists") : t("list")}`}
        </div>
        {!isPhone && (
          <UI.Button
            variant="text"
            onClick={onClose}
            className={css.CloseButton}
          >
            <Icons.Close />
          </UI.Button>
        )}
      </div>

      <div className={css.Content} ref={tipListsRef}>
        <div className={css.TipLists}>
          {tipLists.length === 0 && <span>{t("no-list-followed")}</span>}

          {tipLists.map((tipList) => (
            <Cards.TipList
              key={tipList.id}
              className={css.TipList}
              value={tipList}
              onNavigate={onClose}
            />
          ))}
        </div>
      </div>
      {isPhone && (
        <div className={css.Close}>
          <Button
            onClick={() => {
              setExpanded((prev) => !prev);
              onClose();
            }}
            variant="outline"
            color="secondary"
            style={{ width: "100%" }}
          >
            {t("close")}
          </Button>
        </div>
      )}
    </section>
  );
}
