import React from "react";

import SignInSignUpDialog, {
  SignInSignUpDialogProps,
} from "./SignInSignUpDialog";

function SignInDialog(
  props: Exclude<SignInSignUpDialogProps, "initialView">
): JSX.Element {
  return <SignInSignUpDialog {...props} initialView="signIn" />;
}

function SignUpDialog(
  props: Exclude<SignInSignUpDialogProps, "initialView">
): JSX.Element {
  return <SignInSignUpDialog {...props} initialView="signUp" />;
}

function ForgotPassword(
  props: Exclude<SignInSignUpDialogProps, "initialView">
): JSX.Element {
  return <SignInSignUpDialog {...props} initialView="forgotPassword" />;
}

export { SignInDialog, SignUpDialog, ForgotPassword };
