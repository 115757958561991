import React from "react";

export default function RESIcon({
  className,
}: {
  className?: string;
}): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 170 40"
      height="40"
      width="170"
    >
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,40)" id="g10">
        <g transform="scale(0.05)" id="g12">
          <path
            id="path14"
            fill="#ffffff"
            d="m 1010.01,490.273 h 175.41 V 602.672 H 983.027 c -69.437,0 -163.648,-46.078 -163.648,-151.5 0,-157.231 247.181,-150.25 247.181,-219.098 0,-25.261 -29.16,-28.301 -43.38,-28.301 H 821.309 V 83.6523 h 225.381 c 64.29,0 159.03,28.6607 159.03,141.6017 0,161.168 -249.087,153.379 -249.087,227.187 0,27.524 27.797,37.832 53.377,37.832 M 576.77,489.574 H 795.938 V 600.953 H 436.652 V 83.5742 H 795.918 V 203.441 H 576.77 v 92.122 H 776.176 V 406.941 H 576.77 Z M 412.629,83.5742 c 0,0 -108.941,233.9888 -107.141,235.7928 85.782,34.606 124.602,147.5 51.364,229.246 -51.411,57.379 -157.051,52.242 -157.051,52.242 L 0.078125,600.602 0,490.684 h 150.777 c 60.953,0 61.762,1.382 84.672,-1.762 29.457,-4.039 43.551,-19.918 43.551,-42.531 0,-8.578 -2.441,-36.469 -43.148,-43.239 -20.582,-3.422 -22.883,-1.781 -86.985,-1.781 L 2.26172,401.391 H 2.1875 V 83.5742 H 137.609 V 297.336 c 0,0 38.411,0.187 40.071,0.105 1.941,-0.105 88.75,-213.8668 88.75,-213.8668 z M 0,490.645 v 0.039 -0.039"
          />
          <path
            id="path16"
            fill="#d22027"
            d="m 1884.95,99.8008 c -7.21,-2.0586 -13.38,4.1172 -14.41,10.2932 15.44,65.871 74.1,117.332 126.59,159.531 13.38,8.23 40.14,18.523 45.29,-1.031 -22.64,-68.957 -89.54,-141.004 -157.47,-168.7932 z m 306.47,297.6252 7.2,20.586 c -11.32,23.672 -34.99,-4.117 -48.37,-16.469 l -86.22,-125.742 c -9.26,-3.09 -6.17,11.32 -13.38,14.41 -10.29,7.203 -25.73,7.203 -38.08,5.144 -72.05,-24.703 -151.3,-89.542 -178.06,-161.589 -2.05,-16.469 -4.11,-36.0238 13.38,-44.2582 45.29,-17.4961 81.31,19.5552 114.25,42.1992 0,-14.41 -4.12,-36.0234 14.41,-44.2578 40.14,-6.1758 71.02,19.5548 99.83,46.3168 0,3.086 0,7.203 -3.08,8.234 l -50.44,-34.996 c -8.23,-4.117 -19.55,-13.379 -28.81,-3.086 -2.06,13.379 3.08,22.645 9.26,32.934 85.42,126.597 94.45,136.039 188.11,260.574"
          />
          <path
            id="path18"
            fill="#d22027"
            d="m 2272.24,279.918 c 6.17,0 16.47,2.055 16.47,-7.207 -12.35,-47.344 -65.87,-59.695 -103.96,-69.988 19.56,37.054 51.47,60.726 87.49,77.195 z m 33.96,-28.82 c 3.09,9.261 13.38,22.644 3.09,31.906 -11.32,9.262 -25.73,10.293 -41.17,9.262 -76.16,-13.379 -146.15,-86.453 -171.88,-157.469 -4.12,-15.442 -1.03,-30.879 8.23,-43.2306 53.52,-22.6406 113.22,1.0313 154.39,37.0506 v 11.324 c -33.97,-19.554 -62.78,-49.4019 -104.98,-48.3746 -9.27,-1.0273 -17.5,7.2031 -20.59,15.4376 2.06,29.848 14.41,55.582 33.97,78.223 51.46,8.234 100.86,25.73 138.94,65.871"
          />
          <path
            id="path20"
            fill="#d22027"
            d="m 1904.12,369.121 c 2.06,-17.496 -6.17,-31.906 -20.58,-39.109 -7.21,-1.032 -17.5,-6.176 -23.67,0 -9.27,7.203 -9.27,21.613 -5.15,30.875 6.18,8.234 13.38,17.5 22.64,19.558 11.32,2.059 22.65,-3.09 26.76,-11.324"
          />
          <path
            id="path22"
            fill="#d22027"
            d="m 1840.31,279.578 c -34.99,-57.637 -94.69,-110.129 -125.57,-171.883 -1.02,-7.203 0,-18.5231 10.3,-16.4645 29.84,3.0859 51.46,29.8475 75.13,43.2265 5.15,-5.144 -1.03,-12.352 -6.17,-16.469 -28.82,-22.6403 -61.76,-48.3708 -101.9,-34.9919 -16.47,8.2344 -11.32,33.9649 -6.17,47.3439 30.87,51.461 68.95,99.836 110.12,146.152 12.35,8.235 30.88,11.321 44.26,3.086"
          />
          <path
            id="path24"
            fill="#d22027"
            d="m 1503.62,276 c 2.05,-23.672 -15.44,-39.113 -26.76,-57.637 -28.82,-40.14 -72.05,-71.019 -79.26,-120.4216 12.36,-12.3516 28.82,-1.0273 41.17,6.1756 13.38,10.293 28.82,23.672 44.26,33.965 6.18,-2.059 1.03,-7.203 2.06,-10.293 -29.85,-29.8476 -64.84,-53.5195 -107.04,-43.2265 -11.32,4.1172 -14.41,17.4965 -15.44,28.8205 9.26,62.781 77.19,99.832 109.1,150.265 3.09,6.176 1.03,19.555 -9.26,16.469 -54.55,-22.644 -74.11,-52.492 -117.34,-97.777 -41.17,-30.879 -58.66,-96.7502 -117.33,-100.8634 -6.17,10.289 6.18,17.4961 9.26,25.7264 41.17,53.524 86.46,107.043 120.42,164.68 -11.32,4.117 -25.73,2.058 -37.05,7.203 12.35,13.383 37.05,4.117 54.55,8.234 10.29,1.032 18.53,0 26.76,-4.117 -5.14,-24.699 -33.96,-43.226 -40.14,-64.84 37.05,29.848 72.05,82.336 127.63,72.043 L 1503.62,276"
          />
          <path
            id="path26"
            fill="#d22027"
            d="m 1725.98,303.25 c -5.14,-20.582 -28.82,-29.848 -43.23,-44.258 -18.52,-63.808 -24.7,-146.148 -91.6,-175.9959 -29.85,-9.2617 -65.87,-16.4688 -93.66,0 -15.44,9.2617 -17.49,29.8479 -16.47,50.4339 2.06,14.406 15.44,27.789 27.79,33.965 8.24,2.054 22.65,1.027 21.62,-10.293 -1.03,-26.762 -58.67,-50.434 -12.35,-72.0473 22.64,-5.1484 42.2,4.1172 59.69,13.3789 39.11,39.1134 49.41,90.5744 63.81,139.9764 -25.73,-7.207 -72.2,-51.008 -96.9,-64.387 -6.18,3.086 0,7.204 0,11.321 l 110.28,79.828 c 3.09,15.437 14.41,30.875 28.82,40.137 12.35,3.089 30.88,5.148 42.2,-2.059"
          />
          <path
            id="path28"
            fill="#d22027"
            d="m 2066.19,124.992 72.78,56.934 1.18,12.324 -84.52,-64.562"
          />
          <path
            id="path30"
            fill="#d22027"
            d="m 2253.01,123.156 72.79,56.934 1.17,12.328 -84.52,-64.566"
          />
          <path
            id="path32"
            fill="#d22027"
            d="m 2288.81,150.688 72.78,56.933 16.37,23.387 -99.72,-75.625"
          />
          <path
            id="path34"
            fill="#d22027"
            d="m 1790.18,114.871 72.78,56.934 1.18,12.328 -84.52,-64.567"
          />
          <path
            id="path36"
            fill="#d22027"
            d="m 1450.39,100.195 72.78,56.938 -8.8,4.105 -74.54,-56.343"
          />
          <path
            id="path38"
            fill="#d22027"
            d="m 1080.22,51.5664 c 52.12,37.9063 86.23,96.1326 120.97,154.2856 l -0.9,-0.012 c 23.06,37.223 83.75,130.785 102.68,163.336 v 0 c 47.16,78.707 101.42,169.597 184.84,215.566 0,0 51.3,-6.793 70.23,-1.496 0,0 14.27,23.324 -25.57,25.418 0,0 -1.21,-0.156 -3.14,-0.445 -12.34,0.902 -24.76,-2.301 -36.05,-7.473 -83.15,-6.297 -169.46,4.824 -245.02,-31.699 -43.14,-21.805 -61.37,-62.723 -52.79,-106.676 5.84,-24.648 30.5,-39.637 56.91,-44.008 -11.65,13.008 -29.11,30.68 -24.29,63.102 19.25,98.223 126.43,95.113 213.94,98.156 -40.56,-24.766 -84.47,-66.82 -115.24,-105.664 -23.83,-28.09 -45.2,-56.32 -66.68,-91.43 C 1225,330.723 1151.04,206.285 1131.55,174.488 h 0.02 C 1093.46,113.492 1051.96,51.5703 990.035,25.1523 978.133,20.0742 954.199,15.9023 940.57,13.4844 925.68,10.8438 900.734,11.5703 883.504,13.1445 857.297,19.7461 822.293,37.418 818.102,67.7031 808.305,75.3125 796.23,67.7969 791.801,59.168 785.176,48.3867 788.363,33.2383 797.066,25.6367 821.523,8.47266 856.254,0.871094 888.391,0.101563 c 20.82,-0.496094 62.461,0.816406 82.164,4.281247 44.895,7.03909 78.775,24.71489 109.665,47.18359"
          />
          <path
            id="path40"
            fill="#d22027"
            d="m 2538.46,295.418 c -3.09,-22.711 -54.7,-41.289 -16.51,-60.902 3.09,-1.032 1.03,-4.129 2.06,-6.192 -11.35,-10.324 -23.74,0 -35.09,3.098 l -14.45,16.512 C 2409.44,209.742 2353.7,145.746 2306.21,86.9102 c -11.35,-2.0664 -21.94,-11.6758 -34.33,-3.418 35.09,66.0628 94.2,121.0898 128.27,186.1208 -11.36,4.129 -23.74,4.129 -35.1,6.192 -3.1,5.16 3.1,8.258 7.23,9.289 h 69.16 l 5.16,-5.16 c -12.39,-29.934 -35.1,-48.512 -50.58,-75.352 34.06,26.84 66.06,43.352 92.9,78.449 11.35,16.516 33.03,14.449 49.54,12.387"
          />
          <path
            id="path42"
            fill="#d22027"
            d="m 2508.74,244.391 c 9.78,-11.078 28.36,-12.887 40.1,-3.907 1.13,-0.656 1.33,-2.296 0.78,-3.48 -0.56,-1.18 -1.66,-1.996 -2.75,-2.727 -15.38,-10.355 -35.69,-13.004 -53.22,-6.937 -1.63,0.566 -3.3,1.238 -4.48,2.496 -1.33,1.41 -1.88,3.367 -2.37,5.242 -2.29,8.746 -4.37,18.047 -1.79,26.711 2.13,7.133 7.26,13.07 13.24,17.492 5.99,4.422 12.84,7.5 19.63,10.547"
          />
        </g>
      </g>
    </svg>
  );
}
